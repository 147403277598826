@import ./../../variables.style

.amenities-container
    position: absolute
    top: 56%
    left: 49%
    width: 58%
    height: 82%
    transform: translate(-50%,-50%)
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 8px

.create-amenities-default-container
    position: absolute
    top: 15%
    right: 1%
    width: 20%
    height: 45%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 8px

.create-amenities-new-container
    position: absolute
    top: 62%
    right: 1%
    width: 20%
    height: 35%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 8px

.amenidades-main-title
    position: absolute
    top: 3%
    left: 3%
    font-size: 1.125rem
    color: #043250
    font-family: $font-family-semibold

.create-amenitie-new
    position: absolute
    top: 8%
    left: 6%
    font-size: 0.9vw
    color: #043250
    font-family: $font-family-semibold