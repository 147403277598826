@import ./../../variables.style

.amenidades-list-container
    position: absolute
    top: 30%
    width: 90%
    height: auto
    max-height: 53vh
    left: 50%
    transform: translate(-50%,0)
    overflow-y: auto

.amenitie-individual-container
    position: relative
    height: 6vh
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 5px
    margin-bottom: 1vh
    display: flex
    align-items: center

.amenitie-individual-name
    position: absolute
    top: 50%
    left: 10%
    transform: translate(0,-50%)
    font-family: $font-family-semibold
    font-size: 0.9vw
    color: #043250

.amenity-icon-list-image
    position: relative
    max-height: 1.5rem
    min-height: 1.5rem 
    max-width: 1.5rem
    min-width: 1.5rem
    object-fit: contain

.amenitie-separator
    position: absolute
    right: 12.5%
    top: 50%
    background-color: #BEBEBE
    width: 0.1vw
    height: 40%
    transform: translate(0,-50%)

.gallery-selector
    cursor: pointer
    position: absolute
    top: 50%
    right: 6%
    transform: translate(0,-50%)
    height: 40%
    width: 5%
    img
        position: absolute
        top: 50%
        left: 50%
        max-width: 100%
        min-width: 100%
        max-height: 100%
        min-height: 100%
        object-fit: contain
        transform: translate(-50%,-50%)

.edit-selector
    cursor: pointer
    position: absolute
    top: 50%
    right: 1%
    transform: translate(0,-50%)
    height: 40%
    width: 5%
    img
        position: absolute
        top: 50%
        left: 50%
        max-width: 100%
        min-width: 100%
        max-height: 100%
        min-height: 100%
        object-fit: contain
        transform: translate(-50%,-50%)

.amenitites-select-spin
    position: absolute
    top: 50%
    right: 14%
    transform: translate(0,-50%)
    height: 2rem
    display: flex
    align-items: center
    select
        margin-left: 1rem