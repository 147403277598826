@import ./../../variables.style

.general-properties-container
    position: absolute
    top: 55%
    left: 55%
    width: 45%
    height: 75%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px
    transform: translate(-50%,-50%)

.general-properties-select-icon-container
    position: absolute
    top: 3%
    left: 5%
    width: 70%
    height: 30%

.general-properties-select-view-container
    position: absolute
    top: 30%
    left: 50%
    transform: translate(-50%, 0)
    width: 90%
    height: 65%

.general-propertoes-title
    color: #043250
    font-family: $font-family-semibold
    font-size: 1.125rem

.general-properties-icon-selected-container
    position: absolute
    height: 5.5vw    
    width: 5.5vw
    background: #BEBEBE 0% 0% no-repeat padding-box
    border: 1px solid #707070
    border-radius: 5px
    img
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        max-width: 80%
        min-width: 80%
        max-height: 80%
        min-height: 80%
        object-fit: contain

.general-properties-icon-select-container
    margin-top: 5%
    position: relative
    width: 70%
    height: 40%

.general-properties-icon-text-container
    position: absolute
    left: 31%
    input
        width: 50%

.general-propertiess-info-title
    position: relative
    margin-bottom: 1%
    color: #000
    font-family: $font-family-semibold
    font-size: 0.9vw

.general-properties-info-subtitle
    position: relative
    margin-bottom: 1%
    color: #000
    font-family: $font-family-regular
    font-size: .875rem

.general-properties-info-text
    position: relative
    margin-bottom: 1%
    color: #939598
    font-family: $font-family-regular
    font-size: .875rem

.input-icons-btn-general-properties
    position: absolute
    cursor: pointer
    background: #F5F5F5 0% 0% no-repeat padding-box
    border: 1px solid #707070
    border-radius: 5px
    color: #939598
    font-family: $font-family-regular
    font-size: 0.9vw
    margin-top: 2%
    width: 7rem
    height: 2rem
    display: flex
    justify-content: center
    align-items: center

.general-properties-attributes-view
    position: absolute
    width: 45%
    height: 100%
    left: 0
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 5px
    a
        position: absolute
        bottom: 10%
        left: 50%
        transform: translate(-50%, 0)
        width: 35%
        height: 8%
        background: #ED0874 0% 0% no-repeat padding-box
        border-radius: 3px
        text-decoration: none
        color: #fff
        font-family: $font-family-regular
        font-size: 0.8vw
        display: flex
        justify-content: center
        align-items: center

.general-properties-imuebles-view
    position: absolute
    width: 45%
    height: 100%
    right: 0
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 5px
    a
        position: absolute
        bottom: 10%
        left: 50%
        transform: translate(-50%, 0)
        width: 65%
        height: 8%
        background: #ED0874 0% 0% no-repeat padding-box
        border-radius: 3px
        text-decoration: none
        color: #fff
        font-family: $font-family-regular
        font-size: 0.8vw
        display: flex
        justify-content: center
        align-items: center

.general-properties-attributes-title
    position: absolute
    left: 50%
    width: 80%
    text-align: center
    top: 5%
    transform: translate(-50%, 0)
    color: #043250
    font-family: $font-family-semibold
    font-size: 1.125rem

.general-properties-imuebles-title
    position: absolute
    left: 50%
        width: 80%
    text-align: center
    top: 5%
    transform: translate(-50%, 0)
    color: #043250
    font-family: $font-family-semibold
    font-size: 1.125rem

.general-properties-imuebles-text
    position: absolute
    left: 50%
    top: 25%
    transform: translate(-50%, 0%)
    width: 80%
    color: #000
    font-family: $font-family-regular
    font-size: 0.9vw

.general-properties-attributes-text
    position: absolute
    left: 50%
    top: 25%
    transform: translate(-50%, 0%)
    width: 80%
    color: #000
    font-family: $font-family-regular
    font-size: 0.9vw

.general-properties-filter-menu-container
    position: absolute
    left: 70%
    top: 25%
    width: 50%

.general-properties-filter-name
    position: relative
    margin-bottom: 1.5rem
    font-family: $font-family-semibold
    font-size: 1rem

.general-properties-filter-radios
    display: flex
    flex-direction: column
    label
        display: flex
        align-items: center
        font-family: $font-family-regular
        font-size: 0.875rem
        margin-bottom: 0.5rem
        input
            margin-right: 0.5rem