@import ./../../variables.style

.edit-amenitie-container
    position: absolute
    width: 80%
    height: 80%
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)

.create-edit-save-btn
    cursor: pointer
    position: absolute
    bottom: 10%
    left: 20%
    width: 25%
    height: 10%
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 5px
    color: #fff
    font-family: $font-family-regular
    font-size: 0.8vw
    display: flex
    align-items: center
    justify-content: center

.create-edit-delete-btn
    cursor: pointer
    position: absolute
    bottom: 10%
    width: 25%
    right: 20%
    height: 10%
    background: #BEBEBE 0% 0% no-repeat padding-box
    border-radius: 5px
    color: #fff
    font-family: $font-family-regular
    font-size: 0.8vw
    display: flex
    align-items: center
    justify-content: center
