@import ./../../variables.style

.pois-container-main
    position: absolute
    left: 20%
    top: 13%
    height: 84%
    width: 55%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px