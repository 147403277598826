@import ./../../variables.style

.sidebar-header
    position: relative
    width: 100%
    height: 11%

.sidebar-header-title
    position: absolute
    top: 40%
    left: 5%
    transform: translate(0%,-50%)
    font-family: $font-family-semibold
    font-size: 1vw
    color: #043250

.sidebar-header-subtitle
    position: absolute
    top: 50%
    left: 5%
    transform: translate(0%,50%)
    font-family: $font-family-regular
    font-size: 0.8vw
    color: #616161

.sidebar-collapse-btn
    cursor: pointer
    position: absolute
    top: 50%
    width: 5%
    height: 20%
    right: 10%
    transform: translate(0,-50%)
    img
        position: absolute
        top: 50%
        left: 50%
        min-width: 100%
        max-width: 100%
        min-height: 100%
        max-height: 100%
        object-fit: contain
        transform: translate(-50%,-50%) rotate(90deg)