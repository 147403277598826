@import ./../../variables.style

.quotation-icon-main-container
    position: absolute
    top: 12%
    left: 20%
    width: 42%
    height: 20%
    background-color: #FFFFFF
    border: 1px solid #E0E0E0
    border-radius: 10px

.quotation-icon-title
    position: absolute
    top: 5% 
    left: 3%
    color: #043250
    font-size: 1.125rem
    font-family: $font-family-semibold

.quotation-icon-container
    position: absolute
    top: 20%
    left: 3%
    width: 60%
    height: 60%

.quotation-icon-holder
    position: absolute
    top: 50%
    width: 4.5vw
    height: 4.5vw
    border: 1px solid #707070
    background-color: #F5F5F5
    top: 50%
    transform: translate(0,-50%)
    border-radius: 10px
    img
        position: absolute
        max-width: 70%
        min-width: 70%
        max-height: 70%
        min-height: 70%
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

.quotation-icon-text-container
    position: absolute
    top: 50%
    left: 22%
    transform: translate(0,-50%)
    height: 80%
    input
        position: absolute
        z-index: 2
        height: 35%
        opacity: 0

.quotation-icon-container-title
    position: relative
    margin-bottom: 3%
    color: #000
    font-size: 0.9vw
    font-family: $font-family-semibold

.quotatuib-icon-text
    position: relative
    margin-bottom: 3%
    color: #000
    font-size: 0.8vw
    font-family: $font-family-regular

.quotation-icon-btn
    position: absolute
    height: 35%
    width: 40%
    background-color: #F5F5F5
    border: 1px solid #E0E0E0
    border-radius: 10px
    display: flex
    justify-content: center
    align-items: center
    color: #000
    font-size: 0.8vw
    font-family: $font-family-regular
    cursor: pointer