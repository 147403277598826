@import ./../../variables.style

.modules-container
    position: relative
    width: 62%
    left: 55%
    transform: translate(-50%,0)
    overflow-y: auto
    min-height: 2rem
    height: auto
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px
    margin-bottom: 2rem

.modules-name
    position: relative
    top: 5%
    left: 50%
    font-family: $font-family-semibold
    color: #043250
    font-size: 1.1rem
    transform: translate(-50%,0%)
    text-align: center
    margin-top: 1rem

.modules-card-container
    position: relative
    top: 10%
    left: 50%
    width: 50%
    transform: translate(-50%,0%)
    display: grid
    margin-top: 2rem
    margin-bottom: 2rem
    grid-template-columns: repeat(2, 2fr)
    font-size: 0.875rem

.modules-card
    position: relative
    display: flex
    align-items: center
    justify-content: space-between
    font-family: $font-family-regular
    border-radius: 5px
    border: 0.1rem solid
    margin: 0.5rem
    padding-left: 1rem 