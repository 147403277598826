@import ./../../variables.style

.mailing-quotation-main-container
    position: absolute
    top: 33%
    left: 20%
    width: 42%
    height: 65%
    background-color: #FFFFFF
    border: 1px solid #E0E0E0
    border-radius: 10px

.mailing-container-main
    position: absolute
    top: 5%
    left: 50%
    transform: translate(-50%,0)
    width: 95%
    height: 60%
    border: 1px solid #616161
    border-radius: 10px

.mailing-quotation-title
    position: absolute
    top: 3%
    left: 3%
    color: #043250
    font-size: 1.125rem
    font-family: $font-family-semibold

.mailing-quotation-text
    position: absolute
    top: 10%
    left: 3%
    color: #616161
    width: 80%
    font-size: 0.8vw
    font-family: $font-family-regular

.mailing-quoatuion-name
    position: absolute
    top: 25%
    left: 3%
    width: 40%
    height: 15%
    input
        width: 100%
        height: 40%

.mailing-quotatuion-name-text
    position: relative
    margin-bottom: 5%
    color: #000
    width: 80%
    font-size: 0.9vw
    font-family: $font-family-regular

.mailing-quoatuion-des
    position: absolute
    top: 25%
    left: 47%
    width: 40%
    height: 15%
    input
        width: 100%
        height: 40%

.mailing-quotatuion-name-desc
    position: relative
    margin-bottom: 5%
    color: #000
    width: 80%
    font-size: 0.9vw
    font-family: $font-family-regular

.quotation-mailing-container
    position: absolute
    top: 50%
    left: 3%
    width: 50%
    height: 30%
    border: 1px solid #616161
    border-radius: 5px

.quotation-mailing-holder
    position: absolute
    top: 50%
    width: 4vw
    height: 4vw
    left: 2%
    transform: translate(0,-50%)
    background-color: #F5F5F5
    border: 1px solid #707070
    border-radius: 10px
    img
        position: absolute
        max-width: 70%
        min-width: 70%
        max-height: 70%
        min-height: 70%
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

.quotation-mailing-text-container
    position: absolute
    top: 50%
    left: 24%
    transform: translate(0,-50%)
    height: 80%
    input
        position: absolute
        z-index: 2
        height: 35%
        opacity: 0

.quotation-mailing-title
    position: relative
    margin-bottom: 3%
    color: #000
    font-size: 0.9vw
    font-family: $font-family-semibold

.quotatuib-mailing-text
    position: relative
    margin-bottom: 3%
    color: #000
    font-size: 0.8vw
    font-family: $font-family-regular

.quotation-mailing-btn
    position: absolute
    height: 35%
    width: 40%
    background-color: #F5F5F5
    border: 1px solid #E0E0E0
    border-radius: 10px
    display: flex
    justify-content: center
    align-items: center
    color: #000
    font-size: 0.8vw
    font-family: $font-family-regular
    cursor: pointer

.mailing-quotation-save-btn
    cursor: pointer
    position: absolute
    bottom: 5%
    left: 50%
    height: 10%
    width: 13%
    background-color: #ED0874
    color: #fff
    display: flex
    justify-content: center
    align-items: center
    transform: translate(-50%, 0)
    font-size: 0.8vw
    font-family: $font-family-regular
    border-radius: 5px
