@import ./../../variables.style
    
.properties-header-container
    position: absolute
    top: 2%
    width: 100%
    height: 8%

.properties-header-title
    position: absolute
    left: 2%
    font-family: $font-family-semibold
    color: #043250
    font-size: 1.2rem
    top: 50%
    transform: translate(0,-50%)

.properties-new-btn
    cursor: pointer
    position: absolute
    right: 12rem
    top: 50%
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 7px
    height: 60%
    width: 9rem
    transform: translate(0,-50%)
    font-family: $font-family-regular
    color: #fff
    font-size: 0.85rem
    display: flex
    justify-content: center
    align-items: center

.properties-actions-btn
    position: absolute
    right: 2rem
    top: 50%
    width: 9rem
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 7px
    height: 60%
    transform: translate(0,-50%)
    font-family: $font-family-regular
    color: #fff
    font-size: 0.85rem
    display: flex
    justify-content: center
    align-items: center

.properties-actions-options
    position: absolute
    right: 2rem
    top: 80%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border-radius: 5px
    border: 0.5px solid #BEBEBE
    z-index: 2
    width: 15%
    height: 120%

.properties-actions-selection
    position: relative
    width: 100%
    height: 50%
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    color: #043250
    font-family: $font-family-regular
    font-size: .9rem
    cursor: pointer

.breadcrumbs-container
    position: absolute
    width: 80%
    top: 100%
    font-family: $font-family-semibold
    color: #014976
    font-size: 0.8vw

.breadcrumbs-builders
    text-decoration: none
    color: #616161
    font-family: $font-family-regular
    margin-right: 1.2%
