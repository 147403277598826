@import ./../../variables.style

.sidebar-main
    position: absolute
    left: 0
    background: #F5F5F5 0% 0% no-repeat padding-box
    top: 10%
    height: 90%
    width: 18vw
    box-shadow: 3px 0px 20px #00000029
    overflow-y: auto

.sidebar-divisor
    position: relative
    top: 0%
    height: 0.1%
    width: 100%
    background-color: #E2E2E2

.sideba-dev-text
    position: relative
    margin-top: 2rem
    margin-bottom: 1rem
    width: 90%
    left: 50%
    transform: translate(-50%,0)
    font-family: $font-family-regular
    font-size: 0.875rem
    color: #616161

.update-template-masterplan-cache
    position: relative
    width: 90%
    left: 5%
    font-family: $font-family-regular
    font-size: 0.75rem
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    color:#000
    height: 3.75rem
    display: flex
    justify-content: space-evenly
    align-items: center
    border-radius: 5px
    cursor: pointer
    flex-direction: column
    margin-bottom: 3rem !important
    strong
        margin-top: 0.5rem

.update-template-masterplan
    position: relative
    width: 90%
    left: 5%
    font-family: $font-family-semibold
    font-size: 0.75rem
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    color: #000
    height: 2.5rem
    display: flex
    justify-content: center
    align-items: center
    border-radius: 5px
    cursor: pointer

.update-template-confirmation
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    z-index: 3
    background-color: rgba(200,200,200,0.8)

.update-info-container
    position: absolute
    width: 60rem
    height: 37.5rem
    background-color: #fff
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
    border-radius: 20px
    img
        position: relative
        margin-top: 2rem
        width: 50%
        left: 50%
        transform: translate(-50%,0)
        margin-bottom: 2rem

.update-info-container-title
    position: relative
    left: 50%
    transform: translate(-50%,0)
    font-size: 1.75rem
    text-align: center
    width: 80%
    font-family: $font-family-semibold
    margin-bottom: 2rem

.update-info-container-text
    position: relative
    left: 50%
    transform: translate(-50%,0)
    font-size: 1.25rem
    text-align: center
    width: 80%
    font-family: $font-family-regular
    margin-bottom: 3rem

.update-info-container-btns
    position: relative
    display: flex
    align-items: center
    justify-content: center
    width: 80%
    left: 50%
    transform: translate(-50%,0)

.update-accept
    cursor: pointer
    position: relative
    background-color: #ED0874
    width: 10rem
    height: 2.5rem
    color: #fff
    font-size: 1rem
    font-family: $font-family-regular
    display: flex
    justify-content: center
    align-items: center
    border-radius: 10px
    margin-left: 1rem
    margin-right: 1rem

.update-cancel
    cursor: pointer
    position: relative
    background-color: rgb(221, 51, 51)
    width: 10rem
    height: 2.5rem
    color: #fff
    font-size: 1rem
    font-family: $font-family-regular
    display: flex
    justify-content: center
    align-items: center
    border-radius: 10px
    margin-left: 1rem
    margin-right: 1rem

.progress-bar-parent
    position: absolute
    width: 20rem
    height: 0.5rem
    bottom: 42.5%
    background-color: #fff
    border-radius: 10px

.progress-bar-child
    position: absolute
    top: 0
    left: 0
    height: 100%
    background-color: #ED0874
    border-radius: 10px

.progress-number
    position: absolute
    bottom: 38%
    font-family: $font-family-semibold
    color: #fff
    font-size: 1rem