@import ./../../variables.style

.upload-icon-main
    position: absolute
    top: 13%
    width: 90%
    left: 50%
    height: 20%
    transform: translate(-50%,0)

.upload-icon-container
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 50%


.upload-icon-image
    position: absolute
    top: 50%
    left: 0
    height: 5vw
    width: 5vw
    background: #F5F5F5 0% 0% no-repeat padding-box
    border: 2px solid #BEBEBE
    display: flex
    justify-content: center
    align-items: center
    transform: translate(0,-50%)
    img
        max-height: 80%
        max-width: 80%
        min-height: 80%
        min-width: 80%
        object-fit: contain

.upload-icon-info
    position: absolute
    top: 30%
    left: 25%
    width: 60% 
    transform: translate(0,-50%)

.upload-icon-info-main
    color: #000000
    font-family: $font-family-regular
    font-size: 0.9vw
    margin-bottom: 2%

.upload-icon-info-format
    color: #939598
    font-family: $font-family-regular
    font-size: 0.8vw
    margin-bottom: 5%

.input-icons-btn
    position: absolute
    cursor: pointer
    background: #F5F5F5 0% 0% no-repeat padding-box
    border: 1px solid #707070
    border-radius: 5px
    color: #939598
    font-family: $font-family-regular
    font-size: 0.875rem
    width: 5.625rem
    height: 1.5rem !important
    font-size: .75rem !important
    display: flex
    justify-content: center
    align-items: center

.inputfile-icons
    cursor: pointer
    position: absolute
    z-index: 2
    height: 2rem
    opacity: 0

.upload-icon-name
    position: absolute
    right: 0
    top: 0
    width: 50%
    height: 100%

.upload-name-main
    position: absolute
    top: 8%
    left: 10%
    transform: translate(0%,0)
    color: #000000
    font-family: $font-family-regular
    font-size: 0.9vw
    margin-bottom: 2%

.input-icon-name
    position: absolute
    top: 50%
    left: 10%
    width: 60%
    height: 20%