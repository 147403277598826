// paper & background
$paper: #ffffff

// primary
$primaryLight: #fff0f7
$primaryMain: #ed0874
$primaryDark: #bf1e60
$primary200: #ffcce4
$primary800: #981c4a

// secondary
$secondaryLight: #ede7f6
$secondaryMain: #673ab7
$secondaryDark: #5e35b1
$secondary200: #b39ddb
$secondary800: #4527a0

// success Colors
$successLight: #b9f6ca
$success200: #69f0ae
$successMain: #00e676
$successDark: #00c853

// error
$errorLight: #ef9a9a
$errorMain: #f44336
$errorDark: #c62828

// orange
$orangeLight: #fbe9e7
$orangeMain: #ffab91
$orangeDark: #d84315

// warning
$warningLight: #fff8e1
$warningMain: #ffe57f
$warningDark: #ffc107

// grey
$grey50: #fafafa
$grey100: #f5f5f5
$grey200: #eeeeee
$grey300: #e0e0e0
$grey500: #9e9e9e
$grey600: #757575
$grey700: #616161
$grey900: #212121

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f // level 3
$darkPaper: #111936 // level 4

// dark 800 & 900
$darkLevel1: #29314f // level 1
$darkLevel2: #212946 // level 2

// primary dark
$darkPrimaryLight: #e3f2fd
$darkPrimaryMain: #2196f3
$darkPrimaryDark: #1e88e5
$darkPrimary200: #90caf9
$darkPrimary800: #1565c0

// secondary dark
$darkSecondaryLight: #d1c4e9
$darkSecondaryMain: #7c4dff
$darkSecondaryDark: #651fff
$darkSecondary200: #b39ddb
$darkSecondary800: #6200ea

// text variants
$darkTextTitle: #d7dcec
$darkTextPrimary: #bdc8f0
$darkTextSecondary: #8492c4

// ==============================|| TEXT ||============================== //

//primary
$primaryText: #043250

// ==============================|| FONTS ||============================== //

$font-family-regular: "Montserrat Regular"
$font-family-semibold: "Montserrat Semibold"