@import ./../../variables.style

.general-roads-set-container
    position: relative
    width: 95%
    height: 75%
    left: 2.5%
    top: 7%

.general-roads-location-config
    display: flex
    width: 100%
    height: 10%
    margin-top: 2%

.general-roads-location-map-type
    position: relative
    width: 70%
    height: 15%
    display: flex
    text-align: right
    div
        margin-right: 20px
        input
            margin-right: 10px

.general-roads-location-search-container
    position: relative
    width: 30%
    height: 8%
    div
        position: absolute
        top: 50%
        transform: translate(0,-50%)
        color: #000000
        font-family: $font-family-regular
        font-size: 0.8vw
    input
        position: relative
        left: 20%
        top: 50%
        width: 20%
        transform: translate(0,-50%)

.lat-lon-container
    margin-top: 1%
    position: relative
    width: 100%
    height: 8%
    display: flex

.lat-lon-text
    position: relative
    width: 60%
    margin-right: 10%
    color: #000000
    font-family: $font-family-regular
    font-size: 0.8vw

.lat-container
    margin-right: 15%
    color: #000000
    font-family: $font-family-regular
    font-size: 0.8vw

.lon-container
    margin-right: 15%
    color: #000000
    font-family: $font-family-regular
    font-size: 0.8vw

.map-container
    position: relative
    width: 100%
    height: 70%
    background: #BEBEBE 0% 0% no-repeat padding-box
    border-radius: 5px

.zoom-container
    margin-top: 0.5%
    position: relative
    width: 100%
    height: 10%

.default-zoom-container
    position: absolute
    top: 50%
    transform: translate(0,-50%)

.max-min-zoom-container
    position: absolute
    left: 30%
    width: 70%
    top: 50%
    transform: translate(0,-50%)

.default-zoom-title
    position: relative
    color: #000000
    font-family: $font-family-regular
    font-size: 0.8vw
    margin-bottom: 3%

.default-zoom-input-container
    display: flex
    justify-content: center
    align-items: center
    input
        width: 50%
        margin-right: 10%

.default-zoom-btn
    cursor: pointer
    width: 40%
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 3px
    color: #FFFFFF
    font-family: $font-family-regular
    font-size: 0.8vw
    display: flex
    align-items: center
    justify-content: center

.max-min-zoom-input-container
    display: flex

.max-min-zoom-title
    position: relative
    color: #000000
    font-family: $font-family-regular
    font-size: 0.8vw
    margin-bottom: 1%

.min-zoom-container
    width: 45%
    display: flex
    justify-content: space-between
    align-items: center
    margin-right: 5%

.max-zoom-container
    width: 45%
    display: flex
    justify-content: space-between
    align-items: center

.min-zoom-btn
    cursor: pointer
    width: 25%
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 3px
    color: #FFFFFF
    font-family: $font-family-regular
    font-size: 0.8vw
    display: flex
    align-items: center
    justify-content: center

.max-zoom-btn
    cursor: pointer
    width: 25%
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 3px
    color: #FFFFFF
    font-family: $font-family-regular
    font-size: 0.8vw
    display: flex
    align-items: center
    justify-content: center

.min-zoom-text
    position: relative
    color: #000000
    font-family: $font-family-regular
    font-size: 0.8vw

.max-zoom-text
    position: relative
    color: #000000
    font-family: $font-family-regular
    font-size: 0.8vw

#map
    height: 100%

.fijar-btn
    position: absolute
    left: 50%
    top: 88%
    transform: translate(-50%, 0)
    border: 0.5px solid #ED0874
    width: 16.938rem
    height: 2.125rem
    font-family: $font-family-regular
    font-size: 0.875rem
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 3px
    color: #fff

.center-map
    position: absolute
    right: 0
    top: 88%
    width: 9.875rem
    height: 2.125rem
    font-family: $font-family-regular
    font-size: 0.875rem
    display: flex
    justify-content: center
    align-items: center
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 0.5px solid #ED0874
    border-radius: 3px
    cursor: pointer
    color: #fff
    color: #ED0874

.lng-show-container
    position: absolute
    top: 100%
    left: 30%
    font-family: $font-family-regular
    font-size: 0.75rem
    width: 20%
    text-align: center

.lat-show-container
    position: absolute
    top: 100%
    right: 30%
    font-family: $font-family-regular
    font-size: 0.75rem
    width: 20%
    text-align: center
