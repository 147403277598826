@import ./../../variables.style

.asoleacion-upload-container-main
    position: absolute
    top: 13%
    right: 1%
    width: 20%
    height: 50%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #616161
    border-radius: 10px

.asoleacion-setup-title
    position: absolute
    top: 3%
    left: 3%
    color: #043250
    font-size: 1.125rem
    font-family: $font-family-semibold

.asoleacion-icono-setup
    position: absolute
    top: 10%
    left: 3%
    width: 40%
    height: 15%

.asoleacion-icono-preview
    position: absolute
    height: 4.5vw
    width: 4.5vw
    border: 1px solid #707070
    background-color: #F5F5F5
    top: 50%
    transform: translate(0,-50%)
    border-radius: 10px
    img
        position: absolute
        max-width: 70%
        min-width: 70%
        max-height: 70%
        min-height: 70%
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

.asoleacion-icono-selection-text-container
    position: absolute
    top: 55%
    left: 25%
    transform: translate(0,-50%)
    height: 80%
    input
        position: absolute
        z-index: 2
        height: 35%
        opacity: 0

.asoleacion-icono-selection-title
    position: relative
    margin-bottom: 3%
    color: #000
    font-size: 0.9vw
    font-family: $font-family-semibold

.asolaecion-icono-selection-text
    position: relative
    margin-bottom: 3%
    color: #000
    font-size: 0.8vw
    font-family: $font-family-regular

.input-asoleacion-icon-btn
    position: absolute
    height: 35%
    width: 40%
    background-color: #F5F5F5
    border: 1px solid #E0E0E0
    border-radius: 10px
    display: flex
    justify-content: center
    align-items: center
    color: #000
    font-size: 0.8vw
    font-family: $font-family-regular
    cursor: pointer

.asoleacion-icono-document
    position: absolute
    top: 10%
    left: 45%
    width: 45%
    height: 15%

.asoleacion-list-title
    position: absolute
    top: 28%
    left: 3%
    color: #043250
    font-size: 1.125rem
    font-family: $font-family-semibold

.asoleacion-list-text
    position: absolute
    top: 32%
    left: 3%
    color: #616161
    font-size: 0.8vw
    font-family: $font-family-regular

.asolacion-icono-document-preview
    position: absolute
    height: 4.5vw
    width: 4.5vw
    border: 1px solid #707070
    background-color: #F5F5F5
    top: 52%
    transform: translate(0,-50%)
    border-radius: 10px
    img
        position: absolute
        max-width: 70%
        min-width: 70%
        max-height: 70%
        min-height: 70%
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

.asolecion-documentos-selection-text-container
    position: absolute
    top: 55%
    left: 25%
    transform: translate(0,-50%)
    height: 80%
    input
        position: absolute
        z-index: 2
        height: 35%
        opacity: 0

.asoleacion-document-selection-title
    position: relative
    margin-bottom: 3%
    color: #000
    font-size: 0.9vw
    font-family: $font-family-semibold

.asoleacion-document-selection-text
    position: relative
    margin-bottom: 3%
    color: #000
    font-size: 0.8vw
    font-family: $font-family-regular

.input-asoleacion-document-btn
    position: absolute
    height: 35%
    width: 40%
    background-color: #F5F5F5
    border: 1px solid #E0E0E0
    border-radius: 10px
    display: flex
    justify-content: center
    align-items: center
    color: #000
    font-size: 0.8vw
    font-family: $font-family-regular
    cursor: pointer

.input-name-asoleacion
    position: absolute
    top: 15%
    left: 3%
    width: 90%
    height: 5%

.input-desc-asoleacion
    position: absolute
    top: 28%
    left: 3%
    width: 90%
    height: 20%
    resize: none

.asoleacion-upload-icon-preview-container
    position: absolute
    top: 53%
    width: 90%
    left: 3%
    height: 7rem
    border: 1px solid #616161
    border-radius: 10px

.asolaecion-upload-icon
    position: absolute
    top: 50%
    left: 3%
    height: 3.5vw
    width: 3.5vw
    transform: translate(0,-50%)
    background-color: #F5F5F5
    border: 1px solid #707070
    border-radius: 10px
    img
        position: absolute
        top: 50%
        left: 50%
        min-width: 70%
        max-width: 70%
        min-height: 70%
        max-height: 70%
        transform: translate(-50%,-50%)
        object-fit: contain

.asolecion-upload-text-container
    position: absolute
    top: 50%
    left: 28%
    height: 70%
    transform: translate(0,-50%)

.asoelacion-upload-title
    position: relative
    margin-bottom: 2%
    color: #000
    font-size: 0.8vw
    font-family: $font-family-semibold

.asoleacion-upload-text
    position: relative
    margin-bottom: 2%
    color: #000
    font-size: 0.75rem
    font-family: $font-family-regular

.asoleacion-upload-btn
    position: absolute
    height: 2rem
    width: 7rem
    background-color: #F5F5F5
    border: 1px solid #E0E0E0
    border-radius: 10px
    display: flex
    justify-content: center
    align-items: center
    color: #000
    font-size: 0.7vw
    font-family: $font-family-regular
    cursor: pointer

.asoleacion-upload-input
    position: absolute
    z-index: 2
    height: 2rem
    opacity: 0

.asoleacion-upload-save-btn
    cursor: pointer
    position: absolute
    top: 80%
    left: 50%
    transform: translate(-50%,0)
    height: 8%
    width: 25%
    background-color: #ED0874
    color: #fff
    display: flex
    justify-content: center
    align-items: center
    font-size: 0.9vw
    font-family: $font-family-regular
    border-radius: 10px