@import ./../../variables.style

.asoleacion-list
    position: absolute
    left: 20%
    width: 58%
    height: 50%
    top: 45%

.asoleacion-video-container
    position: relative
    margin-bottom: 2vh
    width: 90%
    height: 10%
    left: 3%
    border: 1px solid #616161
    border-radius: 10px

.asoleacion-video-title
    position: absolute
    left: 2% 
    top: 15%
    font-size: 0.8vw
    font-family: $font-family-semibold

.asoleacion-video-desc
    position: absolute
    left: 2% 
    top: 60%
    font-size: 0.6vw
    font-family: $font-family-regular

.asoleacion-video-preview
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
    font-size: 0.8vw
    font-family: $font-family-regular

.asoleacion-edit
    cursor: pointer
    position: absolute
    top: 50%
    height: 40%
    width: 2%
    right: 7%
    transform: translate(0,-50%)
    img
        position: absolute
        min-height: 100%

.asoleacion-delete
    cursor: pointer
    position: absolute
    top: 50%
    height: 40%
    width: 2%
    right: 2%
    transform: translate(0,-50%)
    img
        position: absolute
        min-height: 100%