@import ./variables.style
    
*
    margin: 0
    padding: 0
    box-sizing: 0

html, body
    width: 100%
    height: 100%
    margin: 0
    overflow: hidden
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -o-user-select: none
    user-select: none

img
    -webkit-user-drag: none
    -khtml-user-drag: none
    -moz-user-drag: none
    -o-user-drag: none

#root
    width: 100%
    height: 100%

input
    font-family: $font-family-regular

.fade-out
    pointer-events: none
    opacity: 0
    transition: all 0.3s ease-in-out

.fade-in
    pointer-events: all
    opacity: 1
    transition: all 0.3s ease-in-out

@import ./fonts.style

@import ./desktop/main.style

@media screen  and (-webkit-min-device-pixel-ratio: 2) and (min-width: 600px) and (max-width: 960px),  (min-resolution: 192dpi) and (min-width: 600px) and (max-width: 960px)
    @import ./desktop/responsive/main.style

@media screen and (min-width: 600px) and (max-width: 960px)
    @import ./desktop/responsive/main.style