.header-poi-title
    position: absolute
    top: 4.5%
    left: 3%
    color: #043250
    font-family: $font-family-semibold
    font-size: 0.9vw

.poi-update-btn
    cursor: pointer
    position: absolute
    top: 3%
    right: 3%
    width: 10%
    height: 5%
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 7px
    display: flex
    align-items: center
    justify-content: center
    font-family: $font-family-regular
    font-size: 0.8vw
    color: #fff

.header-poi-first-text
    position: absolute
    top: 12%
    width: 50%
    left: 3%
    color: #000
    font-family: $font-family-regular
    font-size: 0.8vw

.header-poi-second-text
    position: absolute
    top: 18%
    width: 50%
    left: 3%
    color: #000
    font-family: $font-family-regular
    font-size: 0.8vw
