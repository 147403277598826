@import ./../../variables.style

.show-selection-main
    position: absolute
    width: 100%
    height: 100%
    top: 0%
    left: 50%
    transform: translate(-50%, 0%)
    display: grid
    grid-template-columns: repeat(3, 2fr)
    overflow-y: auto

.selected-card-main
    width: 7vw
    position: relative
    left: 50%
    top: 50%
    transform: translate(-50%,-50%)
    margin: 1vh
    height: 10vh
    background: #fff 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px
    img
        max-height: 100%
        min-height: 100%
        max-width: 100%
        min-width: 100%
        object-fit: contain