@import ./../../variables.style

.attributes-create-container
  position: absolute
  width: 38vw
  height: 85%
  background: #FFFFFF 0% 0% no-repeat padding-box
  top: 12%
  right: 2%
  border: 0.5px solid #BEBEBE
  border-radius: 8px

.attributes-create-title
  position: relative
  margin-top: 1rem
  margin-bottom: 1.5rem
  width: calc( 100% - 4rem )
  left: 2rem
  font-size: 1.125rem
  font-family: $font-family-semibold
  color: #043250
  height: 2.125rem
  display: flex
  align-items: center

.attributes-creation-container
  position: relative
  width: calc( 100% - 4rem )
  left: 2rem
  display: flex
  align-items: flex-end
  margin-bottom: 0.5rem

.attribute-creation-title
  position: relative
  margin-bottom: 0.5rem
  font-size: 0.875rem
  font-family: $font-family-semibold

.attribute-creation-selector
  position: relative
  margin-bottom: 0.5rem
  height: 1.875rem
  width: 11vw

.attribute-creation-name
  margin-right: 1.25rem

.attribute-creation-name-alt
  display: flex
  margin-bottom: 0.5rem
  margin-left: 0.5rem
  height: 1.875rem
  align-items: center
  input
    width: 1.25rem
    height: 1.25rem

.attribute-creation-name-alt-text
  width: 70%
  margin-left: 0.5rem
  font-size: 0.875rem
  color: #616161
  font-family: $font-family-regular

.attibutes-creation-value
  position: relative
  width: calc( 100% - 4rem )
  left: 2rem
  display: flex
  align-items: center
  input
    height: 1.75rem
    width: 10.85vw
    margin-right: 1.25rem

.create-attrbiute-value-btn
  cursor: pointer
  position: relative
  height: 1.75rem
  width: 6.438rem
  background: #ED0874 0% 0% no-repeat padding-box
  border-radius: 5px
  display: flex
  align-items: center
  justify-content: space-evenly
  color: #fff

.create-attribute-value-name
  font-size: 0.688rem
  font-family: $font-family-regular

.create-attribute-value-plus
  font-size: 1rem
  font-family: $font-family-semibold

.created-settings-attributes-container
  position: relative
  width: calc( 100% - 4rem )
  left: 2rem
  margin-top: 1rem
  height: 65%
  overflow-y: auto
  margin-bottom: 1rem

.save-cancel-attributes-btns
  position: relative
  width: calc( 100% - 4rem )
  left: 2rem
  margin-top: 1rem
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer

.cancel-attributes-btn
  width: 5.125rem
  height: 2.5rem
  background: #E0E0E0 0% 0% no-repeat padding-box
  border-radius: 5px
  display: flex
  align-items: center
  justify-content: center
  font-size: 0.875rem
  color: #616161
  font-family: $font-family-regular
  margin-right: 0.5rem
  margin-left: 0.5rem
  cursor: pointer

.save-attributes-btn
  width: 5.125rem
  height: 2.5rem
  background: #ED0874 0% 0% no-repeat padding-box
  border-radius: 5px
  display: flex
  align-items: center
  justify-content: center
  font-size: 0.875rem
  color: #fff
  font-family: $font-family-regular
  margin-right: 0.5rem
  margin-left: 0.5rem

.created-attribute-individual
  width: 100%
  height: 3rem
  box-shadow: 0px 0px 3px #00000021
  border: 0.5px solid #BEBEBE
  border-radius: 8px
  box-sizing: border-box
  display: flex
  align-items: center
  margin-bottom: 0.5rem
  z-index: 0

.attribute-individual-value-name
  color: #000000
  font-family: $font-family-semibold
  font-size: 0.875rem
  width: 15%

.attribute-individual-color
  display: flex

.attribute-individual-color-name
  color: #616161
  font-family: $font-family-regular
  font-size: 0.75rem
  margin-right: 0.75rem

.attribute-current-color-selected
  width: 1rem
  height: 1rem
  border: 0.5px solid #616161
  margin-right: 0.75rem
  cursor: pointer
  
.attributes-values-name-container
  position: relative
  display: flex
  align-items: center
  margin-bottom: 0.5rem
  div
    font-family: $font-family-semibold
    font-size: 0.875rem

.attributes-value-value
  margin-left: 1rem
  margin-right: 1rem

.attributes-value-colors
  position: absolute
  left: 61%
  transform: translate(-50%,0)
  text-align: center
  width: 50%

.attribute-value-container
  position: relative
  display: flex
  align-items: center
  img
    margin-left: 0.5rem
    cursor: pointer

.edition-input-container
  position: relative
  display: flex
  align-items: center
  width: 13rem
  height: 1.875rem
  input
    width: 6rem
    margin-right: 0.5rem
  div
    cursor: pointer
    border-radius: 5px
    color: #fff
    font-family: $font-family-regular
    background: #ED0874 0% 0% no-repeat padding-box
    width: 10rem
    height: 80%
    display: flex
    justify-content: center
    align-items: center
    font-size: 0.875rem

.color-picker-container
  position: absolute
  top: 100%
  z-index: 2
  left: 50%
  transform: translate(-50%,0)