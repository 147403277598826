@import ./../../variables.style

.icons-grid-container
    position: absolute
    width: 95%
    height: auto
    max-height: 75%
    top: 12%
    left: 50%
    transform: translate(-50%,0%)
    display: grid
    grid-template-columns: repeat(3, 2fr)
    overflow-y: auto

.icon-individual
    position: relative
    width: 90%
    left: 50%
    transform: translate(-50%, 0)
    border: 1px solid #BEBEBE
    height: 14vh
    margin-bottom: 1vh
    border-radius: 7px
    overflow-y: auto

.icon-folder-name
    position: absolute
    top: 10%
    left: 50%
    width: 80%
    text-align: center
    font-size: 0.9vw
    font-family: $font-family-semibold
    color: #043250
    transform: translate(-50%,0)

.icon-folder-container
    position: absolute
    top: 30%
    left: 50%
    transform: translate(-50%,0)
    display: grid
    grid-template-columns: repeat(3, 2fr)
    overflow-y: auto

.icon-folder-individual
    cursor: pointer
    position: relative
    width: 5vh
    height: 5vh
    margin: 2vh
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 0.5px solid #BEBEBE
    display: flex
    justify-content: center
    align-items: center
    img
        position: absolute
        max-width: 90%
        min-width: 90%
        max-height: 90%
        min-height: 90%
        object-fit: contain

.save-icon-btn
    cursor: pointer
    position: absolute
    right: 33%
    bottom: 3%
    width: 15%
    height: 5%
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 5px
    color: #fff
    font-family: $font-family-regular
    font-size: 0.8vw
    color: #fff
    display: flex
    justify-content: center
    align-items: center