@import ./../../variables.style
    
.header-builder-container
    position: absolute
    top: 3%
    left: 50%
    transform: translate(-50%,0)
    width: 90%
    height: 8%

.builder-title
    position: absolute
    top: 50%
    transform: translate(0, -50%)
    font-family: $font-family-semibold
    color: #043250
    font-size: 2vw

.new-builders-btn
    cursor: pointer
    position: absolute
    right: 0
    top: 50%
    transform: translate(0,-50%)
    background-color: #ED0874
    width: 15%
    min-width: 200px
    height: 65%
    color: #fff
    display: flex
    justify-content: center
    align-items: center
    font-family: $font-family-regular
    border-radius: 7px
    font-size: 0.9vw