@import ./../../variables.style

.dispo-table-container
    position: absolute
    top: 20%
    width: 90%
    left: 50%
    transform: translate(-50%,0)

.dispo-actaulizar-btn
    cursor: pointer
    background-color: #ED0874
    height: 100%
    width: 60%
    border-radius: 10px
    color: #fff
    display: flex
    justify-content: center
    align-items: center
    padding: 3%