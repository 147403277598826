@import ./../../variables.style

.link-create-container
    position: absolute
    top: 15%
    height: auto
    right: 1%
    width: 22.5%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 8px

.links-create-name
    position: relative
    left: 6%
    font-size: 1.2rem
    font-family: $font-family-semibold
    color: #043250
    margin-top: 1rem
    margin-bottom: 1rem

.links-create-text
    position: relative
    left: 6%
    width: 70%
    font-size: 0.8vw
    font-family: $font-family-regular
    color: #616161
    margin-bottom: 1rem

.links-create-name-container
    position: relative
    left: 6%
    width: 80%
    margin-bottom: 1rem
    input
        width: 100%
        height: 3vh

.links-create-input-title
    font-size: 0.8vw
    font-family: $font-family-regular
    color: #000
    margin-bottom: 3%

.links-create-url-container
    position: relative
    top: 45%
    left: 6%
    width: 80%
    margin-bottom: 1rem
    input
        width: 100%
        height: 3vh


.links-create-desc-container
    position: relative
    top: 58%
    left: 6%
    width: 80%
    height: 20%
    margin-bottom: 1rem
    textarea
        width: 100%
        height: 60%
        resize: none

.links-create-save
    cursor: pointer
    position: relative
    bottom: 6%
    left: 50%
    transform: translate(-50%,0)
    width: 8rem
    height: 2.5rem
    background-color: #ED0874
    color: #fff
    border-radius: 8px
    font-size: 0.8vw
    font-family: $font-family-regular
    display: flex
    justify-content: center
    align-items: center
    margin-bottom: 1rem

.gallery-links-preview
    position: absolute
    top: 10%
    left: 50%
    width: 80%
    transform: translate(-50%, 0)
    height: 70%

.gallery-link-container
    position: relative
    margin-bottom: 2%
    margin-top: 2%
    border: 1px solid #616161
    height: 10%
    border-radius: 8px

.gallery-link-url
    position: absolute
    left: 60%
    top: 50%
    transform: translate(-50%,-50%)
    font-size: 0.75rem
    font-family: $font-family-regular
    color: #000

.gallery-recorrido-name-des-container
    position: absolute
    top: 50%
    left: 3%
    transform: translate(0,-50%)

.gallery-recorrido-name
    font-size: 0.8vw
    font-family: $font-family-semibold
    color: #000

.gallery-recorrido-desc
    font-size: 0.8vw
    font-family: $font-family-regular
    color: #000

.close-gallery
    position: absolute
    width: 1rem
    height: 1rem
    right: 1rem
    top: 50%
    transform: translate(0%,-50%)
    cursor: pointer
    img
        width: 100%
        height: 100%

.edit-links
    position: absolute
    width: 1rem
    height: 1rem
    right: 3rem
    top: 42%
    transform: translate(0%,-50%)
    cursor: pointer

.preview-container
    position: absolute
    top: 50%
    left: 60%
    transform: translate(-50%,-50%)
    font-family: $font-family-regular
    font-size: 1rem

.input-desc-links-create
    font-family: $font-family-regular

.brochures-upload-file
    position: relative
    margin-bottom: 1.5rem
    height: auto
    width: 88%
    left: 50%
    transform: translate(-50%,0)
    display: flex

.brochures-preview-image-upload
    position: relative
    width: 5rem
    height: 5rem
    margin-right: 1rem
    background: #F5F5F5 0% 0% no-repeat padding-box
    border: 0.5px solid #707070
    border-radius: 5px
    img
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

.brochures-preview-link-title
    position: relative
    font-family: $font-family-semibold
    font-size: 1rem
    margin-bottom: 0.5rem

.brochures-preview-subtitle
    position: relative
    font-family: $font-family-regular
    font-size: 0.75rem
    margin-bottom: 0.25rem