@import ./../../variables.style
    
.attributes-container
    position: absolute
    top: 55%
    left: 58%
    height: 80%
    width: 78%
    transform: translate(-50%,-50%)

.atributtes-table
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 75%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 8px

.attributes-settings
    position: absolute
    top: 0
    right: 0
    height: 100%
    width: 23%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 8px

.ReactTags__tags
    margin-top: 10%

.ReactTags__tagInput
    width: 100%
    height: 2rem

.ReactTags__tagInputField
    width: 100%
    height: 100%

.ReactTags__selected
    width: 100%
    margin-top: 10%
    margin-bottom: 10%
    height: auto
    max-height: 10vh
    border: solid 1px #cacaca
    border-radius: 4px
    background: #fafafa
    display: grid
    grid-template-columns: repeat(3, 2fr)
    overflow-y: auto

.tag-wrapper
    margin: 5px
    border-radius: 4px
    padding: 0.3rem
    width: 80%
    background-color: #cacaca
    display: flex
    align-items: center
    text-align: center
    justify-content: space-around
    font-family: $font-family-regular

.ReactTags__remove
    border: none
    border-radius: 4px
    margin-left: 3px
    width: 20%

.attribute-selected-type-table
    position: relative
    width: 100%
    left: 0%
    height: 5vh
    display: flex
    align-items: center
    justify-content: space-between
    border: 1px solid
    border-radius: 5px
    margin-bottom: 2%

.attribute-select-name
    margin-left: 2%
    width: 6rem
    font-size: 0.875rem
    div
        position: relative
        margin-left: 0.5rem
        width: 1rem
        height: 1rem
        svg
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%,-50%)

.attribute-selecte-save-btn
    cursor: pointer
    margin-right: 2%
    background-color: #ED0874
    width: 10%
    height: 3vh
    border-radius: 5px
    display: flex
    justify-content: center
    align-items: center
    color: #fff
    font-size: 0.875rem

.attribute-select-type
    display: flex
    width: 40%
    justify-content: center

.select-type-name
    position: relative
    margin-right: 2%
    font-size: 0.875rem

.color-attribute-container
    position: relative
    width: 15vw
    display: flex
    align-items: center

.color-palette-preview-attributes
    cursor: pointer
    position: relative
    width: 1rem
    height: 1rem
    margin-left: 0.5rem
    margin-right: 0.5rem
    border: 1px solid #000

.layout-color-container
    position: relative
    display: flex
    align-items: center

.container
    position: relative
    width: 2vw

.warning-attributes
    font-family: $font-family-regular
    font-size: 0.875rem
    color: #ED0874

.rst__rowContents
    min-width: auto !important
    max-width: 10rem
    font-family: $font-family-regular
    
.attribute-active
    margin-top: 1rem
    display: flex
    align-items: center
    font-family: $font-family-regular
    font-size: 1rem
    input
        margin-right: 1rem