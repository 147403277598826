@import ./../../variables.style

.roads-create-icon-container
    position: relative
    left: 3%
    top: 5%
    width: 90%
    height: 10%
    display: flex

.roads-create-icon-selector
    position: relative
    width: 4.5vw
    height: 4.5vw
    background: #BEBEBE 0% 0% no-repeat padding-box
    border-radius: 5px
    img
        position: absolute
        top: 50%
        left: 50%
        max-width: 80%
        min-width: 80%
        max-height: 80%
        min-height: 80%
        object-fit: contain
        transform: translate(-50%,-50%)

.roads-create-info-text
    position: absolute
    left: 25%
    width: 80%

.roads-info-title
    position: relative
    margin-bottom: 1%
    color: #043250
    font-family: $font-family-semibold
    font-size: 0.875rem

.roads-info-subtitle
    position: relative
    margin-bottom: 1%
    color: #000000
    font-family: $font-family-regular
    font-size: 0.8rem

.rodas-info-text
    position: relative
    margin-bottom: 1%
    color: #939598
    font-family: $font-family-regular
    font-size: 0.8rem