@import ./../../variables.style
    
.gallery-container
    position: absolute
    top: 55%
    left: 59%
    width: 75%
    height: 80%
    transform: translate(-50%,-50%)
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 8px
    transition: all 0.3s ease-in-out

.gallery-container-links
    position: absolute
    top: 55%
    left: 48%
    width: 55%
    height: 80%
    transform: translate(-50%,-50%)
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 8px
    transition: all 0.3s ease-in-out

.gallery-upload-btn
    cursor: pointer
    position: absolute
    top: 4%
    right: 5%
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 5px
    color: #fff
    width: 9rem
    height: 5% 
    display: flex
    justify-content: center
    align-items: center
    font-family: $font-family-regular
    font-size: 0.8vw

.dz-message
    span
        font-family: $font-family-regular

.dropzone
    border-radius: 5px