@import ./../../variables.style

.gallery-cards-container-modal
    position: absolute
    width: 90%
    height: 80%
    top: 55%
    left: 50%
    transform: translate(-50%,-50%)
    display: grid
    grid-template-columns: repeat(3, 2fr)
    overflow-y: auto
    overflow-x: hidden

.individual-gallery-card-modal
    position: relative
    cursor: pointer
    margin-left: 0.25rem
    margin-right: 0.25rem
    margin-bottom: 0.5rem
    height: 7rem
    background: #F5F5F5 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px
    img
        position: absolute
        width: 3rem
        height: 3rem
        top: 35%
        left: 50%
        transform: translate(-50%,-50%)

.individual-gallery-name-modal
    position: absolute
    word-wrap: break-word
    top: 70%
    left: 50%
    width: 80%
    transform: translate(-50%,0)
    text-align: center
    font-family: $font-family-regular
    font-size: 0.75rem

.gallery-image-container-modal
    position: absolute
    top: 2%
    width: 90%
    height: 60%
    left: 50%
    transform: translate(-50%,0)
    img
        max-height: 100%
        min-height: 100%
        max-width: 100%
        min-width: 100%
        object-fit: contain