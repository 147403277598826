@import ./../../variables.style

.attributes-table-container
  position: absolute
  width: 38vw
  height: 85%
  background: #FFFFFF 0% 0% no-repeat padding-box
  top: 12%
  left: 20%
  border: 0.5px solid #BEBEBE
  border-radius: 8px

.attributes-table-title-btn-container
  position: relative
  margin-top: 1rem
  margin-bottom: 2rem
  width: calc( 100% - 4rem )
  left: 2rem
  display: flex
  align-items: center
  justify-content: space-between

.attributes-table-title
  font-size: 1.125rem
  font-family: $font-family-semibold
  color: #043250

.attributes-table-btn
  width: 9.625rem
  height: 2.125rem
  background: #ED0874 0% 0% no-repeat padding-box
  border-radius: 7px
  color: #fff
  display: flex
  font-size: 0.875rem
  cursor: pointer
  font-family: $font-family-regular
  justify-content: center
  align-items: center

.kermit-container
  position: absolute
  top: 50%
  left: 50%
  width: 80%
  height: 60%
  transform: translate(-50%,-50%)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  img
    max-height: 12rem
    min-height: 12rem
    max-width: 12rem
    min-width: 12rem
    object-fit: contain
    margin-bottom: 3rem

.kermit-warning
  color: #616161
  text-align: center
  font-size: 1.25rem
  font-family: $font-family-semibold

.created-attributes-container
  position: relative
  margin-bottom: 2rem
  width: calc( 100% - 4rem )
  left: 2rem
  height: 85%
  overflow-y: auto

.attrbiutes-individual-container
  width: 100%
  position: relative
  height: 3rem 
  box-shadow: 0px 0px 3px #00000021
  border: 0.5px solid #BEBEBE
  border-radius: 8px
  box-sizing: border-box
  margin-bottom: 1rem
  display: flex
  align-items: center

.attributes-individual-drag-controller
  cursor: grab
  position: relative
  margin-left: 1rem
  margin-right: 1rem
  width: 1.25rem
  height: 1.25rem
  img
    max-height: 100% 
    min-width: 100%
    min-width: 100%
    max-width: 100%
    object-fit: contain

.attributes-individual-name
  font-size: 1rem
  font-family: $font-family-semibold
  color: #000

.attributes-delete-edit-container
  position: absolute
  right: 0.5rem
  display: flex
  align-items: center
  justify-content: space-between

.attrbiutes-icon-container
  cursor: pointer
  width: 0.875rem
  height: 0.875rem
  margin-right: 0.5rem
  margin-left: 0.5rem
  img
    max-width: 100%
    min-width: 100%
    min-height: 100%
    max-height: 100%
    object-fit: contain

.attributes-table-tree-container
  position: absolute
  top: 3.5rem
  background-color: white
  width: 17.125rem 
  height: 15.875rem
  box-shadow: 0px 0px 6px #0000004D
  border: 0.5px solid #616161
  border-radius: 10px
  z-index: 2
  right: 2rem

.attributes-table-tree
  background: #FFFFFF 0% 0% no-repeat padding-box
  border: 0.5px solid #616161
  border-radius: 3px
  width: 15.125rem
  height: 9.188rem
  position: relative
  left: 50%
  transform: translate(-50%,0)
  overflow: auto

.attributes-tree-title
  margin-top: 2rem
  position: relative
  left: 1rem
  font-size: 1rem
  color: #043250
  font-family: $font-family-semibold
  margin-bottom: 0.75rem

.attributes-tree-text
  position: relative
  left: 1rem
  font-size: 0.75rem
  color: #000000
  font-family: $font-family-regular
  margin-bottom: 0.75rem

.attributes-individual-color-container
  cursor: pointer
  display: flex
  position: absolute
  left: 50%

.attributes-delete
  cursor: pointer
  position: absolute
  right: 1rem
  width: 1rem
  height: 1rem
    
.attributes-edit-active
  position: absolute
  left: 65%
  width: 1.125rem
  height: 1.125rem
  input
    width: 100%
    height: 100%

.attributes-list-names-container
  display: flex
  align-items: center
  justify-content: space-between
  width: calc( 100% - 5rem )
  position: relative
  left: 50%
  transform: translate(-50%,0)
  margin-bottom: 0.5rem
  font-family: $font-family-semibold
  font-size: 0.875rem

#root > div > div.attributes-table-container > div.attributes-list-names-container > div:nth-child(2)
  position: absolute
  left: 58%