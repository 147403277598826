@import ./../../variables.style

.color-palette-title
    position: absolute
    top: 5%
    left: 50%
    font-size: 1.125rem
    font-family: $font-family-semibold
    color: #043250
    transform: translate(-50%,0)

.color-palette-inputs-container
    z-index: 2
    position: absolute
    width: 75%
    top: 10%
    left: 50%
    height: 40%
    transform: translate(-50%,0)
    display: flex
    align-items: center
    justify-content: center

.color-palette-individual-container
    position: relative
    width: 28%
    margin-left: 1%
    margin-right: 1%
    height: 100%

.color-palette-individual-title
    position: absolute
    left: 50%
    top: 5%
    transform: translate(-50%,0)
    width: 100%
    text-align: center
    font-size: 0.8vw
    font-family: $font-family-regular

.color-palette-preview
    position: absolute
    top: 20%
    width: 60%
    height: 40%
    border: 2px solid #BEBEBE
    left: 50%
    transform: translate(-50%,0)

.color-palette-input
    position: absolute
    top: 70%
    left: 50%
    width: 50%
    transform: translate(-50%,0)

.color-palette-previews-container-main
    position: absolute
    top: 50%
    width: 90%
    height: 45%
    left: 50%
    transform: translate(-50%,0%)

.color-palette-previews-title
    position: absolute
    left: 50%
    font-family: $font-family-semibold
    font-size: 0.8vw
    transform: translate(-50%,0)

.color-palette-previews-container
    position: absolute
    top: 10%
    height: 80%
    width: 100%
    display: flex
    justify-content: center
    align-items: center

.color-palette-individual-preview
    position: relative
    width: 12.5%
    margin-left: 0.5%
    margin-right: 0.5%
    height: 100%

.color-palette-individual-img-container
    position: absolute
    top: 50%
    left: 50%
    width: 90%
    height: 50%
    transform: translate(-50%,-50%)
    border-radius: 10px
    svg
        position: absolute
        top: 40%
        left: 50%
        transform: translate(-50%,-50%)
        width: 30%
        height: 30%

.color-palette-individual-inner-text
    position: absolute
    bottom: 10%
    left: 50%
    font-family: $font-family-regular
    font-size: 0.8vw
    transform: translate(-50%,0)

.color-palette-individual-text
    position: absolute
    bottom: 5%
    left: 50%
    transform: translate(-50%,0)
    font-size: 0.8vw
    font-family: $font-family-semibold

.color-palette-outline-container
    position: relative
    width: 12.5%
    margin-left: 0.5%
    margin-right: 0.5%
    height: 100%

.palette-individual-text
    position: absolute
    bottom: 5%
    left: 50%
    transform: translate(-50%,0)
    font-size: 0.8vw
    font-family: $font-family-semibold

.color-palette-text-states-container
    position: relative
    width: 15%
    margin-left: 0.5%
    margin-right: 0.5%
    height: 100%

.color-palette-bilboards-container
    position: relative
    width: 18%
    margin-left: 0.5%
    margin-right: 0.5%
    height: 100%

.color-palette-outline-btn
    position: relative
    top: 40%
    height: 15%
    width: 90%
    left: 50%
    transform: translate(-50%,-50%)
    margin-top: 5%
    margin-bottom: 5%
    display: flex
    align-items: center
    justify-content: center
    font-size: 0.8vw
    font-family: $font-family-regular

.color-palette-amenities-billboard
    position: relative
    width: 50%
    svg
        position: absolute
        left: 50%
        transform: translate(-50%,-50%)

.color-palette-text-individual
    position: relative
    display: flex
    align-items: center
    background: #FAFAFA 0% 0% no-repeat padding-box
    border-radius: 5px 0px 0px 5px
    top: 40%
    height: 15%
    width: 90%
    left: 50%
    transform: translate(-50%,-50%)
    margin-top: 5%
    margin-bottom: 5%
    img
        height: 80%
        margin-right: 5%
        margin-left: 5%
    div
        font-family: $font-family-regular
        font-size: 0.8vw

.color-palette-lotes-billboard
    position: relative
    width: 50%
    svg
        position: absolute
        left: 50%
        transform: translate(-50%,-50%)

.svg-container
    position: absolute
    top: 50%
    width: 90%
    height: 50%
    display: flex
    align-items: center
    justify-content: center
    transform: translate(0,-50%)

.colorPicker-palette
    position: absolute
    top: 20%
    left: 80%
    z-index: 2
    