@import ./../../variables.style

.contact-information-container
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0

.salas-list-container
  position: absolute
  width: 62%
  height: 85%
  left: 19%
  background-color: #fff
  top: 13%
  border-radius: 10px
  border: 1px solid #BEBEBE

.create-salas-emails-container
  display: flex
  margin-bottom: 0.5rem
  input
    width: 60%
    height: 1.25rem
    margin-right: 0.5rem
  div
    cursor: pointer
    width: 30%
    color: #fff
    background-color: #ED0874
    display: flex
    justify-content: center
    align-items: center
    border-radius: 5px
    font-family: $font-family-regular
    font-size: 1.5vh

.create-salas-container
  position: absolute
  right: 1%
  width: 17%
  height: 85%
  background-color: #fff
  top: 13%
  border-radius: 10px
  border: 1px solid #BEBEBE
  overflow-y: auto
  overflow-x: hidden

.salas-list-name
  position: absolute
  top: 3%
  left: 3%
  font-size: 1.2vw
  font-family: $font-family-semibold

.create-salas-name
  position: relative
  margin-top: 8%
  margin-bottom: 10%
  left: 5%
  font-size: 2vh
  font-family: $font-family-semibold

.add-contact-info
  cursor: pointer
  position: relative
  bottom: 0%
  left: 50%
  width: 50%
  height: 5%
  text-align: center
  transform: translate(-50%, 100%)
  color: #fff
  background-color: #ED0874
  display: flex
  justify-content: center
  align-items: center
  border-radius: 5px
  font-family: $font-family-regular
  font-size: 1.5vh

.create-salas-general-info
  position: relative
  left: 10%
  width: 80%
  transform: translate(0%,0)
  margin-bottom: 8%

.create-salas-email-info
  position: relative
  left: 10%
  width: 85%
  min-height: 8%
  overflow-y: auto
  overflow-x: hidden
  margin-bottom: 1rem

.email-container
  font-family: $font-family-regular
  font-size: 1.5vh
  margin-top: 0.25rem
  margin-bottom: 0.75rem

.create-salas-
  position: relative
  margin-bottom: 5%
  font-size: 1.5vh
  font-family: $font-family-semibold

.crate-salas-input-name
  font-family: $font-family-regular
  font-size: 1.5vh

.create-salas-input-container
  margin-bottom: 4%
  input
    width: 100%
    height: 2vh

.create-salas-numbers-info
  position: relative
  left: 10%
  width: 85%
  min-height: 8%
  overflow-y: auto
  overflow-x: hidden

.create-salas-emails-name
  position: relative
  margin-bottom: 5%
  font-size: 1.5vh
  height: 8%
  display: flex
  align-items: center
  font-family: $font-family-semibold

.create-salas-numbers-name
  position: relative
  margin-bottom: 5%
  font-size: 1.5vh
  height: 8%
  display: flex
  align-items: center
  font-family: $font-family-semibold

.add-phone-btn
  cursor: pointer
  position: absolute
  top: 0
  right: 0 
  height: 1.5rem
  width: 35%
  color: #fff
  background-color: #ED0874
  display: flex
  justify-content: center
  align-items: center
  border-radius: 5px
  font-family: $font-family-regular
  font-size: 1.5vh

.create-salas-numbers-container
  width: 100%
  select
    margin-right: 2%
    width: 25%
    height: 3vh

#root > div > div.create-salas-container > div.create-salas-numbers-info > div.create-salas-numbers-container > input[type=text]:nth-child(2)
  width: 15%
  margin-right: 4%
  height: 3vh
  padding-left: 2%

#root > div > div.create-salas-container > div.create-salas-numbers-info > div.create-salas-numbers-container > input[type=text]:nth-child(3)
  width: 40%
  height: 3vh
  padding-left: 2%

.whatsapp-checkcontainer
  margin-top: 4%
  display: flex
  div
    margin-right: 5%
    font-size: 1.5vh
    font-family: $font-family-regular

.save-number-btn
  position: relative
  margin-top: 4%
  margin-bottom: 4%
  width: 40%
  left: 50%
  transform: translate(-50%,0)
  color: #fff
  background-color: #ED0874
  display: flex
  justify-content: center
  align-items: center
  border-radius: 5px
  font-family: $font-family-regular
  font-size: 1.5vh
  height: 4vh

.saved-phone-container
  margin-top: 4%
  margin-bottom: 4%
  position: relative

.saved-phone-type
  font-size: 1.5vh
  font-family: $font-family-semibold
  margin-bottom: 4%

.saved-phone-number
  font-size: 1.5vh
  font-family: $font-family-regular
  margin-bottom: 2%

.saved-phone-whatsapp
  font-size: 1.5vh
  font-family: $font-family-regular
  margin-bottom: 2%

.salas-list-data-container
  position: absolute
  top: 10%
  left: 3%
  width: 95%

.sala-individual-container
  min-height: 4vh
  margin-bottom: 2%
  border: 0.1vw #616161 solid
  border-radius: 10px

.minified-info-modal
  height: 5vh
  position: relative
  cursor: pointer
  img
    height: 20%
    position: absolute
    right: 1%
    top: 50%
    transform: translate(0,-50%)
    transition: all 0.3s ease-in-out

.uncollapsed-contact
  transform: translate(0,-50%) rotate(-180deg) !important
  //uncollapsed-contact

.minified-info-name
  position: absolute
  font-size: 1.6vh
  font-family: $font-family-semibold
  top: 50%
  left: 1%
  transform: translate(0,-50%)

.uncollapsed-contact-info
  position: relative
  left: 1%
  div
    margin-bottom: 1%
    font-family: $font-family-regular
    font-size: 1.5vh

.minified-delete-btn
  cursor: pointer
  position: absolute
  height: 80%
  width: 8%
  right: 5%
  top: 50%
  transform: translate(0%,-50%)
  color: #fff
  background-color: #ED0874
  display: flex
  justify-content: center
  align-items: center
  border-radius: 5px
  font-family: $font-family-regular
  font-size: 1.5vh

.minified-edit-btn
  cursor: pointer
  position: absolute
  height: 80%
  width: 8%
  right: 14%
  top: 50%
  transform: translate(0%,-50%)
  color: #fff
  background-color: #ED0874
  display: flex
  justify-content: center
  align-items: center
  border-radius: 5px
  font-family: $font-family-regular
  font-size: 1.5vh

.delete-number
  position: absolute
  width: 2vh
  height: 2vh
  top: 5%
  right: 5%

.contact-list-main-container
  position: absolute
  width: 48vw
  height: 85%
  background: #FFFFFF 0% 0% no-repeat padding-box
  top: 12%
  left: 20%
  border: 0.5px solid #BEBEBE
  border-radius: 8px

.create-new-contact-sala-main-container
  position: absolute
  width: 28vw
  height: 85%
  background: #FFFFFF 0% 0% no-repeat padding-box
  top: 12%
  right: 2%
  border: 0.5px solid #BEBEBE
  border-radius: 8px

.contact-list-main-container-title
  position: relative
  margin-top: 2rem
  margin-left: 2rem
  color: #043250
  font-family: $font-family-semibold
  font-size: 1.125rem

.create-new-contact-title
  position: relative
  margin-top: 2rem
  margin-left: 2rem
  color: #043250
  font-family: $font-family-semibold
  font-size: 1.125rem

.sala-list-data-container
  position: relative
  margin-top: 1rem
  width: calc( 100% - 4rem )
  left: 2rem

.sala-individual-main-container
  position: relative
  background: #FFFFFF 0% 0% no-repeat padding-box
  width: 100%
  height: auto
  min-height: 3rem
  box-shadow: 0px 0px 3px #00000021
  border: 0.5px solid #BEBEBE
  border-radius: 8px
  margin-bottom: 1rem

.sala-individual-brief-container
  cursor: pointer
  position: relative
  display: flex
  align-items: center
  height: 3rem

.sala-individual-brief-name
  margin-left: 0.875rem
  margin-right: 0.875rem
  color: #000
  font-family: $font-family-semibold
  font-size: 0.875rem

.sala-indiivudal-brief-arrow
  position: relative
  width: 1rem
  height: 1rem
  img
    position: absolute
    top: 50%
    left: 50%
    width: 100%
    height: 100%
    object-fit: contain
    transform: translate(-50%,-50%)

.sala-individual-brief-actions-container
  position: absolute
  right: 2%
  display: flex

.sala-individual-brief-individual-action
  cursor: pointer
  width: 1rem
  height: 1rem
  margin-left: 1rem
  img
    max-height: 100%
    min-height: 100%
    min-width: 100%
    max-width: 100%
    object-fit: contain

.create-new-contact-create-container
  position: relative
  margin-top: 1rem
  left: 2rem
  width: calc( 100% - 4rem )
  height: 82%
  margin-bottom: 1rem
  overflow-y: auto
  overflow-x: hidden

.create-new-contact-item
  width: 100%
  margin-bottom: 0.875rem
  input
    width: 100%
    height: 1.875rem
    box-sizing: border-box

.create-new-item-title
  position: relative
  font-family: $font-family-semibold
  color: #000
  margin-bottom: 0.25rem
  font-size: 0.875rem

.create-new-item-title-alt-container
  display: flex
  margin-bottom: 0.5rem

.create-new-item-input-container
  display: flex
  align-items: center
  margin-bottom: 0.5rem
  div
    margin-right: 0.5rem
    font-family: $font-family-regular
    font-size: 0.875rem
    margin-left: 0.5rem

.create-new-contact-item-alt
  margin-bottom: 1rem

.create-new-phone-btn
  background: #ED0874 0% 0% no-repeat padding-box
  border-radius: 5px
  height: 1.875rem
  width: 9.25rem
  cursor: pointer
  color: #fff
  font-size: 0.75rem
  display: flex
  align-items: center
  justify-content: center
  font-family: $font-family-regular

.input-container-short
  width: 26%
  height: 1.875rem

.input-container-large
  width: 35%
  height: 1.875rem

.create-cancel-contact-btn-contaienr
  display: flex
  width: calc( 100% - 4rem )
  position: relative
  left: 2rem
  align-items: center
  justify-content: center

.hour-item-alt-container
  font-family: $font-family-semibold
  font-size: 0.875rem
  width: 35%
  margin-right: 2.4rem

.time-item-alt-container
  font-family: $font-family-semibold
  font-size: 0.875rem
  width: 35%

.cancel-contact-btn
  cursor: pointer
  position: relative
  width: 5.125rem
  height: 2.5rem
  background: #E0E0E0 0% 0% no-repeat padding-box
  border-radius: 5px
  display: flex
  align-items: center
  justify-content: center
  font-family: $font-family-regular
  font-size: 0.75rem
  margin-left: 0.5rem
  margin-right: 0.5rem

.create-contact-btn
  cursor: pointer
  position: relative
  width: 5.125rem
  height: 2.5rem
  background: #ED0874 0% 0% no-repeat padding-box
  border-radius: 5px
  display: flex
  align-items: center
  justify-content: center
  font-family: $font-family-regular
  font-size: 0.75rem
  color: #fff
  margin-left: 0.5rem
  margin-right: 0.5rem

.create-new-items-phone-container
  display: flex
  align-items: center
  width: 100%

.whatsapp-container-contact
  display: flex
  margin-left: 0.5rem
  align-items: center

  input
    width: 1.5rem
    height: 1.5rem
    margin-right: 0.5rem
    font-size: 0.875rem
  div
    width: 80%
    font-family: $font-family-regular
    font-size: 0.875rem

.sala-individual-info-container
  position: relative
  left: 1rem
  div
    margin-bottom: 1rem
    font-family: $font-family-regular
    font-size: 0.875rem
    word-wrap: break-word
    width: 80%

strong
  font-family: $font-family-semibold

.numbers-to-show-contact
  position: relative
  margin-bottom: 0.5rem
  margin-top: 0.5rem
  font-family: $font-family-regular
  border: 1px solid #BEBEBE
  padding-top: 0.5rem
  padding-bottom: 0.5rem
  padding-left: 0.5rem
  font-size: 0.875rem

.close-numbers-array
  cursor: pointer
  position: absolute
  top: 50%
  right: 0.5rem
  width: 1rem
  height: 1rem
  transform: translate(0%,-50%)