@import ./../../variables.style

.disponibilidad-table-container
    position: absolute
    top: 13%
    left: 19%
    background-color: #F5F5F5
    border-radius: 10px
    border: 1px solid #616161
    height: 85%
    width: 79%
    overflow-y: auto