.login-screen
    position: absolute
    top: 0%
    left: 0%
    width: 100%
    height: 100%

.login-background-img
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
    min-width: 100%
    max-width: 100%
    min-height: 100%
    max-height: 100%
    object-fit: cover
