@import ./../../variables.style

.list-roads-container
    position: relative
    top: 15%
    width: 90%
    height: 40%
    left: 3%
    overflow-y: auto

.list-roads-individual-container
    position: relative
    margin-bottom: 2%
    height: 3.125rem
    border: 1px solid #616161
    border-radius: 10px

.list-roads-name-container
    position: absolute
    top: 50%
    left: 3%
    width: 40%
    height: 80%
    transform: translate(0,-50%)
    display: flex
    align-items: center
    img
        max-height: 40%
        min-height: 40%

.list-roads-name
    position: relative
    margin-right: 10%
    font-size: 0.75rem
    font-family: $font-family-regular

.delete-road
    cursor: pointer
    position: absolute
    width: 5%
    height: 80%
    right: 3%
    top: 50%
    transform: translate(0,-50%)
    img 
        max-width: 50%
        max-height: 50%
        min-width: 50%
        max-height: 50%
        object-fit: contain
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

.list-roads-color
    position: absolute
    top: 50%
    left: 60%
    transform: translate(-50%,-50%)
    width: 1.5rem
    height: 1.5rem

.list-roads-name-input
    width: 100%

.colorPicker-roads
    position: absolute
    bottom: 95%
    left: 55%