@import ./../../variables.style

.poi-category-container
    position: absolute
    width: 100%
    top: 28%
    left: 50%
    height: 68%
    overflow-x: auto
    transform: translate(-50%,0)

.poi-category-individual
    position: relative
    left: 50%
    height: 8%
    width: 30%
    margin-bottom: 2%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 4px
    transform: translate(-50%,0)

.poi-category-individual-name
    position: absolute
    top: 50%
    left: 12%
    color: #000
    font-family: $font-family-regular
    font-size: 0.8vw
    transform: translate(0,-50%)

.poi-category-input
    position: absolute
    right: 5%
    top: 50%
    transform: translate(0,-50%)
    width: 10%
    height: 50%

.header-disabled-zoom
    position: absolute
    top: 810%
    left: 50%
    transform: translate(-50%,0)
    display: flex
    align-items: center
    input
        width: 1.25rem
        height: 1.25rem
        margin-right: 1rem
    div
        font-family: $font-family-regular
        font-size: 0.8vw

.draggable-controller
    position: absolute
    top: 50%
    left: 2.5%
    transform: translate(0,-50%)
    width: 1rem
    height: 1rem
    cursor: move
    img
        position: absolute
        top: 50%
        left: 50%
        height: 1rem
        width: 1rem
        transform: translate(-50%,-50%)
        object-fit: contain