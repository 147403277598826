@import ./../../variables.style

.poi-icon-select-main
    position: absolute
    right: 2%
    top: 13%
    width: 21%
    height: 35%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px

.poi-icon-select-title
    position: relative
    top: 8%
    left: 3%
    color: #043250
    font-family: $font-family-semibold
    font-size: 1.125rem

.poi-icon-select-container
    position: relative
    top: 14%
    left: 3%
    width: 90%
    height: 40%

.poi-icon-selected-container
    position: absolute
    height: 4.5vw
    width: 4.5vw
    background: #BEBEBE 0% 0% no-repeat padding-box
    border: 1px solid #707070
    border-radius: 5px
    img
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        max-width: 80%
        min-width: 80%
        max-height: 80%
        min-height: 80%
        object-fit: contain

.poi-icon-text-container
    position: absolute
    left: 30%

.pois-info-title
    position: relative
    margin-bottom: 1%
    color: #000
    font-family: $font-family-regular
    font-size: .875rem

.pois-info-subtitle
    position: relative
    margin-bottom: 1%
    color: #000
    font-family: $font-family-regular
    font-size: .75rem

.pois-info-text
    position: relative
    margin-bottom: 1%
    color: #939598
    font-family: $font-family-regular
    font-size: .75rem

.input-icons-btn-poi
    position: absolute
    cursor: pointer
    background: #F5F5F5 0% 0% no-repeat padding-box
    border: 1px solid #707070
    border-radius: 5px
    color: #939598
    font-family: $font-family-regular
    font-size: 0.875rem
    width: 7rem 
    height: 2rem
    display: flex
    justify-content: center
    align-items: center