@import ./../../variables.style
    
.gallery-modal-container
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(1, 17, 27, 0.7) 0% 0% no-repeat padding-box

.gallery-modal-main
    position: absolute
    top: 50%
    left: 50%
    width: 60%
    height: 70%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #707070
    border-radius: 10px
    transform: translate(-50%,-50%)
    z-index: 3

.gallery-modal-title
    position: absolute
    top: 5%
    left: 5%
    font-size: 1.2rem
    font-family: $font-family-semibold
    color: #043250

.gallery-modal-subtitle
    position: absolute
    top: 10%
    left: 5%
    font-size: 0.9vw
    font-family: $font-family-regular
    color: #939598

.gallery-modal-cancel
    cursor: pointer
    position: absolute
    bottom: 3%
    left: 35%
    background: #BEBEBE 0% 0% no-repeat padding-box
    border-radius: 5px
    font-size: 0.9vw
    font-family: $font-family-regular
    color: #fff
    width: 12%
    height: 6%
    display: flex
    justify-content: center
    align-items: center

.gallery-modal-asign
    cursor: pointer
    position: absolute
    bottom: 3%
    right: 35%
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 5px
    font-size: 0.9vw
    font-family: $font-family-regular
    color: #fff
    width: 12%
    height: 6%
    display: flex
    justify-content: center
    align-items: center

.gallery-modal-cards-container
    position: absolute
    width: 45%
    left: 4%
    height: 60%
    top: 50%
    transform: translate(0,-50%)
    border: 1px solid #BEBEBE
    border-radius: 5px

.gallery-modal-selected-material
    position: absolute
    width: 45%
    right: 4%
    height: 60%
    top: 50%
    transform: translate(0,-50%)
    border: 1px solid #BEBEBE
    border-radius: 5px