.logos-container
    position: absolute
    top: 15%
    left: 50%
    width: 70%
    height: 10%
    transform: translate(-50%,-50%)

.logo-umbra-login
    position: absolute
    top: 50%
    left: 10%
    transform: translate(0,-50%)

.logo-cloud-login
    position: absolute
    top: 50%
    right: 10%
    transform: translate(0,-50%)

.login-logo-divisor
    position: absolute
    top: 50%
    left: 50%
    width: 0.2%
    height: 60%
    transform: translate(-50%,-50%)
    background-color: #000