@import ./../../variables.style

.road-create-container
    position: relative
    top: 14%
    left: 3%
    width: 90%
    border: 1px solid #BEBEBE
    border-radius: 5px

.roads-create-title-main
    position: relative
    margin-top: 2%
    left: 3%
    color: #043250
    font-family: $font-family-semibold
    font-size: 0.875rem

.create-roads-set-container
    position: relative
    margin-top: 2%
    display: flex
    justify-content: space-between
    align-items: flex-end
    width: 90%
    left: 3%

.create-roads-set-color
    margin-left: 1%
    input
        width: 20%

.create-roads-set-name
    width: 40%
    input
        height: 3vh
        width: 100%

.create-roads-name-title
    font-family: $font-family-regular
    font-size: 0.75rem
    margin-bottom: 1vh

.create-roads-color-title
    font-family: $font-family-regular
    font-size: 0.75rem
    margin-bottom: 1vh

.selected-color-container
    height: 3vh
    border: 1px solid #BEBEBE

.color-selector-input
    width: 25%
    input 
        height: 3vh
        width: 100%

.save-road-btn
    cursor: pointer
    width: 13%
    height: 3vh
    background: #ED0874 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 5px
    color: #fff
    font-size: .8rem
    font-family: $font-family-regular
    display: flex
    justify-content: center
    align-items: center

.create-roads-btns
    position: relative
    margin-top: 1rem
    margin-bottom: 1rem
    display: flex
    justify-content: space-evenly
    align-items: center

.map-draw-btn
    cursor: pointer
    width: 45%
    height: 3vh
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #ED0874
    border-radius: 4px
    color: #ED0874
    font-family: $font-family-regular
    font-size: 0.8vw
    display: flex
    justify-content: center
    align-items: center

.road-save-btn
    width: 30%
    height: 3vh
    background: #ED0874 0% 0% no-repeat padding-box
    border: 1px solid #ED0874
    border-radius: 4px
    color: #ffff
    font-family: $font-family-regular
    font-size: 0.8vw
    display: flex
    justify-content: center
    align-items: center

#root > div > div.roads-create-container > div.road-create-container > div.create-roads-set-container > div.create-roads-set-color > div:nth-child(3)
    z-index: 2
    position: absolute !important