.dashboard-background
    position: absolute
    min-width: 100%
    max-width: 100%
    min-height: 100%
    max-height: 100%
    object-fit: cover

.builders-container
    position: absolute
    width: 77%
    min-width: "500px"
    height: 87%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    top: 57%
    left: 50% 
    border-radius: 13px
    transform: translate(-50%,-50%)
    transition: all 0.3s ease-in-out
    overflow-y: auto

.builders-container-opened
    position: absolute
    width: 77%
    min-width: "500px"
    height: 87%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    top: 57%
    left: 41% 
    border-radius: 13px
    transform: translate(-50%,-50%)
    transition: all 0.3s ease-in-out