@import ./../../variables.style

.upload-spins-container
    position: absolute
    top: 13%
    left: 20%
    width: 58%
    height: 25%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px

.upload-spins-title
    position: absolute
    top: 8%
    left: 2.5%
    font-family: $font-family-semibold
    font-size: 1.125rem
    color: #043250

.upload-name-type-container
    position: absolute
    left: 3%
    top: 25%
    width: 50%
    height: 38%

.upload-spin-map-container
    position: absolute
    right: 30%
    top: 48%
    width: 35%
    height: 50%
    transform: translate(0,-50%)

.upload-container-name
    position: absolute
    height: 70%
    width: 40%
    input
        height: 30%
        width: 100%

.upload-continer-type
    position: absolute
    top: 60%
    height: 70%
    width: 40%
    left: 0%
    select
        height: 38%
        width: 100%

.upload-spin-name
    position: relative
    color: #000
    font-size: 0.875rem
    font-family: $font-family-semibold
    margin-bottom: 1.5%

.upload-spin-type
    position: relative
    color: #000
    font-size: 0.875rem
    font-family: $font-family-semibold
    margin-bottom: 1.5%

.upload-spin-container
    position: absolute
    width: 100%
    height: 90%
    border: 1px solid #939598
    border-radius: 3px
    input
        cursor: pointer
        border: 1px solid red
        position: absolute
        left: 25%
        top: 65%
        z-index: 3
        opacity: 0

.upload-map-container
    position: absolute
    width: 40%
    height: 90%
    left: 45%
    border: 1px solid #939598
    border-radius: 3px
    input
        position: absolute
        left: 30%
        top: 65%
        z-index: 2
        opacity: 0

.upload-spin-image-container
    position: absolute
    height: 4.5vw
    width: 4.5vw
    background-color: #F5F5F5
    top: 50%
    left: 2.5%
    transform: translate(0,-50%)
    border: 1px solid #939598
    border-radius: 3px
    img
        position: absolute
        top: 50%
        left: 50%
        max-height: 80%
        min-height: 80%
        max-width: 80%
        min-width: 80%
        object-fit: contain
        transform: translate(-50%,-50%)

.upload-spin-modal-title
    position: absolute
    left: 27.5%
    top: 10%
    color: #000
    font-size: 0.8vw
    font-family: $font-family-semibold

.upload-spin-modal-inst
    position: absolute
    left: 27.5%
    top: 27.5%
    color: #616161
    font-size: 0.7vw
    font-family: $font-family-regular

.upload-spin-btn
    cursor: pointer
    position: absolute
    left: 27.5%
    top: 65%
    height: 25%
    width: 30%
    background-color: #F5F5F5
    border: 1px solid #616161
    color: #616161
    border-radius: 5px
    font-family: $font-family-regular
    font-size: 0.7vw
    display: flex
    justify-content: center
    align-items: center

.upload-spin-modal-format
    position: absolute
    left: 27.5%
    top: 45%
    color: #616161
    font-size: 0.7vw
    font-family: $font-family-regular

.upload-map-image-container
    position: absolute
    height: 4.5vw
    width: 4.5vw
    background-color: #F5F5F5
    top: 50%
    left: 2.5%
    transform: translate(0,-50%)
    border: 1px solid #939598
    border-radius: 3px
    img
        position: absolute
        top: 50%
        left: 50%
        max-height: 80%
        min-height: 80%
        max-width: 80%
        min-width: 80%
        object-fit: contain
        transform: translate(-50%,-50%)

.upload-map-name
    position: absolute
    left: 27.5%
    top: 10%
    color: #000
    font-size: 0.8vw
    font-family: $font-family-semibold

.upload-map-format
    position: absolute
    left: 27.5%
    top: 27.5%
    color: #616161
    font-size: 0.7vw
    font-family: $font-family-regular

.upload-map-btn
    position: absolute
    left: 27.5%
    top: 65%
    height: 25%
    width: 30%
    background-color: #F5F5F5
    border: 1px solid #616161
    color: #616161
    border-radius: 5px
    font-family: $font-family-regular
    font-size: 0.7vw
    display: flex
    justify-content: center
    align-items: center

.upload-save-btn
    position: absolute
    cursor: pointer
    width: 5vw
    height: 4vh
    background-color: #ED0874
    display: flex
    justify-content: center
    align-items: center
    color: #fff
    font-family: $font-family-regular
    font-size: 0.875rem
    border-radius: 3px
    bottom: 10%
    left: 3%