@import ./../../variables.style

.icons-main-container
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(1, 17, 27, 0.7) 0% 0% no-repeat padding-box

.icons-container
    position: absolute
    width: 60%
    height: 75%
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box
    border: 1px solid rgba(190, 190, 190, 1)
    border-radius: 13px

.icons-name
    position: absolute
    top: 4%
    left: 4%
    font-family: $font-family-semibold 
    font-size: 0.9vw
    color: #043250

.icons-text
    position: absolute
    top: 7%
    left: 4%
    font-family: $font-family-regular
    font-size: 0.8vw
    color: #939598

.create-icons
    cursor: pointer
    position: absolute
    right: 4%
    top: 4%
    height: 5%
    width: 15%
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 5px
    font-family: $font-family-regular
    font-size: 0.8vw
    color: #fff
    display: flex
    justify-content: center
    align-items: center

.cancel-btn-icons
    position: absolute
    cursor: pointer
    left: 33%
    width: 15%
    height: 5%
    bottom: 3%
    background: #BEBEBE 0% 0% no-repeat padding-box
    border-radius: 5px
    font-family: $font-family-regular
    font-size: 0.8vw
    color: #fff
    display: flex
    justify-content: center
    align-items: center

.asign-btn-icons
    position: absolute
    cursor: pointer
    right: 33%
    width: 15%
    height: 5%
    bottom: 3%
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 5px
    font-family: $font-family-regular
    font-size: 0.8vw
    color: #fff
    display: flex
    justify-content: center
    align-items: center

.icons-upload-container
    position: absolute
    width: 60%
    height: 75%
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box
    border: 1px solid rgba(190, 190, 190, 1)
    border-radius: 13px