@import ./../../variables.style

.asoleacion-setup-container-main
    position: absolute
    top: 13%
    left: 20%
    background-color: #FFFFFF
    border: 1px solid #616161
    width: 58%
    height: 85%
    border-radius: 10px

.asoleacion-upload-title
    position: absolute
    top: 4%
    left: 3% 
    color: #043250
    font-size: 1.125rem
    font-family: $font-family-semibold

.asoleacion-upload-name-text
    position: absolute
    top: 10%
    left: 3%
    color: #000
    font-size: 0.8vw
    font-family: $font-family-regular

.asoleacion-upload-desc-text
    position: absolute
    top: 23%
    left: 3%
    color: #000
    font-size: 0.8vw
    font-family: $font-family-regular

.asolacion-list-vistas
    position: absolute
    left: 3%
    top: 38%
    width: 90%
    height: 40%

.asolacion-list-individual
    position: relative
    height: 15%
    width: 100%
    margin-bottom: 2%
    padding-left: 2%
    border-radius: 10px
    border: 1px solid #616161
    display: flex
    align-items: center
    font-size: 0.9vw
    font-family: $font-family-semibold



