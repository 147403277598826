@import ./../../variables.style

.amenitites-logo-container
    position: absolute
    top: 10%
    width: 90%
    height: 15%
    left: 50%
    transform: translate(-50%,0)

.amenities-logo-image
    position: absolute
    width: 5.5vw
    height: 5.5vw
    background: #BEBEBE 0% 0% no-repeat padding-box
    border-radius: 5px
    img
        position: absolute
        max-width: 80%
        min-width: 80%
        max-height: 80%
        min-height: 80%
        object-fit: contain
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

.amenities-logo-information
    position: absolute
    left: 15%

.logo-name-title-amenities
    position: relative
    font-family: $font-family-semibold 
    font-size: 0.9vw
    margin-bottom: 1%

.logo-format-subtitle-amenities
    position: relative
    font-family: $font-family-semibold 
    font-size: 0.75rem
    margin-bottom: 1%
    color: #939598

.logo-format-dubtitle-amenities
    position: relative
    font-family: $font-family-semibold 
    font-size: 0.75rem
    margin-bottom: 3%
    color: #939598

.inputfile-amenities
    position: absolute
    top: 130%
    left: 20%
    transform: translate(-50%,-50%)
    height: 60%
    width: 35%
    opacity: 0
    cursor: pointer
    z-index: 2

.input-amenities-icon-menu
    cursor: pointer
    position: absolute
    top: 100%
    left: 0%
    background: #F5F5F5 0% 0% no-repeat padding-box
    border: 0.5px solid #707070
    border-radius: 5px
    color: #707070
    width: 40%
    height: 60%
    font-family: $font-family-regular
    font-size: 0.85rem
    display: flex
    justify-content: center
    align-items: center