@import ./../../variables.style

.selector-container
    position: absolute
    top: 62.5%
    left: 50%
    width: 90%
    max-height: 50%
    height: auto
    transform: translate(-50%,-50%)
    display: grid
    grid-template-columns: repeat(3, 2fr)
    overflow-y: auto
    border: 1px solid #BEBEBE

.icon-individual-container
    position: relative
    height: 6vh
    border: 1px solid #BEBEBE
    border-radius: 4px
    margin: 2vh
    label
        width: 90%
        position: absolute
        font-family: $font-family-semibold
        font-size: 0.8vw
        left: 50%
        top: 50%
        transform: translate(-50%,-50%)
        text-align: center
        input
            position: absolute
            top: 50%
            right: 1%
            width: 3vh
            height: 3vh
            transform: translate(0,-50%) 

input[type=checkbox]:checked
    background-color: #ED0874
    accent-color: #ED0874


