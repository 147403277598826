@import ./../../variables.style

.spins-previews-container-main
    position: absolute
    top: 45%
    left: 20%
    width: 58%
    height: 53%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px
    overflow-y: auto

.spins-previews-title
    position: absolute
    top: 3%
    left: 3%
    font-family: $font-family-semibold
    font-size: 1.125rem
    color: #043250

.spins-preview-validation-info
    position: absolute
    left: 50%
    top: 12.5%
    transform: translate(-50%,0)
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #707070
    border-radius: 5px
    width: 60%

.spins-preview-validation-title
    position: relative
    margin-top: 2%
    margin-bottom: 2%
    left: 50%
    width: 100%
    text-align: center
    transform: translate(-50%,0)
    font-family: $font-family-regular
    font-size: 0.9vw
    color: #000

.spins-preview-line
    position: relative
    display: flex
    margin-bottom: 2%
    margin-top: 2%
    height: 30%

.spins-preview-info-individual-container
    position: relative
    width: 45%
    display: flex
    align-items: center
    margin-left: 2.5%
    margin-right: 2.5%
    height: 100%
    img
        max-height: 100%
        min-height: 100%
        object-fit: contain
        margin-right: 5%
    div
        font-family: $font-family-regular
        font-size: 0.8vw
        color: #000
