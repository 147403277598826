@import ./../../variables.style

.header-general-info-container
    position: relative
    margin-top: 3%
    width: 100%
    height: 2rem

.general-title
    position: absolute
    top: 50%
    left: 5%
    transform: translate(0%,-50%)
    font-size: 1.125rem
    font-family: $font-family-semibold
    color: #043250

.update-btn
    cursor: pointer
    position: absolute
    top: 50%
    right: 5%
    transform: translate(0%,-50%)
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 7px
    color: #fff
    height: 100%
    width: 10%
    font-family: $font-family-regular
    font-size: 0.9vw
    display: flex
    justify-content: center
    align-items: center