.builders-card-grid
    grid-template-columns: repeat(3, 2fr)

.builders-name
    font-size: 1.5vw

.new-builders-btn
    font-size: 1.5vw

.builders-projects
    font-size: 1.2vw

.builders-see-project
    font-size: 1.2vw

.breadcrumbs-builders
    font-size: 1.5vw

.nav-bar-container
    max-height: 6% !important
    padding: 3% !important

.builders-container-opened
    width: 65%
    left: 35%

.create-builder-container 
    min-width: 0px
    width: 30%

.create-title
    font-size: 1.8vw

.caption-logo-builder
    font-size: 1.5vw

.create-image-container
    width: 12vw
    height: 12vw

.create-btn
    width: 60%
    font-size: 1.5vw

.breadcrumbs-container
    font-size: 1.5vw

.project-name
    font-size: 1.4vw

.project-url-text
    font-size: 1.2vw

.MuiButtonBase-root
    padding: 1% !important

.project-see
    font-size: 1.2vw

.sidebar-header-title
    font-size: 1.8vw

.setup-general-container
    width: 72%
    left: 60%

.modules-container
    width: 72%
    left: 60%

.color-palette-container-main
    width: 72%
    left: 60%

.sidebar-header-subtitle
    font-size: 1.2vw

.project-data-main-title-no-sub-menu
    font-size: 1.3vw

.sidebar-main
    width: 19vw

.title-submenu
    font-size: 1.3vw
    width: 70%

.name-container
    width: 60%
    right: -10%

.general-properties-container
    width: 65%
    left: 60%

.submenu-btn
    div
        font-size: 1.2vw

.attributes-container
    left: 60%

.atributtes-table
    width: 70%

.disponibilidad-table-container
    left: 20%

.logo-name-title
    font-size: 1.3vw

.logo-format-subtitle
    font-size: 1.2vw

.logo-format-dubtitle
    font-size: 1.2vw

.logo-information
    width: 70%

.name-container
    right: -20%

.url-name-general-information
    font-size: 1.3vw

.modules-card
    width: 90%

.module-name
    font-size: 1.2vw

.color-palette-inputs-container
    width: 90%

.color-palette-individual-title
    font-size: 1.2vw

.color-palette-preview
    width: 80%

.color-palette-input
    width: 70%

.color-palette-previews-title
    font-size: 1.6vw
    width: 80%
    left: 50%
    transform: translate(-50%,0)
    text-align: center

.color-palette-individual-preview
    width: 15%

.color-palette-outline-container
    width: 15%

.color-palette-outline-btn
    font-size: 1.2vw

.color-palette-individual-text
    font-size: 1.2vw

.color-palette-text-states-container
    width: 15%

.color-palette-bilboards-container
    width: 15%

.svg-container
    display: block
    height: 70%

.color-palette-amenities-billboard
    position: absolute
    left: 50%
    top: 30%
    transform: translate(-50%, 0)

.color-palette-lotes-billboard
    position: absolute
    top: 80%
    left: 50%
    transform: translate(-50%,0)

.palette-individual-text
    font-size: 1.2vw

.general-propertiess-info-title
    font-size: 1.3vw

.general-properties-info-subtitle
    font-size: 1.2vw

.general-properties-info-text
    font-size: 1.2vw

.input-icons-btn-general-properties
    font-size: 1.2vw

.general-properties-icon-selected-container
    height: 6.5vw
    width: 6.5vw

.general-properties-attributes-text
    font-size: 1.3vw

.general-properties-imuebles-text
    font-size: 1.3vw

.general-properties-imuebles-btn
    font-size: 1.1vw

.general-properties-imuebles-view
    a
        width: 80%

.general-properties-attributes-view
    a
        width: 45%

.general-properties-attributes-btn
    font-size: 1.1vw

.properties-actions-options
    width: 30%

.info-roads-title-oval
    font-size: 1.2vw

.input-icons-btn
    height: 40%

.info-roads-text-oval
    font-size: 1vw

.information-logo-icon-oval
    font-size: 1.2vw
    top: 80%

.info-roads-title
    font-size: 1.2vw

.info-roads-text
    font-size: 1vw

.general-roads-location-search-container
    div
        font-size: 1.2vw
    input
        left: 30%
        width: 40%

.fijar-btn
    width: 50%
    left: 40%
    font-size: 1.2vw

.center-map
    width: 30%
    font-size: 1.2vw

.lng-show-container
    font-size: 1.2vw
    left: 20%

.lat-show-container
    font-size: 1.2vw
    right: 20%

.lote-title
    font-size: 1.2vw

.lote-draw-btn
    top: 40%
    width: 45%
    left: 40%

.lote-save
    top: 65%
    width: 45%
    left: 40%

.gallery-modal-main
    width: 80%

.gallery-cards-container-modal
    grid-template-columns: repeat(3,2fr)

.gallery-modal-cards-container
    overflow-y: auto

.individual-gallery-card-modal
    height: 12vh
    margin-bottom: 1vh
    margin-top: 1vh

.individual-gallery-name-modal
    font-size: 1vw

.gallery-modal-subtitle
    font-size: 1.2vw

.gallery-modal-cancel
    font-size: 1.1vw

.gallery-modal-asign
    font-size: 1.1vw

.gallery-image-card-modal
    width: 9vw

.gallery-image-name-modal
    font-size: 0.9vw

.lote-draw-btn
    font-size: 1vw

.lote-save
    font-size: 1vw

.roads-info-title
    font-size: 1.2vw

.roads-info-subtitle
    font-size: 1vw

.rodas-info-text
    font-size: 1vw

#root > div > div.roads-create-container > div.roads-create-icon-container > div.roads-create-info-text > div.input-icons-btn
    font-size: 1.2vw
    width: 60%

.roads-create-title
    font-size: 1.2vw

.roads-create-title-main
    font-size: 1.2vw

.create-roads-name-title
    font-size: 1.2vw

.create-roads-color-title
    font-size: 1.2vw

.road-save-btn
    font-size: 1vw

.map-draw-btn
    font-size: 1vw

.list-roads-name-container
    width: 40%

.list-roads-color
    width: 16%

.delete-road
    width: 8%

.header-poi-title
    font-size: 1.4vw

.header-poi-first-text
    font-size: 1.2vw

.header-poi-second-text
    font-size: 1.2vw

.poi-category-individual
    width: 45%

.poi-category-individual-name
    font-size: 1.2vw

.poi-icon-select-title
    font-size: 1.4vw

.pois-info-title
    font-size: 1.3vw

.pois-info-subtitle
    font-size: 1.1vw

.pois-info-text
    font-size: 1.1vw

.input-icons-btn-poi
    width: 80%
    font-size: 1vw

.create-poi-title
    font-size: 1.4vw

.crate-poi-input-container
    input
        width: 50%

.poi-create-btn
    font-size: 1vw

.pois-category-header-create-btn
    width: 20%

.pois-category-fijar-btn
    width: 50%
    font-size: 1.2vw 

.pois-category-current-lng
    width: 20%
    left: 20%
    font-size: 1.2vw
    text-align: center

.pois-category-current-lat
    width: 20%
    right: 20%
    font-size: 1.2vw
    text-align: center

.pois-ccategory-list-title
    font-size: 1.2vw

.poi-individual-name
    font-size: 1.1vw

.logo-name-title-amenities
    font-size: 1.2vw

.amenitie-individual-name
    font-size: 1.2vw

.default-amenities-title
    font-size: 1.2vw

.default-amenities-info-title
    font-size: 1vw

.default-amenities-info-text
    font-size: 1vw

.default-amenities-info-title
    font-size: 1.2vw

.default-amenities-select
    width: 100%
    font-size: 1vw

.default-amenities-select-text
    font-size: 1.2vw

.default-amenities-save
    width: 40%
    font-size: 1.1vw

.create-amenitie-new
    font-size: 1.2vw

.logo-format-dubtitle-amenities-new
    font-size: 1vw

.logo-name-title-amenities-new
    font-size: 1.2vw

.input-amenities-icon
    top: 120%
    height: 30%
    left: 0%
    width: 100%
    font-size: 1.1vw

.select-icon
    height: 30%
    width: 100%
    top: 80%
    font-size: 1.1vw

.create-new-name
    top: 110%

.create-new-save-btn
    width: 40%
    font-size: 1.1vw

.gallery-cards-container
    grid-template-columns: repeat(5, 2fr)

.individual-gallery-name
    font-size: 1.2vw

.individual-gallery-card
    height: 14vh

.gallery-preview-main
    grid-template-columns: repeat(4, 2fr)

.gallery-image-name
    font-size: 1vw 

.gallery-upload-btn
    font-size: 1vw
    width: 15%

.icons-container
    width: 90%

.icon-folder-container
    width: 80%

.icons-name
    font-size: 1.4vw

.icons-text
    font-size: 1.2vw

.icon-folder-name
    font-size: 1.2vw

.create-icons
    font-size: 1.2vw

.cancel-btn-icons
    font-size: 1.2vw

.asign-btn-icons
    font-size: 1.2vw

.icons-upload-container
    width: 90%

.upload-icon-info-main
    font-size: 1.4vw

.upload-icon-image
    width: 10vw
    height: 10vw

.upload-icon-info
    left: 30%

.upload-icon-info-format
    font-size: 1.2vw

.save-icon-btn
    font-size: 1.2vw

.upload-name-main
    font-size: 1.2vw 

.upload-icon-name
    input
        top: 40%

#root > div > div.icons-main-container.fade-in > div > div.upload-icon-main > div.upload-icon-container > div.upload-icon-info > div.input-icons-btn
    height: 50%

.icon-individual-container
    label
        font-size: 1.2vw

.upload-spin-name
    font-size: 1.2vw

.upload-spin-type
    font-size: 1.2vw

.upload-continer-type
    select
        width: 80%

.upload-spin-container
    width: 50%

.upload-map-container
    left: 55%
    width: 50%

.upload-spin-modal-title
    font-size: 1.3vw

.upload-map-name
    font-size: 1.3vw

.upload-map-format
    font-size: 1.1vw
    top: 38%

.upload-spin-modal-inst
    font-size: 1.1vw

.upload-spin-modal-format
    font-size: 1.1vw

.upload-spin-btn
    top: 70%
    width: 40%
    font-size: 1vw

.upload-map-btn
    top: 70%
    width: 40%
    font-size: 1vw

.pricing-btn-filter-container
    div
        font-size: 1.2vw

.select-filters
    font-size: 1.2vw

.activate-dispo-title
    font-size: 1.1vw

.activate-dispo-container
    width: 30%

.activate-dispo-state-name
    font-size: 1vw

.dispo-actaulizar-btn
    width: 100%
    padding: 5%
    font-size: 1.4vw

.quotation-icon-holder
    width: 7.5vw
    height: 7.5vw

.quotation-icon-container
    width: 80%

.quotation-icon-text-container
    left: 30%

.quotation-icon-container-title
    font-size: 1.2vw

.quotatuib-icon-text
    font-size: 1.2vw

.quotation-icon-btn
    font-size: 1.2vw
    width: 50%

.mailing-quotation-text
    font-size: 1vw

.mailing-quotation-save-btn
    width: 25%
    font-size: 1.2vw

.mailing-quotatuion-name-text
    font-size: 1.2vw

.mailing-quotatuion-name-desc
    font-size: 1.2vw

.quotation-mailing-container
    width: 80%

.quotation-mailing-holder
    width: 7.5vw
    height: 7.5vw

.quotation-mailing-text-container
    left: 30%

.quotation-mailing-title
    font-size: 1.2vw

.quotatuib-mailing-text
    font-size: 1vw

.quotation-mailing-btn
    width: 50%
    font-size: 1.2vw

.asoleacion-list-text
    font-size: 1vw

.asoleacion-icono-preview
    width: 6vw
    height: 6vw

.asoleacion-icono-selection-text-container
    left: 30%

.asoleacion-icono-selection-title
    font-size: 1.2vw

.asolaecion-icono-selection-text
    font-size: 1vw

.input-asoleacion-icon-btn
    width: 70%
    font-size: 1vw

.asolacion-icono-document-preview
    width: 6vw
    height: 6vw

.asolecion-documentos-selection-text-container
    left: 30%

.asoleacion-document-selection-title
    font-size: 1.2vw

.asoleacion-document-selection-text
    font-size: 1vw

.input-asoleacion-document-btn
    width: 80%
    font-size: 1vw

.asoleacion-upload-name-text
    font-size: 1.2vw

.asoleacion-upload-desc-text
    font-size: 1.2vw

.asoleacion-upload-icon-preview-container
    height: 30%

.asoleacion-upload-save-btn
    top: 90%
    width: 45%
    font-size: 1.2vw

.asoleacion-upload-text
    font-size: 1vw

.asoelacion-upload-title
    font-size: 1.2vw

.asoleacion-upload-btn
    font-size: 1vw
    width: 80%
    height: 30%

.princing-quotation-main-container
    overflow-x: auto !important

.pricing-table-container
    overflow-y: auto !important

.MuiPaper-elevation
    overflow: auto !important