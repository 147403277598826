@import ./../../variables.style

.setup-general-container
    position: relative

.general-info-main-container
    position: relative
    width: 62%
    left: 55%
    height: auto
    min-height: 70%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px
    transform: translate(-50%,0)
    margin-bottom: 2rem

.additional-logos-container
    margin-top: 2rem
    position: relative
    height: auto
    width: 90%
    left: 50%
    transform: translate(-50%,0)
    background: #FFFFFF 0% 0% no-repeat padding-box
    box-shadow: 0px 3px 6px #00000029
    border: 0.5px solid #616161
    border-radius: 5px
    height: auto
    min-height: 3.563rem

.additional-logo-name-icon
    cursor: pointer
    position: relative
    display: flex
    width: 100%
    min-height: 3.563rem
    align-items: center
    img
        min-height: 1rem
        max-height: 1rem
        min-width: 1rem
        max-width: 1rem

.aditional-logos-name
    margin-left: 1.5rem
    font-size: 0.875rem
    font-family: $font-family-semibold
    margin-right: 1.5rem

.aditioanl-logo-text
    position: relative
    margin-left: 1.5rem
    width: calc( 100% - 3rem )
    font-size: 0.875rem
    font-family: $font-family-regular
    color: #616161
    margin-bottom: 1rem

.additional-logo-btns
    cursor: pointer
    position: relative
    margin-left: 1.5rem
    margin-bottom: 1rem
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 5px
    display: flex
    height: 1.875rem
    width: 11.188rem
    font-size: 0.75rem
    font-family: $font-family-regular
    align-items: center
    justify-content: center
    color: #fff

.aditional-logo-individual-general-container
    margin-bottom: 1rem
    width: calc( 100% - 3rem )
    margin-left: 1.5rem
    display: grid
    grid-template-columns: repeat(2,1fr)

.aditional-logo-individual-container
    display: flex
    align-items: center
    margin-bottom: 1rem

.aditional-logo-icon
    position: relative
    width: 5.625rem
    height: 5.625rem
    background: #F5F5F5 0% 0% no-repeat padding-box
    border: 0.5px solid #707070
    border-radius: 5px
    margin-right: 1rem
    img
        position: absolute
        top: 50%
        left: 50%
        max-height: 60%
        min-height: 60%
        min-width: 60%
        max-width: 60%
        transform: translate(-50%,-50%)

.aditional-logo-title-text
    height: 5.625rem

.aditional-logo-individual-title
    color: #000
    font-family: $font-family-semibold
    font-size: 0.875rem
    margin-bottom: 0.5rem

.aditional-logo-individual-text
    color: #616161
    font-family: $font-family-regular
    font-size: 0.875rem
    margin-bottom: 0.5rem

.aditional-logo-individual-input-container
    display: flex
    input
        position: absolute
        opacity: 0

.aditional-logo-individual-upload
    background: #F5F5F5 0% 0% no-repeat padding-box
    border: 0.5px solid #616161
    border-radius: 5px
    width: 5.875rem
    height: 1.563rem
    font-size: 0.75rem
    display: flex
    justify-content: center
    align-items: center
    color: #616161
    font-family: $font-family-regular

.legal-text-container
    #outlined-basic-label
        display: none !important

.legal-text-title
    margin-bottom: 1rem
    font-family: $font-family-semibold

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root
    padding: 0rem 0rem 0rem 0rem !important
    textarea
        padding: 1rem