.fullscreen-component
    position: absolute
    background: rgba(0, 0, 0, 0.63) 0% 0% no-repeat padding-box
    width: 100%
    height: 100%
    top: 0
    left: 0
    z-index: 2
    img
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        max-height: 90%
        min-height: 90%
        min-width: 80%
        max-width: 80%
        object-fit: contain

.close-btn-gallery
    cursor: pointer
    position: absolute
    top: 2%
    right: 1%
    background: rgba(237, 8, 116, 1) 0% 0% no-repeat padding-box
    border-radius: 7px
    width: 4vh
    height: 4vh