@import ./../../variables.style
    
.list-spins-container-main
    position: absolute
    top: 40%
    left: 20%
    width: 58%
    height: 58%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px

.list-spins-title
    position: absolute
    top: 3%
    left: 2.5%
    font-family: $font-family-semibold
    font-size: 1.125rem
    color: #043250

.list-files-spins
    position: absolute
    top: 13%
    right: 1%
    width: 20%
    height: 85%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px

.list-files-spins-names
    position: absolute
    top: 1.5%
    font-family: $font-family-semibold
    font-size: 1.125rem
    color: #043250
    left: 3%

.list-files-text-container
    position: absolute
    top: 6%
    font-family: $font-family-regular
    width: 90%
    font-size: 0.8rem
    color: #000
    left: 3%
    p
        margin-bottom: 5%

h6
    font-family: $font-family-semibold
    color: #043250
    font-size: 0.8rem
    cursor: pointer

.list-files-general-container
    position: absolute
    top: 20%
    width: 90%
    height: 70%
    left: 50%
    transform: translate(-50%,0)
    overflow-y: auto
    overflow-x: hidden

.list-files-container
    position: relative
    border: 0.5px solid #616161
    border-radius: 5px
    width: 99%
    height: auto
    min-height: 4vh
    margin-bottom: 5%

.files-individual-container
    position: relative

.files-uncollapsed-radio-container
    opacity: 0
    height: 0vh
    pointer-events: none

.files-collapse-title-btn
    height: 5vh
    cursor: pointer

.files-collapse-title
    position: absolute
    top: 50%
    transform: translate(0,-50%)
    left: 5%
    font-family: $font-family-semibold
    font-size: 1rem
    color: #043250

.files-collapse-image-btn
    position: absolute
    width: 5%
    height: 100%
    right: 5%
    img
        position: absolute
        top: 50%
        transform: translate(0,-50%)

.files-radio-individual-container
    position: relative
    width: 90%
    height: 6vh
    margin-top: 2%
    margin-bottom: 2%
    left: 50%
    transform: translate(-50%,0)
    box-shadow: 0px 0px 6px #00000029
    border: 0.5px solid #616161
    border-radius: 5px

.group-name-spins
    position: relative
    width: 90%
    margin-bottom: 2%
    left: 50%
    transform: translate(-50%,0)
    font-family: $font-family-regular
    font-size: 0.875rem
    color: #043250

.files-input-container
    position: absolute
    left: 1%
    height: 100%
    width: 10%
    input
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        height: 100%

.files-name-date-container
    position: absolute
    left: 12%
    top: 50%
    transform: translate(0,-50%)
    font-size: 1rem

.files-name
    font-size: 0.875rem
    font-family: $font-family-semibold
    color: #000000
    letter-spacing: 0px

.files-date
    font-size: 0.875rem
    font-family: $font-family-regular
    color: #616161
    letter-spacing: 0px

.list-spins-container
    position: absolute
    top: 15%
    width: 95%
    left: 50%
    transform: translate(-50%,0)
    height: 80%
    overflow-y: auto
    overflow-x: hidden

.spins-container-individual
    position: relative
    width: 98%
    height: 5vh
    border: 0.5px solid #616161
    border-radius: 5px
    margin-bottom: 10px

.spin-container-name
    position: absolute
    top: 50%
    left: 1%
    height: 100%
    transform: translate(0,-50%)
    display: flex
    align-items: center
    width: 40%
    max-width: 40%

.spin-name
    font-family: $font-family-semibold
    font-size: 1rem
    margin-right: 5%

.spin-edition-container
    position: relative
    cursor: pointer
    img
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

.spin-type
    position: absolute
    top: 50%
    left: 40%
    transform: translate(-50%,-50%)
    font-family: $font-family-regular
    font-size: 1rem

.spin-close-btn
    cursor: pointer
    position: absolute
    top: 50%
    right: 1%
    width: 1rem
    height: 1rem
    transform: translate(0,-50%)
    img
        position: absolute
        top: 50%
        left: 50%
        max-width: 100%
        min-height: 100%
        min-width: 100%
        max-height: 100%
        object-fit: contain
        transform: translate(-50%,-50%)

.list-spins-info-container
    position: absolute
    top: 9%
    width: 95%
    left: 50%
    transform: translate(-50%,0)
    height: 2vh

.list-spins-info-name
    position: absolute
    top: 50%
    left: 0%
    transform: translate(0,-50%)
    font-size: 1rem
    font-family: $font-family-semibold

.list-spins-info-type
    position: absolute
    top: 50%
    left: 34.5%
    transform: translate(0,-50%)
    font-size: 1rem
    font-family: $font-family-semibold

.spins-container-name-edition
    position: relative
    left: 1%
    height: 100%
    display: flex
    align-items: center
    input
        margin-right: 2%

.spins-container-name-save-btn
    cursor: pointer
    width: 5vw
    height: 60%
    background-color: #ED0874
    display: flex
    justify-content: center
    align-items: center
    color: #fff
    font-family: $font-family-regular
    font-size: 0.875rem
    border-radius: 3px

.script-spin-selector
    width: 90%
    position: relative
    left: 50%
    transform: translate(-50%,0)
    display: flex
    font-family: $font-family-regular
    font-size: 0.875rem
    div
        margin-right: 0.5rem
        
