@import ./../../variables.style

.pois-category-map-container
    position: absolute
    width: 95%
    height: 65%
    top: 23%
    left: 50%
    transform: translate(-50%,0)

.pois-category-fijar-btn
    position: absolute
    left: 50%
    top: 103%
    transform: translate(-50%,0)
    font-family: $font-family-regular
    height: 6%
    width: 30%
    font-size: 0.8vw
    border: 1px solid #ED0874
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer

.pois-category-current-lng
    position: absolute
    top: 112%
    left: 30%
    font-family: $font-family-regular
    font-size: 0.75rem

.pois-category-current-lat
    position: absolute
    top: 112%
    right: 30%
    font-family: $font-family-regular
    font-size: 0.75rem