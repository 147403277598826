@import ./../../variables.style

.gallery-modal-properties-container
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(1, 17, 27, 0.7) 0% 0% no-repeat padding-box
  z-index: 3

.gallery-modal-properties-main-container
  position: absolute
  top: 52.5%
  left: 50%
  width: 60%
  height: 40.75rem
  background: #FFFFFF 0% 0% no-repeat padding-box
  border: 1px solid #BEBEBE
  border-radius: 10px
  transform: translate(-50%,-50%)

.gallery-modal-properties-title
  position: relative
  margin-top: 3rem
  left: 4rem
  width: calc( 100% - 8rem )
  color: #043250
  font-family: $font-family-semibold
  font-size: 1.125rem

.gallery-modal-properties-text
  position: relative
  margin-top: 1rem
  left: 4rem
  width: calc( 100% - 8rem )
  color: #000000
  font-family: $font-family-regular
  font-size: 0.875rem
  margin-bottom: 1rem

.gallery-modal-attributes-container
  position: relative
  left: 4rem
  width: calc( 100% - 8rem )
  display: flex
  align-items: center

.gallery-modal-attributes-individual-container
  margin-right: 1rem

.gallery-modal-attributes-individual-title
  position: relative
  margin-bottom: 1rem
  font-size: 0.875rem
  font-family: $font-family-semibold
  color: #014976

.gallery-modal-attributes-individual-select
  width: 10rem
  height: 1.875rem
  select
    width: 100%
    height: 100%

.gallery-modal-container-galleries
  position: relative
  left: 4rem
  margin-top: 1rem
  width: calc( 100% - 8rem )
  display: flex
  height: 60%
  align-items: center
  justify-content: space-between

.gallery-container-breadcrumbs-cards
  position: relative
  width: 49%
  height: 100%
  background: #FFF 0% 0% no-repeat padding-box
  border: 0.5px solid #616161
  border-radius: 5px

.save-cancel-gallery-modal-properties
  position: relative
  margin-top: 1rem
  left: 4rem
  width: calc( 100% - 8rem )
  display: flex
  align-items: center
  justify-content: center

.cancel-btn-gallery-modal
  position: relative
  margin-left: 0.5rem
  margin-right: 0.5rem
  width: 4.875rem
  background: #E0E0E0 0% 0% no-repeat padding-box
  border-radius: 5px
  height: 2.125rem
  display: flex
  justify-content: center
  align-items: center
  font-family: $font-family-regular
  font-size: 0.875rem
  cursor: pointer
  color: #616161

.save-btn-gallery-modal
  position: relative
  margin-left: 0.5rem
  margin-right: 0.5rem
  width: 8.125rem
  background: #ED0874 0% 0% no-repeat padding-box
  border-radius: 5px
  height: 2.125rem
  display: flex
  justify-content: center
  align-items: center
  font-family: $font-family-regular
  font-size: 0.875rem
  cursor: pointer
  color: #fff

.breadcrumbs-container-gallery-modal-properties
  position: relative
  margin-top: 0.5rem
  display: flex
  left: 1rem
  font-family: $font-family-regular
  font-size: 0.875rem

.bread-crumbs-container-text
  margin-right: 1rem
  cursor: pointer

.gallery-container-breadcrumbs-title
  position: relative
  margin-top: 0.5rem
  text-align: center
  font-family: $font-family-regular
  color: #8F8F8F
  font-size: 0.875rem

.gallery-modal-gallery-container
  position: relative
  left: 1rem
  margin-top: 1rem
  width: calc( 100% - 2rem )
  display: grid
  grid-template-columns: repeat(3,2fr)

.gallery-modal-gallery-container-previews
  position: relative
  left: 1rem
  margin-top: 1rem
  width: calc( 100% - 2rem )
  display: flex
  flex-wrap: wrap
  max-height: 80%
  overflow-y: auto
  overflow-x: hidden

.gallery-modal-gallery-container-individual
  cursor: pointer
  position: relative
  display: flex
  background: #F5F5F5 0% 0% no-repeat padding-box
  border: 0.5px solid #616161
  border-radius: 5px
  height: 2.5rem
  width: 8.125rem
  display: flex
  align-items: center
  font-family: $font-family-regular
  font-size: 0.75rem

.gallery-modal-gallery-icon
  margin-left: 0.5rem
  margin-right: 0.5rem
  width: 1.313rem
  height: 1.313rem
  img
    width: 100%
    height: 100%


.preview-image-container
  position: relative
  width: 8rem
  height: 7rem
  margin-left: 0.5rem
  margin-right: 0.5rem
  background: #F5F5F5 0% 0% no-repeat padding-box
  border: 0.5px solid #616161
  border-radius: 5px
  cursor: pointer
  margin-bottom: 1rem

.preview-iamge-gallery-container
  width: 100%
  height: 70%
  border-radius: 5px
  img
    width: 100%
    height: 100%
    object-fit: cover
    border-radius: 5px

.preview-image-title
  position: relative
  width: 100%
  height: 30%
  display: flex
  justify-content: center
  align-items: center
  font-family: $font-family-regular
  font-size: 0.75rem
  text-align: center

.preview-link-container
  position: relative
  width: 8rem
  height: 2.5rem
  margin-left: 0.5rem
  margin-right: 0.5rem
  background: #F5F5F5 0% 0% no-repeat padding-box
  border: 0.5px solid #616161
  border-radius: 5px
  cursor: pointer
  font-family: $font-family-regular
  font-size: 0.75rem
  text-align: center
  display: flex
  justify-content: center
  align-items: center

.gallery-container-images-selected
  display: flex
  margin-top: 1rem
  flex-wrap: wrap
  position: relative
  left: 1rem
  max-height: 85%
  overflow-y: auto
  overflow-x: hidden

.gallery-modal-delete-btn
  position: absolute
  top: 0.25rem
  right: 0.25rem
  width: 1rem
  height: 1rem
  background: #ED0874 0% 0% no-repeat padding-box
  cursor: pointer
  border-radius: 2px
  img
    position: absolute
    width: 60%
    height: 60%
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
