@import ./../../variables.style

.lote-container-main
    position: absolute
    top: 11%
    right: 1%
    width: 23.5%
    height: 20%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px

.lote-title
    position: absolute
    top: 10%
    left: 3%
    color: #043250
    font-family: $font-family-semibold
    font-size: 0.9vw

.lote-color-container
    position: absolute
    top: 50%
    left: 5%
    width: 50%
    height: 40%
    transform: translate(0,-50%)

.lote-color-title
    color: #043250
    font-family: $font-family-semibold
    font-size: .875rem
    margin-bottom: 5%

.lote-color-subcontainer
    display: flex

.lote-color-container-div
    width: 15%
    margin-right: 5%

.lote-colorPicker
    top: 110%
    z-index: 2
    position: absolute !important

.lote-draw-btn
    cursor: pointer
    position: absolute
    top: 60%
    left: 30%
    width: 20%
    height: 20%
    transform: translate(0,-50%)
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #ED0874
    border-radius: 4px
    color: #ED0874
    font-family: $font-family-regular
    font-size: 0.8vw
    display: flex
    justify-content: center
    align-items: center

.lote-save
    cursor: pointer
    position: absolute
    top: 60%
    left: 52%
    width: 20%
    height: 20%
    transform: translate(0,-50%)
    background: #ED0874 0% 0% no-repeat padding-box
    border: 1px solid #ED0874
    border-radius: 4px
    color: #fff
    font-family: $font-family-regular
    font-size: 0.8vw
    display: flex
    justify-content: center
    align-items: center

.lote-delete
    cursor: pointer
    position: absolute
    top: 60%
    left: 74%
    width: 20%
    height: 20%
    transform: translate(0,-50%)
    background: #ED0874 0% 0% no-repeat padding-box
    border: 1px solid #ED0874
    border-radius: 4px
    color: #fff
    font-family: $font-family-regular
    font-size: 0.8vw
    display: flex
    justify-content: center
    align-items: center

.lote-icon-creation-container
    position: absolute
    top: 11%
    right: 0.5%
    background-color: #fff
    width: 24.5%
    border-radius: 10px
    border: 0.5px solid #616161
    height: 30vh

.lote-icon-creation-title
    position: relative
    margin-top: 1rem
    margin-bottom: 1rem
    left: 1rem
    color: #043250
    font-family: $font-family-semibold
    font-size: 1rem

.icon-creation-container
    position: relative
    width: calc( 100% - 2rem )
    left: 1rem
    display: flex
    align-items: center

.icon-creation-image-container
    position: relative
    width: 5rem
    height: 5rem
    background: #E0E0E0 0% 0% no-repeat padding-box
    border-radius: 5px
    margin-right: 1rem
    img
        position: absolute
        width: 80%
        height: 80%
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

.icon-creation-information-title
    position: relative
    margin-bottom: 0.5rem
    font-family: $font-family-semibold
    font-size: 0.875rem

.icon-creation-information-text
    position: relative
    margin-bottom: 0.5rem
    font-family: $font-family-regular
    font-size: 0.75rem
    color: #616161

.icon-creation-information-input-container
    input
        position: relative
        width: 5.625rem
        height: 1.5rem
        opacity: 0

.icon-creation-information-btn
    position: absolute
    border: 1px solid #E0E0E0
    background: #F5F5F5 0% 0% no-repeat padding-box
    border-radius: 3px
    width: 5.625rem
    height: 1.5rem
    display: flex
    align-items: center
    justify-content: center
    font-family: $font-family-regular
    font-size: 0.75rem

.lote-creation-container
    position: relative
    width: calc( 100% - 2rem )
    left: 1rem
    margin-top: 1rem

.lote-creation-title
    position: relative
    margin-top: 0.5rem
    margin-bottom: 0.5rem
    color: #043250
    font-family: $font-family-semibold
    font-size: 0.875rem

.lote-creation-options
    position: relative
    display: flex
    align-items: center
    input
        height: 1.875rem
        margin-right: 0.5rem
        width: 5rem
        border-radius: 5px
        border: 0.5px solid #000

.lote-color-container-thumb
    cursor: pointer
    position: relative
    width: 1.875rem
    height: 1.875rem
    margin-right: 0.5rem

.sketch-picker
    top: 100%
    position: absolute
    z-index: 2

.lote-draw-btn-creation
    cursor: pointer
    border-radius: 4px
    border: 0.5px solid #ED0874
    background: #FFFFFF 0% 0% no-repeat padding-box
    font-size: .75rem
    color: #ED0874
    height: 1.875rem
    width: 7.5rem
    font-family: $font-family-regular
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    margin-right: 0.5rem

.lote-save-btn-creation
    cursor: pointer
    background: #ED0874 0% 0% no-repeat padding-box
    border: 0.5px solid #ED0874
    border-radius: 4px
    height: 1.875rem
    width: 4.375rem
    color: #fff
    font-size: 0.75rem
    font-family: $font-family-regular
    display: flex
    justify-content: center
    align-items: center

.lote-container-created
    position: relative
    width: calc( 100% - 2rem )
    left: 1rem
    margin-top: 1rem
    display: flex
    border: 0.5px solid #616161
    border-radius: 5px
    background: #FFFFFF 0% 0% no-repeat padding-box
    align-items: center
    height: 2.5rem
    div
        position: absolute
        left: 0.5rem
        font-family: $font-family-semibold
        font-size: 0.75rem
    img
        width: 1rem
        height: 1rem
        position: absolute
        right: 0.5rem