@import ./../../variables.style
    
.builders-card-grid
    display: grid
    position: absolute
    top: 20%
    width: 95%
    left: 50%
    height: auto
    grid-template-columns: repeat(5, 2fr)
    transform: translate(-50%,0)

.builders-cards
    position: relative
    width: 90%
    min-width: 50px
    margin-left: 5%
    margin-right: 5%
    margin-bottom: 10%
    height: 30vh
    border-radius: 10px 10px 10px 10px
    border: 1px solid #BEBEBE
    background: #F5F5F5 0% 0% no-repeat padding-box

.buiders-image-container
    position: absolute
    top: 0
    left: 0
    height: 52%
    width: 100%
    background-color: #fff
    border-radius: 10px 10px 0px 0px
    img
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        min-height: 80%
        max-height: 80%
        min-width: 80%
        max-width: 80%
        object-fit: contain

.builders-info-container
    position: absolute
    top: 52%
    height: 48%
    width: 100%

.builders-name
    position: relative
    top: 5%
    left: 50%
    width: 80%
    text-align: center
    font-family: $font-family-semibold
    transform: translate(-50%,0)
    font-size: 0.9vw

.builders-projects
    position: relative
    left: 50%
    top: 7.5%
    text-align: center
    font-family: $font-family-regular
    transform: translate(-50%,0)
    font-size: 0.8vw

.builders-options-container
    position: absolute
    top: 60%
    left: 10%
    display: flex

.builders-divider
    position: absolute
    top: 60%
    left: 50%
    transform: translate(-50%,0)
    width: 1.5px
    height: 30%
    background-color: #BEBEBE

.builders-see-project
    cursor: pointer
    position: absolute
    top: 60%
    right: 10%
    width: 30%
    height: 30%
    background-color: #ED0874
    color: #fff
    font-family: $font-family-semibold
    display: flex
    justify-content: center
    align-items: center
    font-size: 0.9vw
    border-radius: 7px