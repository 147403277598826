@import ./../../variables.style
    
.form-login
    position: absolute
    top: 58%
    left: 50%
    transform: translate(-50%,-50%)
    width: 70%

.error-code-login
    position: relative
    margin-top: 1rem
    font-size: 1rem
    font-family: $font-family-semibold
    color: rgb(237, 8, 116)