.canvas-content
    width: 80%
    height: 90%
    position: absolute
    top: 45%
    left: 50%
    transform: translate(-50%,0%)
    margin-bottom: 5%

.canvas-3d
    width: 100%
    height: 100%
    position: absolute
    top: 0

.camera-frames
    width: 100%
    height: 100%
    img
        position: absolute
        top: 50%
        left: 50%
        max-width: 100%
        min-width: 100%
        max-height: 100%
        min-height: 100%
        object-fit: cover
        transform: translate(-50%,-50%)

.lazy-load-image-background
    position: absolute
    pointer-events: all