.create-poi-container
    position: absolute
    right: 2%
    top: 50%
    width: 21%
    height: 15%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px

.create-poi-title
    position: absolute
    top: 10%
    left: 3%
    color: #043250
    font-family: $font-family-semibold
    font-size: 0.9vw

.crate-poi-input-container
    position: absolute
    top: 50%
    left: 50%
    width: 90%
    height: 20%
    transform: translate(-50%,-50%)
    display: flex
    align-items: center
    justify-content: flex-start
    input
        height: 80%
        margin-right: 5%

.poi-create-btn
    cursor: pointer
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 5px
    display: flex
    align-items: center
    justify-content: center
    font-family: $font-family-regular
    font-size: 0.8vw
    color: #fff
    height: 100%
    width: 30%