@import ./../../variables.style

.general-roads-container
    position: absolute
    top: 11%
    left: 19% 
    width: 55%
    height: 88%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px

.general-roads-title
    position: relative
    top: 3%
    left: 3%
    color: #043250
    font-family: $font-family-semibold
    font-size: 1.125rem