@import ./../../variables.style

b
    font-family: $font-family-semibold

.pois-category-container-main
    position: absolute
    top: 13%
    left: 20%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px
    height: 85%
    width: 55%

.pois-category-header-title
    position: absolute
    top: 5%
    left: 3%
    color: #043250
    font-family: $font-family-semibold
    font-size: 1.125rem

.pois-category-header-subtitle
    position: absolute
    top: 10%
    left: 3%
    color: #000
    font-family: $font-family-regular
    font-size: .875rem

.pois-category-header-create
    position: absolute
    top: 13%
    left: 3%
    width: 60%
    height: 3.5%
    display: flex
    input
        position: relative
        height: 100%
        max-height: 100%
        width: 70%
        margin-right: 5%

.pois-category-header-create-btn
    cursor: pointer
    position: relative
    width: 10%
    height: 100%
    top: 0
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #ED0874
    color: #ED0874
    font-family: $font-family-semibold
    font-size: 0.75rem
    border-radius: 4px
    display: flex
    justify-content: center
    align-items: center

.pois-category-header-text
    position: absolute
    top: 19%
    left: 3%
    color: #000
    font-family: $font-family-regular
    font-size: .875rem