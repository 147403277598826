@import ./../../variables.style

.header-dispo-title
    position: absolute
    top: 3%
    left: 3%
    color: #043250
    font-size: 1.125rem
    font-family: $font-family-semibold

.activate-dispo-container
    position: absolute
    width: 20%
    height: 8%
    top: 8%
    right: 30%
    background-color: #FFFFFF
    border: 1px solid #616161

.activate-dispo-states-container
    position: absolute
    left: 15%
    width: 30%
    top: 9%
    height: 5%
    background-color: #FFFFFF
    border: 1px solid #616161
    display: flex
    justify-content: space-evenly

.activate-dispo-in-masterplan-states-container
    position: absolute
    right: 3%
    width: 40%
    top: 9%
    height: 5%
    background-color: #FFFFFF
    border: 1px solid #616161
    display: flex
    justify-content: space-evenly

.activate-dispo-states-individual-container
    position: relative
    width: 30%

.activate-dispo-masterplan-states-individual-container
    position: relative
    width: 30%
    display: inline-flex
    align-items: center
    justify-content: center

    label
        font-size: 0.6rem

.activate-dispo-state-name
    position: relative
    text-align: center
    color: #000
    font-size: 0.6rem
    font-family: $font-family-regular

.activate-dispo-title
    position: absolute
    top: 50%
    left: 5%
    transform: translate(0,-50%)
    color: #000
    font-size: 0.8vw
    font-family: $font-family-regular

.activate-dispo-switch
    position: absolute !important
    right: 5%
    top: 50%
    transform: translate(0,-50%)

.switch-state
    position: relative
    margin-top: 2%
    left: 50%
    transform: translate(-50%, 0)

.header-filter-hierarchy-first
    cursor: pointer
    position: absolute
    top: 9%
    height: 6%
    width: 10%
    left: 3%
    background-color: #ED0874
    border-radius: 10px
    color: #fff
    font-size: 0.8vw
    font-family: $font-family-regular
    display: flex
    justify-content: center
    align-items: center

.header-filter-hierarchy-second
    cursor: pointer
    position: absolute
    top: 9%
    height: 6%
    width: 10%
    left: 15%
    background-color: #ED0874
    border-radius: 10px
    color: #fff
    font-size: 0.8vw
    font-family: $font-family-regular
    display: flex
    justify-content: center
    align-items: center

.select-filters
    background-color: #ED0874
    border: none
    color: #fff
    font-size: 0.8vw