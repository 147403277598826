@import ./../../variables.style

.properties-main-table-container
  position: absolute
  background: #FFFFFF 0% 0% no-repeat padding-box
  border-radius: 10px
  top: 12%
  left: 19%
  width: 80%
  height: 86%

.properties-table-header
  position: relative
  width: calc( 100% - 4rem )
  left: 2rem
  margin-top: 2rem
  margin-bottom: 1rem
  display: flex
  align-items: center
  justify-content: space-between

.properties-table-title
  font-family: $font-family-semibold
  color: #043250
  font-size: 1.125rem

.porperties-table-btns-container
  display: flex

.properties-create-new-btn
  cursor: pointer
  height: 2.5rem
  width: 8.625rem
  background: #ED0874 0% 0% no-repeat padding-box
  border-radius: 7px
  margin-right: 1rem
  display: flex
  align-items: center
  justify-content: center
  color: #fff
  font-family: $font-family-regular
  font-size: 0.875rem

.properties-table-actions-btn
  cursor: pointer
  height: 2.5rem
  width: 5rem
  background: #ED0874 0% 0% no-repeat padding-box
  border-radius: 7px
  display: flex
  align-items: center
  justify-content: center
  color: #fff
  font-family: $font-family-regular
  font-size: 0.875rem

  .custom-select .Select-clear-zone
    display: none

.properties-table-actions-btn-small-default
  cursor: pointer
  height: 2rem
  width: 3rem
  background: #D4D4D4 0% 0% no-repeat padding-box
  border-radius: 7px
  display: flex
  align-items: center
  justify-content: center
  color: #fff
  font-family: $font-family-regular
  font-size: 0.875rem
  margin-left: 10px

.MuiInputBase-sizeSmall
  padding-top: 0px !important
  padding-bottom: 0px !important

.properties-table-actions-btn-small-success
  cursor: pointer
  height: 2rem
  width: 3rem
  background: #ED0874 0% 0% no-repeat padding-box
  border-radius: 7px
  display: flex
  align-items: center
  justify-content: center
  color: #fff
  font-family: $font-family-regular
  font-size: 0.875rem
  margin-left: 10px

.properties-table-general-container
  position: relative
  left: 2rem
  width: calc( 100% - 4rem )
  height: 85%
  overflow-x: auto
  overflow-y: auto

.propeties-table-header-container
  position: relative
  margin-bottom: 1rem
  margin-top: 1rem
  display: flex
  align-items: center
  height: 2.5rem

.checkbox-container
  margin-right: 1rem
  width: 1rem
  height: 1rem
  input
    width: 100%
    height: 100%

.table-header-attributes-container
  display: flex
  border: 1px solid #939598
  border-radius: 5px
  height: 100%
  width: auto
  align-items: center
  background-color: #f5f5f5

.table-header-item
  min-width: 10rem
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  margin-left: 1rem
  margin-right: 1rem
  font-size: 0.875rem
  font-family: $font-family-semibold
  color: #000000

.table-header-item-selectable
  cursor: pointer
  min-width: 10rem
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  margin-left: 1rem
  margin-right: 1rem
  font-size: 0.875rem
  font-family: $font-family-semibold
  color: #000000

.properties-individual-container
  position: relative
  display: flex
  align-items: center
  height: 3.125rem
  margin-bottom: 1rem

.properties-individual-items-container
  display: flex
  position: relative
  border: 1px solid #939598
  height: 100%
  width: auto
  border-radius: 5px
  align-items: center
  background-color: #fafafa

.propeties-individual-title
  min-width: 10rem
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  margin-right: 1rem
  margin-left: 1rem
  font-size: 0.875rem
  font-family: $font-family-regular
  select
    height: 2.063rem
    width: 90%
  input
    height: 2.063rem

.property-new-individual-container
  display: flex
  height: 3.125rem
  align-items: center
  margin-bottom: 1rem

.property-new-creation-container
  display: flex
  position: relative
  border: 1px solid #ED0874
  background: #FFFFFF 0% 0% no-repeat padding-box
  border-radius: 8px
  height: 100%
  width: auto
  align-items: center

.property-creation-individual-title
  min-width: 10rem
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  margin-right: 1rem
  margin-left: 1rem
  font-size: 0.875rem
  font-family: $font-family-regular
  select
    height: 2.063rem
    width: 90%
  input
    height: 2.063rem

.property-creation-save-btn
  cursor: pointer
  position: relative
  width: 5.625rem
  height: 2.125rem
  background: #ED0874 0% 0% no-repeat padding-box
  border-radius: 4px
  display: flex
  align-items: center
  justify-content: center
  color: #fff
  font-size: 0.875rem

.properties-action-container
  position: absolute
  top: 2.5rem
  z-index: 4
  right: 0
  border: 0.5px solid #E0E0E0
  border-radius: 5px
  box-shadow: 0px 3px 6px #00000052
  background: #FFFFFF 0% 0% no-repeat padding-box

.properties-action-options
  cursor: pointer
  height: 3.125rem
  width: 10.188rem
  background-color: #fff
  transition: all 0.3s ease-in-out
  display: flex
  align-items: center
  justify-content: center
  color: rgba(4, 50, 80, 1)
  font-size: 0.875rem
  font-family: $font-family-regular

.properties-action-options:hover
  cursor: pointer
  height: 3.125rem
  width: 10.188rem
  background-color: rgba(0, 179, 197, 0.2)
  transition: all 0.3s ease-in-out

.properties-make-copies-container
  position: absolute
  top: 2.5rem
  z-index: 2
  right: 0
  border: 1px solid #BEBEBE
  box-shadow: 0px 0px 6px #0000004D
  background: #FFFFFF 0% 0% no-repeat padding-box
  border-radius: 10px
  height: 7.063rem
  width: 12.875rem

.properties-close-container
  cursor: pointer
  width: 0.75rem
  height: 0.75rem
  position: relative
  margin-top: 0.75rem
  right: 1.5rem
  left: calc( 100% - 1.5rem )
  img
    max-height: 100%
    min-height: 100%
    max-width: 100%
    min-width: 100%
    object-fit: contain

.properties-copies-title
  position: relative
  left: 1.5rem
  margin-top: 0.5rem
  font-size: 0.875rem
  font-family: $font-family-semibold
  color: #014976
  margin-bottom: 1rem

.properties-copies-input-container
  position: relative
  display: flex
  width: calc( 100% - 3rem )
  left: 1.5rem
  align-items: center

.properties-copies-input
  width: 5rem
  height: 1.938rem
  margin-right: 1rem
  input
    height: 100%
    width: 100%
    border-radius: 4px
    border: 0.5px solid #616161

.propeties-copies-input-btn
  cursor: pointer
  position: relative
  width: 3.875rem
  height: 1.938rem
  background: #ED0874 0% 0% no-repeat padding-box
  border-radius: 7px
  color: #fff
  font-size: 0.75rem
  font-family: $font-family-regular
  display: flex
  align-items: center
  justify-content: center

.properties-edit-attribute-container
  position: absolute
  width: 100%
  height: 100%
  background: rgba(1, 17, 27, 0.4) 0% 0% no-repeat padding-box

.properties-edit-attribute
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%,-50%)
  height: 18rem
  width: 28.5rem
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box
  border: 1px solid #BEBEBE
  border-radius: 10px

.properties-edit-attribute-close
  position: absolute
  width: 0.75rem
  height: 0.75rem
  top: 1rem
  right: 1rem
  cursor: pointer
  img
    max-height: 100%
    min-height: 100%
    min-width: 100%
    max-width: 100%
    object-fit: contain

.properties-edit-attribute-title
  position: relative
  margin-top: 3rem
  left: 2.5rem
  font-size: 1.125rem
  color: #043250
  font-family: $font-family-semibold
  margin-bottom: 1rem

.properties-edit-attribute-text
  position: relative
  left: 2.5rem
  width: calc( 100% - 5rem )
  color: #616161
  font-size: 0.875rem
  font-family: $font-family-regular
  margin-bottom: 1rem

.properties-edit-attribute-setup
  position: relative
  left: 2.5rem
  width: calc( 100% - 5rem )
  display: flex
  align-items: center
  margin-bottom: 2rem

.properties-edit-setup-input
  width: 10rem
  margin-right: 1rem
  select
    width: 100%
    height: 1.875rem

.properties-edit-setup-title
  color: #014976
  margin-bottom: 1rem
  font-size: 0.875rem
  font-family: $font-family-semibold

.properties-edit-btns
  position: relative
  left: 2.5rem
  width: calc( 100% - 5rem )
  display: flex
  align-items: center
  justify-content: center

.properties-edit-attributes-cancel
  cursor: pointer
  position: relative
  background: #E0E0E0 0% 0% no-repeat padding-box
  border-radius: 5px
  width: 4.875rem
  height: 2.125rem
  margin-right: 0.5rem
  margin-left: 0.5rem
  display: flex
  align-items: center
  justify-content: center
  font-size: 0.875rem
  font-family: $font-family-regular

.properties-edit-attributes-apply
  cursor: pointer
  position: relative
  background: #ED0874 0% 0% no-repeat padding-box
  border-radius: 5px
  width: 8.125rem
  height: 2.125rem
  margin-right: 0.5rem
  margin-left: 0.5rem
  display: flex
  align-items: center
  justify-content: center
  font-size: 0.875rem
  font-family: $font-family-regular
  color: #fff

.table-header-item-title
  z-index: 3

.attributes-filter-container
  position: absolute
  background: #FFFFFF 0% 0% no-repeat padding-box
  z-index: 2
  top: 10%
  width: 10rem
  box-shadow: 0px 3px 6px #0000004D
  border: 0.5px solid #BEBEBE
  border-radius: 5px

.title-space-container
  height: 2rem

.properties-individual-option
  display: flex
  margin-bottom: 0.5rem
  margin-top: 0.5rem
  input
    margin-left: 0.5rem
    margin-right: 0.5rem
  div
    font-family: $font-family-regular
    font-size: 0.75rem

