@import ./../../variables.style

.gallery-preview-main
    position: absolute
    width: 90%
    height: auto
    max-height: 70%
    top: 12%
    left: 50%
    transform: translate(-50%, 0%)
    display: grid
    grid-template-columns: repeat(5, 3fr)
    overflow-y: auto

.gallery-preview-main-collapsed
    position: absolute
    width: 90%
    height: auto
    max-height: 70%
    top: 12%
    left: 50%
    transform: translate(-50%, 0%)
    display: grid
    grid-template-columns: repeat(4, 3fr)
    overflow-y: auto

.gallery-image-card
    position: relative
    background: #fff 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px
    width: 80%
    height: 12rem
    margin-right: 1rem
    margin-bottom: 1rem

.gallery-image-container-links
    position: relative
    top: 0%
    width: 100%
    height: 60%
    left: 50%
    transform: translate(-50%,0)
    border-radius: 5px
    img
        position: absolute
        top: 50%
        left: 50%
        max-height: 90%
        min-height: 90%
        max-width: 90%
        min-width: 90%
        object-fit: contain
        transform: translate(-50%,-50%)

.information-container
    position: relative
    width: 100%
    height: 40%
    border-radius: 0px 0px 5px 5px
    background-color: rgba(0,0,0,0.1)

.gallery-image-name
    position: relative
    word-wrap: break-word
    font-family: $font-family-regular
    font-size: 0.75rem
    top: 0.5rem
    margin-bottom: 1rem
    width: 80%
    left: 50%
    transform: translate(-50%,0)
    text-align: center

.fullscreen-image-contaienr
    cursor: pointer
    position: absolute
    top: 5%
    left: 2.5%
    width: 15%
    height: 20%
    border-radius: 3px
    background: rgba(0, 0, 0, 0.63) 0% 0% no-repeat padding-box
    img
        position: absolute
        top: 50%
        left: 50%
        max-height: 70%
        min-height: 70%
        max-width: 70%
        min-width: 70%
        object-fit: contain
        transform: translate(-50%,-50%)

.close-image-contaienr
    cursor: pointer
    position: absolute
    top: 5%
    right: 2.5%
    width: 15%
    height: 20%
    border-radius: 3px
    background: rgba(0, 0, 0, 0.63) 0% 0% no-repeat padding-box
    img
        position: absolute
        top: 50%
        left: 50%
        max-height: 70%
        min-height: 70%
        max-width: 70%
        min-width: 70%
        object-fit: contain
        transform: translate(-50%,-50%)

.image-options
    position: relative
    display: flex
    width: 80%
    left: 50%
    transform: translate(-50%,0)
    align-items: center
    justify-content: center

.fullscreen-option
    width: 4rem
    height: 1.75rem
    font-family: $font-family-regular
    color: #fff
    background-color: #ED0874
    border-radius: 5px
    display: flex
    justify-content: center
    align-items: center
    margin-right: 0.75rem
    cursor: pointer

.edit-gallery
    width: 1.5rem
    height: 1.5rem
    margin-right: 0.75rem
    cursor: pointer

.delete-gallery
    width: 1.5rem
    height: 1.5rem
    cursor: pointer

.edition-modal-gallery
    position: absolute
    top: 15%
    height: 35%
    right: 1%
    width: 22.5%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 8px

.edit-image-title
    position: relative
    top: 12%
    left: 50%
    transform: translate(-50%,0)
    font-family: $font-family-semibold
    color: #043250
    font-size: 1.125rem
    text-align: center
    margin-bottom: 4rem

.edit-image-container
    position: relative
    width: 80%
    height: 40%
    display: flex
    left: 50%
    transform: translate(-50%,0)
    margin-bottom: 2rem

.edit-image-container-preview
    position: relative
    max-width: 50%
    width: 50%
    height: 100%
    background: #F5F5F5 0% 0% no-repeat padding-box
    border: 0.5px solid #616161
    border-radius: 5px
    margin-right: 1rem
    img
        position: absolute
        max-width: 90%
        min-width: 90%
        max-height: 90%
        min-height: 90%
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        object-fit: contain

.info-title-gallery
    font-family: $font-family-regular
    font-size: 1rem
    color: #043250
    margin-bottom: 0.75rem

.info-subtitle-gallery
    font-family: $font-family-regular
    font-size: 0.75rem
    color: #000
    margin-bottom: 0.75rem

.edit-gallery-save
    position: relative
    width: 6rem
    height: 2.25rem
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 5px
    color: #fff
    font-size: 1rem
    font-family: $font-family-regular
    display: flex
    justify-content: center
    align-items: center
    left: 50%
    transform: translate(-50%,0)