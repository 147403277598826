@import ./../../variables.style

.create-builder-container
    position: absolute
    min-width: 300px
    top: 10%
    height: 90%
    width: 18%
    right: 0
    background-color: #fff

.create-builder-header
    position: absolute
    cursor: pointer
    width: 6%
    height: 6%
    top: 0.5rem
    right: 0.5rem

.create-title
    position: absolute
    top: 6%
    left: 50%
    width: 80%
    text-align: center
    transform: translate(-50%,0)
    font-family: $font-family-semibold
    font-size: 1vw
    color: #043250

.create-image-container
    position: absolute
    top: 15%
    left: 5%
    width: 5vw
    height: 5vw
    background: #F5F5F5 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 5px
    img
        position: absolute
        max-width: 80%
        min-width: 80%
        max-height: 80%
        min-height: 80%
        object-fit: contain
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

.caption-logo-builder
    position: absolute
    top: 15%
    left: 40%
    width: 60%
    text-align: left
    font-family: $font-family-regular
    font-size: 0.8vw
    color: #939598
    letter-spacing: 0px

.inputfile
    position: absolute
    top: 23%
    left: 40%
    height: 8%
    opacity: 0
    cursor: pointer

.upload-photo-nuevo-label
    position: absolute
    top: 23%
    left: 40%
    cursor: pointer
    div
        cursor: pointer

.create-btn
    position: absolute
    top: 95%
    left: 50%
    width: 30% 
    height: 5%
    display: flex
    justify-content: center
    align-items: center
    font-size: 0.9vw
    font-family: $font-family-regular
    border-radius: 7px
    background-color: #ED0874
    color: #fff
    transform: translate(-50%,-50%)
    cursor: pointer

.contact-project-container
    position: absolute
    top: 40%
    width: 80%
    left: 50%
    transform: translate(-50%, 0)
    max-height: 50%
    overflow-y: auto

.contact-project-name
    font-family: $font-family-semibold
    font-size: 1.6vh

.add-contact-info
    position: relative
    width: 100%
    height: 3.5vh

.add-contact-info-text
    position: absolute
    width: 60%
    font-family: $font-family-regular
    font-size: 1.5vh
    top: 50%
    transform: translate(0,-50%)

.add-contact-indo-btns
    position: absolute
    width: 30%
    height: 100%
    right: 0
    background: #ED0874 0% 0% no-repeat padding-box
    color: #FFFFFF
    border-radius: 5px
    font-family: $font-family-regular
    display: flex
    justify-content: center
    align-items: center
    font-size: 1.5vh
    cursor: pointer

.contact-creation-modal
    margin-top: 3%
    margin-bottom: 3%

.contact-creation-sales-name
    position: relative
    font-family: $font-family-semibold
    font-size: 1.6vh
    margin-bottom: 3%

.contact-sales-name-input
    height: 4vh
    div
        position: absolute
        font-family: $font-family-regular
        font-size: 1.5vh
    input
        position: absolute
        right: 0
        max-width: 60%
        width: 60%

.contact-creation-number-name
    position: relative
    font-family: $font-family-semibold
    font-size: 1.6vh
    margin-bottom: 3%

.contact-numbers-number-input
    height: 4vh
    display: flex
    justify-content: space-between
    align-items: center
    div
        font-family: $font-family-regular
        font-size: 1.5vh
        width: 70%

.prefix
    height: 50%
    width: 20%
    max-width: 10%

.number
    height: 50%
    width: 60%
    margin-left: 5%

.save-sales-btn
    position: relative
    width: 30%
    height: 3.5vh
    cursor: pointer
    right: 0
    background: #ED0874 0% 0% no-repeat padding-box
    color: #FFFFFF
    font-size: 1.5vh
    font-family: $font-family-regular
    display: flex
    justify-content: center
    align-items: center
    border-radius: 5px

.save-sales-btn-deactivated
    position: relative
    width: 30%
    height: 3.5vh
    right: 0
    background: #888 0% 0% no-repeat padding-box
    color: #FFFFFF
    font-size: 1.5vh
    font-family: $font-family-regular
    display: flex
    justify-content: center
    align-items: center
    border-radius: 5px

.info-contar-container-main
    overflow-x: hidden
    margin-top: 2%
    margin-bottom: 3%

.info-container-container-main-name
    font-family: $font-family-semibold
    font-size: 1.5vh

.info-container-container-name
    font-size: 1.5vh
    font-family: $font-family-regular
    margin-bottom: 3%
    margin-top: 2%
    max-width: 100%
    word-break: break-all