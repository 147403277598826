@import ./../../variables.style

.pois-category-icons-container
    position: relative
    top: 5%
    left: 50%
    transform: translate(-50%,0)
    width: 90%
    height: auto
    min-height: 20%
    margin-bottom: 1rem
    display: flex

.pois-category-icon-container
    position: relative
    width: 4.5vw
    height: 4.5vw
    margin-right: 5%
    background: #BEBEBE 0% 0% no-repeat padding-box
    border: 1px solid #707070
    border-radius: 5px
    img
        max-width: 80%
        min-width: 80%
        max-height: 80%
        min-height: 80%
        object-fit: contain
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

.pois-category-icon-text-container
    position: relative
    width: 75%

.pois-category-icon-title
    color: #000
    font-family: $font-family-semibold
    font-size: .875rem
    margin-bottom: 2%

.pois-category-icon-text
    color: #939598
    font-family: $font-family-regular
    font-size: .75rem
    margin-bottom: 2%