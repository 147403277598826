@import ./../../variables.style

.gallery-cards-container
    position: absolute
    width: 90%
    height: auto
    top: 15%
    left: 50%
    transform: translate(-50%,0%)
    display: grid
    grid-template-columns: repeat(7, 2fr)

.individual-gallery-card
    position: relative
    cursor: pointer
    margin: 1vh
    height: 13vh
    background: #F5F5F5 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px
    img
        position: absolute
        width: 40%
        top: 35%
        left: 50%
        transform: translate(-50%,-50%)

.individual-gallery-name
    position: absolute
    word-wrap: break-word
    top: 65%
    left: 50%
    transform: translate(-50%,0)
    text-align: center
    font-family: $font-family-regular
    font-size: 0.8vw

.gallery-image-container
    position: relative
    top: 2%
    width: 90%
    height: 60%
    left: 50%
    transform: translate(-50%,0)
    img
        max-height: 100%
        min-height: 100%
        max-width: 100%
        min-width: 100%
        object-fit: contain