@import ./../../variables.style

.princing-quotation-main-container
    position: absolute
    top: 12%
    right: 1%
    width: 35%
    height: 86%
    background-color: #FFFFFF
    border: 1px solid #E0E0E0
    border-radius: 10px
    overflow-y: auto
    overflow-x: hidden

.pricing-quotation-main-title
    position: absolute
    top: 1% 
    left: 3%
    color: #043250
    font-size: 1.125rem
    font-family: $font-family-semibold


.pricing-table-container
    position: absolute
    top: 16%
    width: 95%
    left: 50%
    transform: translate(-50%,0)
    overflow-y: auto
    overflow-x: hidden

.pricing-btn-filter-container
    position: absolute
    top: 10% 
    left: 3%
    height: 3%
    width: 80%
    display: flex
    align-items: center
    div
        //cursor: pointer
        //height: 100%
        //margin-right: 2%
        //padding: 2%
        //color: #fff
        font-size: 0.8vw
        font-family: $font-family-regular
        //background-color: #ED0874
        //display: flex
        justify-content: center
        align-items: center
        border-radius: 10px
        //border-color: #ED0874