* {
  margin: 0;
  padding: 0;
  box-sizing: 0;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

#root {
  width: 100%;
  height: 100%;
}

input {
  font-family: "Montserrat Regular";
}

.fade-out {
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.fade-in {
  pointer-events: all;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

@font-face {
  font-family: "Montserrat Regular";
  src: url(./fonts/Montserrat-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat Semibold";
  src: url(./fonts/Montserrat-SemiBold.ttf) format("truetype");
}
.login-screen {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

.login-background-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.logos-container {
  position: absolute;
  top: 15%;
  left: 50%;
  width: 70%;
  height: 10%;
  transform: translate(-50%, -50%);
}

.logo-umbra-login {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(0, -50%);
}

.logo-cloud-login {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translate(0, -50%);
}

.login-logo-divisor {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.2%;
  height: 60%;
  transform: translate(-50%, -50%);
  background-color: #000;
}

.form-login {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}

.error-code-login {
  position: relative;
  margin-top: 1rem;
  font-size: 1rem;
  font-family: "Montserrat Semibold";
  color: rgb(237, 8, 116);
}

.MFaQR-container {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.dashboard-background {
  position: absolute;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.builders-container {
  position: absolute;
  width: 77%;
  min-width: "500px";
  height: 87%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  top: 57%;
  left: 50%;
  border-radius: 13px;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
}

.builders-container-opened {
  position: absolute;
  width: 77%;
  min-width: "500px";
  height: 87%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  top: 57%;
  left: 41%;
  border-radius: 13px;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
}

.delete-screen-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.delete-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30%;
  height: 40%;
  min-width: 500px;
  background-color: #fff;
  transform: translate(-50%, -50%);
  border-radius: 7px;
}

.delete-icon {
  position: absolute;
  height: 20%;
  width: 20%;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.delete-title {
  position: absolute;
  top: 47.5%;
  left: 50%;
  font-family: "Montserrat Semibold";
  font-size: 1.6rem;
  transform: translate(-50%, 0);
}

.delete-subtitle {
  position: absolute;
  top: 60%;
  left: 50%;
  text-align: center;
  width: 80%;
  font-family: "Montserrat Regular";
  font-size: 1.2rem;
  transform: translate(-50%, 0);
}

.accept-btn {
  position: absolute;
  bottom: 10%;
  left: 20%;
  background-color: #ED0874;
  width: 25%;
  height: 10%;
  cursor: pointer;
  border-radius: 7px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9vw;
  font-family: "Montserrat Regular";
}

.cancel-btn {
  position: absolute;
  bottom: 10%;
  right: 20%;
  background-color: rgb(221, 51, 51);
  width: 25%;
  height: 10%;
  cursor: pointer;
  border-radius: 7px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9vw;
  font-family: "Montserrat Regular";
}

.f-modal-alert {
  background-color: #fff;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 20px;
  padding: 50px;
}

.f-modal-icon {
  border-radius: 50%;
  border: 4px solid gray;
  box-sizing: content-box;
  height: 80px;
  margin: 20px auto;
  padding: 0;
  position: relative;
  width: 80px;
}
.f-modal-icon.f-modal-success, .f-modal-icon.f-modal-error {
  border-color: #A5DC86;
}
.f-modal-icon.f-modal-success:after, .f-modal-icon.f-modal-success:before, .f-modal-icon.f-modal-error:after, .f-modal-icon.f-modal-error:before {
  background: #fff;
  content: "";
  height: 120px;
  position: absolute;
  transform: rotate(45deg);
  width: 60px;
}
.f-modal-icon.f-modal-success:before, .f-modal-icon.f-modal-error:before {
  border-radius: 120px 0 0 120px;
  left: -33px;
  top: -7px;
  transform-origin: 60px 60px;
  transform: rotate(-45deg);
}
.f-modal-icon.f-modal-success:after, .f-modal-icon.f-modal-error:after {
  border-radius: 0 120px 120px 0;
  left: 30px;
  top: -11px;
  transform-origin: 0 60px;
  transform: rotate(-45deg);
}
.f-modal-icon.f-modal-success .f-modal-placeholder, .f-modal-icon.f-modal-error .f-modal-placeholder {
  border-radius: 50%;
  border: 4px solid rgba(165, 220, 134, 0.2);
  box-sizing: content-box;
  height: 80px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 80px;
  z-index: 2;
}
.f-modal-icon.f-modal-success .f-modal-fix, .f-modal-icon.f-modal-error .f-modal-fix {
  background-color: #fff;
  height: 90px;
  left: 28px;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 5px;
  z-index: 1;
}
.f-modal-icon.f-modal-success .f-modal-line, .f-modal-icon.f-modal-error .f-modal-line {
  background-color: #A5DC86;
  border-radius: 2px;
  display: block;
  height: 5px;
  position: absolute;
  z-index: 2;
}
.f-modal-icon.f-modal-success .f-modal-line.f-modal-tip, .f-modal-icon.f-modal-error .f-modal-line.f-modal-tip {
  left: 14px;
  top: 46px;
  transform: rotate(45deg);
  width: 25px;
}
.f-modal-icon.f-modal-success .f-modal-line.f-modal-long, .f-modal-icon.f-modal-error .f-modal-line.f-modal-long {
  right: 8px;
  top: 38px;
  transform: rotate(-45deg);
  width: 47px;
}
.f-modal-icon.f-modal-error {
  border-color: #F27474;
}
.f-modal-icon.f-modal-error .f-modal-x-mark {
  display: block;
  position: relative;
  z-index: 2;
}
.f-modal-icon.f-modal-error .f-modal-placeholder {
  border: 4px solid rgba(200, 0, 0, 0.2);
}
.f-modal-icon.f-modal-error .f-modal-line {
  background-color: #F27474;
  top: 37px;
  width: 47px;
}
.f-modal-icon.f-modal-error .f-modal-line.f-modal-left {
  left: 17px;
  transform: rotate(45deg);
}
.f-modal-icon.f-modal-error .f-modal-line.f-modal-right {
  right: 16px;
  transform: rotate(-45deg);
}
.f-modal-icon.f-modal-warning {
  border-color: #F8BB86;
}
.f-modal-icon.f-modal-warning:before {
  animation: pulseWarning 2s linear infinite;
  background-color: #fff;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
}
.f-modal-icon.f-modal-warning:after {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.f-modal-icon.f-modal-warning .f-modal-body {
  background-color: #F8BB86;
  border-radius: 2px;
  height: 47px;
  left: 50%;
  margin-left: -2px;
  position: absolute;
  top: 10px;
  width: 5px;
  z-index: 2;
}
.f-modal-icon.f-modal-warning .f-modal-dot {
  background-color: #F8BB86;
  border-radius: 50%;
  bottom: 10px;
  height: 7px;
  left: 50%;
  margin-left: -3px;
  position: absolute;
  width: 7px;
  z-index: 2;
}
.f-modal-icon + .f-modal-icon {
  margin-top: 50px;
}

.animateSuccessTip {
  animation: animateSuccessTip 0.75s;
}

.animateSuccessLong {
  animation: animateSuccessLong 0.75s;
}

.f-modal-icon.f-modal-success.animate:after {
  animation: rotatePlaceholder 4.25s ease-in;
}

.f-modal-icon.f-modal-error.animate:after {
  animation: rotatePlaceholder 4.25s ease-in;
}

.animateErrorIcon {
  animation: animateErrorIcon 0.5s;
}

.animateXLeft {
  animation: animateXLeft 0.75s;
}

.animateXRight {
  animation: animateXRight 0.75s;
}

.scaleWarning {
  animation: scaleWarning 0.75s infinite alternate;
}

.pulseWarningIns {
  animation: pulseWarningIns 0.75s infinite alternate;
}

@keyframes animateSuccessTip {
  0%, 54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes animateSuccessLong {
  0%, 65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes rotatePlaceholder {
  0%, 5% {
    transform: rotate(-45deg);
  }
  100%, 12% {
    transform: rotate(-405deg);
  }
}
@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes animateXLeft {
  0%, 65% {
    left: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    left: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    left: 17px;
    top: 37px;
    width: 47px;
  }
}
@keyframes animateXRight {
  0%, 65% {
    right: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    right: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    right: 16px;
    top: 37px;
    width: 47px;
  }
}
@keyframes scaleWarning {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulseWarning {
  0% {
    background-color: #fff;
    transform: scale(1);
    opacity: 0.5;
  }
  30% {
    background-color: #fff;
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    background-color: #F8BB86;
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes pulseWarningIns {
  0% {
    background-color: #F8D486;
  }
  100% {
    background-color: #F8BB86;
  }
}
.nav-bar-container {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  z-index: 3;
}

.header-builder-container {
  position: absolute;
  top: 3%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 90%;
  height: 8%;
}

.builder-title {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "Montserrat Semibold";
  color: #043250;
  font-size: 2vw;
}

.new-builders-btn {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  background-color: #ED0874;
  width: 15%;
  min-width: 200px;
  height: 65%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat Regular";
  border-radius: 7px;
  font-size: 0.9vw;
}

.builders-card-grid {
  display: grid;
  position: absolute;
  top: 20%;
  width: 95%;
  left: 50%;
  height: auto;
  grid-template-columns: repeat(5, 2fr);
  transform: translate(-50%, 0);
}

.builders-cards {
  position: relative;
  width: 90%;
  min-width: 50px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 10%;
  height: 30vh;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #BEBEBE;
  background: #F5F5F5 0% 0% no-repeat padding-box;
}

.buiders-image-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 52%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px 10px 0px 0px;
}
.buiders-image-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 80%;
  max-height: 80%;
  min-width: 80%;
  max-width: 80%;
  object-fit: contain;
}

.builders-info-container {
  position: absolute;
  top: 52%;
  height: 48%;
  width: 100%;
}

.builders-name {
  position: relative;
  top: 5%;
  left: 50%;
  width: 80%;
  text-align: center;
  font-family: "Montserrat Semibold";
  transform: translate(-50%, 0);
  font-size: 0.9vw;
}

.builders-projects {
  position: relative;
  left: 50%;
  top: 7.5%;
  text-align: center;
  font-family: "Montserrat Regular";
  transform: translate(-50%, 0);
  font-size: 0.8vw;
}

.builders-options-container {
  position: absolute;
  top: 60%;
  left: 10%;
  display: flex;
}

.builders-divider {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 1.5px;
  height: 30%;
  background-color: #BEBEBE;
}

.builders-see-project {
  cursor: pointer;
  position: absolute;
  top: 60%;
  right: 10%;
  width: 30%;
  height: 30%;
  background-color: #ED0874;
  color: #fff;
  font-family: "Montserrat Semibold";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9vw;
  border-radius: 7px;
}

.create-builder-container {
  position: absolute;
  min-width: 300px;
  top: 10%;
  height: 90%;
  width: 18%;
  right: 0;
  background-color: #fff;
}

.create-builder-header {
  position: absolute;
  cursor: pointer;
  width: 6%;
  height: 6%;
  top: 0.5rem;
  right: 0.5rem;
}

.create-title {
  position: absolute;
  top: 6%;
  left: 50%;
  width: 80%;
  text-align: center;
  transform: translate(-50%, 0);
  font-family: "Montserrat Semibold";
  font-size: 1vw;
  color: #043250;
}

.create-image-container {
  position: absolute;
  top: 15%;
  left: 5%;
  width: 5vw;
  height: 5vw;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 5px;
}
.create-image-container img {
  position: absolute;
  max-width: 80%;
  min-width: 80%;
  max-height: 80%;
  min-height: 80%;
  object-fit: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.caption-logo-builder {
  position: absolute;
  top: 15%;
  left: 40%;
  width: 60%;
  text-align: left;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  color: #939598;
  letter-spacing: 0px;
}

.inputfile {
  position: absolute;
  top: 23%;
  left: 40%;
  height: 8%;
  opacity: 0;
  cursor: pointer;
}

.upload-photo-nuevo-label {
  position: absolute;
  top: 23%;
  left: 40%;
  cursor: pointer;
}
.upload-photo-nuevo-label div {
  cursor: pointer;
}

.create-btn {
  position: absolute;
  top: 95%;
  left: 50%;
  width: 30%;
  height: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9vw;
  font-family: "Montserrat Regular";
  border-radius: 7px;
  background-color: #ED0874;
  color: #fff;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.contact-project-container {
  position: absolute;
  top: 40%;
  width: 80%;
  left: 50%;
  transform: translate(-50%, 0);
  max-height: 50%;
  overflow-y: auto;
}

.contact-project-name {
  font-family: "Montserrat Semibold";
  font-size: 1.6vh;
}

.add-contact-info {
  position: relative;
  width: 100%;
  height: 3.5vh;
}

.add-contact-info-text {
  position: absolute;
  width: 60%;
  font-family: "Montserrat Regular";
  font-size: 1.5vh;
  top: 50%;
  transform: translate(0, -50%);
}

.add-contact-indo-btns {
  position: absolute;
  width: 30%;
  height: 100%;
  right: 0;
  background: #ED0874 0% 0% no-repeat padding-box;
  color: #FFFFFF;
  border-radius: 5px;
  font-family: "Montserrat Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5vh;
  cursor: pointer;
}

.contact-creation-modal {
  margin-top: 3%;
  margin-bottom: 3%;
}

.contact-creation-sales-name {
  position: relative;
  font-family: "Montserrat Semibold";
  font-size: 1.6vh;
  margin-bottom: 3%;
}

.contact-sales-name-input {
  height: 4vh;
}
.contact-sales-name-input div {
  position: absolute;
  font-family: "Montserrat Regular";
  font-size: 1.5vh;
}
.contact-sales-name-input input {
  position: absolute;
  right: 0;
  max-width: 60%;
  width: 60%;
}

.contact-creation-number-name {
  position: relative;
  font-family: "Montserrat Semibold";
  font-size: 1.6vh;
  margin-bottom: 3%;
}

.contact-numbers-number-input {
  height: 4vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contact-numbers-number-input div {
  font-family: "Montserrat Regular";
  font-size: 1.5vh;
  width: 70%;
}

.prefix {
  height: 50%;
  width: 20%;
  max-width: 10%;
}

.number {
  height: 50%;
  width: 60%;
  margin-left: 5%;
}

.save-sales-btn {
  position: relative;
  width: 30%;
  height: 3.5vh;
  cursor: pointer;
  right: 0;
  background: #ED0874 0% 0% no-repeat padding-box;
  color: #FFFFFF;
  font-size: 1.5vh;
  font-family: "Montserrat Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.save-sales-btn-deactivated {
  position: relative;
  width: 30%;
  height: 3.5vh;
  right: 0;
  background: #888 0% 0% no-repeat padding-box;
  color: #FFFFFF;
  font-size: 1.5vh;
  font-family: "Montserrat Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.info-contar-container-main {
  overflow-x: hidden;
  margin-top: 2%;
  margin-bottom: 3%;
}

.info-container-container-main-name {
  font-family: "Montserrat Semibold";
  font-size: 1.5vh;
}

.info-container-container-name {
  font-size: 1.5vh;
  font-family: "Montserrat Regular";
  margin-bottom: 3%;
  margin-top: 2%;
  max-width: 100%;
  word-break: break-all;
}

.sidebar-main {
  position: absolute;
  left: 0;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  top: 10%;
  height: 90%;
  width: 18vw;
  box-shadow: 3px 0px 20px rgba(0, 0, 0, 0.1607843137);
  overflow-y: auto;
}

.sidebar-divisor {
  position: relative;
  top: 0%;
  height: 0.1%;
  width: 100%;
  background-color: #E2E2E2;
}

.sideba-dev-text {
  position: relative;
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 90%;
  left: 50%;
  transform: translate(-50%, 0);
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  color: #616161;
}

.update-template-masterplan-cache {
  position: relative;
  width: 90%;
  left: 5%;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  color: #000;
  height: 3.75rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  flex-direction: column;
  margin-bottom: 3rem !important;
}
.update-template-masterplan-cache strong {
  margin-top: 0.5rem;
}

.update-template-masterplan {
  position: relative;
  width: 90%;
  left: 5%;
  font-family: "Montserrat Semibold";
  font-size: 0.75rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  color: #000;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.update-template-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(200, 200, 200, 0.8);
}

.update-info-container {
  position: absolute;
  width: 60rem;
  height: 37.5rem;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
}
.update-info-container img {
  position: relative;
  margin-top: 2rem;
  width: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: 2rem;
}

.update-info-container-title {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 1.75rem;
  text-align: center;
  width: 80%;
  font-family: "Montserrat Semibold";
  margin-bottom: 2rem;
}

.update-info-container-text {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 1.25rem;
  text-align: center;
  width: 80%;
  font-family: "Montserrat Regular";
  margin-bottom: 3rem;
}

.update-info-container-btns {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  left: 50%;
  transform: translate(-50%, 0);
}

.update-accept {
  cursor: pointer;
  position: relative;
  background-color: #ED0874;
  width: 10rem;
  height: 2.5rem;
  color: #fff;
  font-size: 1rem;
  font-family: "Montserrat Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.update-cancel {
  cursor: pointer;
  position: relative;
  background-color: rgb(221, 51, 51);
  width: 10rem;
  height: 2.5rem;
  color: #fff;
  font-size: 1rem;
  font-family: "Montserrat Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.progress-bar-parent {
  position: absolute;
  width: 20rem;
  height: 0.5rem;
  bottom: 42.5%;
  background-color: #fff;
  border-radius: 10px;
}

.progress-bar-child {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #ED0874;
  border-radius: 10px;
}

.progress-number {
  position: absolute;
  bottom: 38%;
  font-family: "Montserrat Semibold";
  color: #fff;
  font-size: 1rem;
}

.sidebar-header {
  position: relative;
  width: 100%;
  height: 11%;
}

.sidebar-header-title {
  position: absolute;
  top: 40%;
  left: 5%;
  transform: translate(0%, -50%);
  font-family: "Montserrat Semibold";
  font-size: 1vw;
  color: #043250;
}

.sidebar-header-subtitle {
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translate(0%, 50%);
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  color: #616161;
}

.sidebar-collapse-btn {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: 5%;
  height: 20%;
  right: 10%;
  transform: translate(0, -50%);
}
.sidebar-collapse-btn img {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: contain;
  transform: translate(-50%, -50%) rotate(90deg);
}

.projects-data-container {
  position: relative;
  top: 0;
  height: auto;
  width: 100%;
}

.projects-data-title {
  position: relative;
  left: 5%;
  font-size: 1.2rem;
  font-family: "Montserrat Regular";
  color: #8F8F8F;
}

.sidebar-projects-container {
  position: relative;
  width: 90%;
  left: 5%;
}

.project-data-btn-container {
  position: relative;
  margin-top: 3%;
  margin-bottom: 3%;
  height: auto;
  min-height: 4vh;
  transition: all 0.3s ease-in-out;
}
.project-data-btn-container a {
  height: 4vh;
  text-decoration: none;
}

.project-data-btn-container:hover {
  background-color: #EEEEEE;
  transition: all 0.3s ease-in-out;
}

.project-data-main-title {
  cursor: pointer;
  max-height: 4vh;
  height: 4vh;
  position: relative;
  color: #8F8F8F;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  left: 5%;
  transition: all 0.3s ease-in-out;
}

.title-submenu {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  transition: all 0.3s ease-in-out;
}

.deselected-arrow {
  position: absolute;
  width: 7%;
  top: 50%;
  right: 10%;
  transform: translate(0, -50%);
  transition: 0.1s all ease-in-out;
}

.selected-arrow {
  position: absolute;
  width: 7%;
  top: 50%;
  right: 10%;
  transform: translate(0, -50%) rotate(90deg);
  transition: 0.1s all ease-in-out;
}
.selected-arrow path {
  stroke: #ED0874;
}

.project-data-main-title-no-sub-menu {
  position: relative;
  color: #8F8F8F;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  height: 4vh;
  display: flex;
  left: 5%;
  align-items: center;
}

.sub-menu-container {
  position: relative;
  height: auto;
  width: 90%;
  transform: translate(-20%);
}

.submenu-btn {
  cursor: pointer;
  position: relative;
  left: 0%;
  text-decoration: none;
  width: 100%;
  margin-bottom: 5%;
  margin-top: 5%;
}
.submenu-btn div {
  margin-bottom: 2%;
  margin-top: 2%;
  position: relative;
  color: #8F8F8F;
  left: 0%;
  font-size: 0.8vw;
  display: flex;
  align-items: center;
  height: 4vh;
  text-align: left;
  font-family: "Montserrat Regular";
  border-radius: 3px 30px 30px 3px;
  padding-left: 40%;
  transition: all 0.3s ease-in-out;
}

.submenu-btn:hover {
  position: relative;
  left: 0%;
  width: 100%;
}
.submenu-btn:hover div {
  color: #ED0874 !important;
  border-radius: 3px 30px 30px 3px;
  background-color: #FFCCE4;
  transition: all 0.3s ease-in-out;
}

.sidebar-selected {
  font-family: "Montserrat Semibold";
  color: #ED0874;
  transition: all 0.3s ease-in-out;
}

.submenu-selected {
  color: #ED0874 !important;
  border-radius: 3px 30px 30px 3px;
  background-color: #FFCCE4;
  transition: all 0.3s ease-in-out;
}

.general-info-container {
  position: absolute;
  top: 55%;
  left: 50%;
  width: 100%;
  height: 80%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  overflow-x: hidden;
}

.logo-name-container {
  position: relative;
  margin-top: 2rem;
  width: 90%;
  height: 20%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
}

.logo-name-container-2 {
  position: relative;
  margin-top: 2rem;
  width: 90%;
  height: 18%;
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: 2rem;
}

.logo-container {
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
}

.frame-intro-container {
  position: relative;
  right: 0;
  width: 50%;
  height: 100%;
  display: flex;
}

.logo-image {
  position: relative;
  width: 7vw;
  height: 7vw;
  border: 1px #939598 solid;
  border-radius: 7px;
}
.logo-image img {
  position: absolute;
  max-height: 80%;
  min-height: 80%;
  max-width: 80%;
  min-width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-information {
  position: relative;
  width: 60%;
  left: 10%;
}

.logo-name-title {
  font-family: "Montserrat Semibold";
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

.logo-format-subtitle {
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  margin-bottom: 1rem;
  color: #939598;
}

.logo-format-dubtitle {
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  margin-bottom: 1rem;
  color: #939598;
}

.name-container {
  position: relative;
  left: 0;
  display: flex;
}
.name-container input {
  position: relative;
  top: 25%;
  height: 2.5rem;
  width: 41.688rem;
}

.legal-text-container {
  position: relative;
  top: 44%;
  width: 90%;
  left: 50%;
  height: 30%;
  transform: translate(-50%, 0);
  margin-bottom: 2rem;
}

.color-palette-container-main {
  position: relative;
  width: 62%;
  left: 55%;
  height: 80%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
  transform: translate(-50%, 0);
}

.url-name-general-information {
  position: relative;
  font-family: "Montserrat Semibold";
  font-size: 0.9vw;
  margin-bottom: 1rem;
}

.general-info-copy-url {
  cursor: pointer;
  position: relative;
  top: 25%;
  width: 8.125rem;
  height: 2.5rem;
  left: 5%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #ED0874;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.general-info-copy-text {
  right: 5%;
  font-size: 0.875rem;
  font-family: "Montserrat Regular";
}

.Mui-checked {
  color: #ED0874 !important;
}

.Mui-checked + .MuiSwitch-track {
  background-color: #ED0874 !important;
}

.setup-general-container {
  position: relative;
}

.general-info-main-container {
  position: relative;
  width: 62%;
  left: 55%;
  height: auto;
  min-height: 70%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
  transform: translate(-50%, 0);
  margin-bottom: 2rem;
}

.additional-logos-container {
  margin-top: 2rem;
  position: relative;
  height: auto;
  width: 90%;
  left: 50%;
  transform: translate(-50%, 0);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 0.5px solid #616161;
  border-radius: 5px;
  height: auto;
  min-height: 3.563rem;
}

.additional-logo-name-icon {
  cursor: pointer;
  position: relative;
  display: flex;
  width: 100%;
  min-height: 3.563rem;
  align-items: center;
}
.additional-logo-name-icon img {
  min-height: 1rem;
  max-height: 1rem;
  min-width: 1rem;
  max-width: 1rem;
}

.aditional-logos-name {
  margin-left: 1.5rem;
  font-size: 0.875rem;
  font-family: "Montserrat Semibold";
  margin-right: 1.5rem;
}

.aditioanl-logo-text {
  position: relative;
  margin-left: 1.5rem;
  width: calc(100% - 3rem);
  font-size: 0.875rem;
  font-family: "Montserrat Regular";
  color: #616161;
  margin-bottom: 1rem;
}

.additional-logo-btns {
  cursor: pointer;
  position: relative;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  height: 1.875rem;
  width: 11.188rem;
  font-size: 0.75rem;
  font-family: "Montserrat Regular";
  align-items: center;
  justify-content: center;
  color: #fff;
}

.aditional-logo-individual-general-container {
  margin-bottom: 1rem;
  width: calc(100% - 3rem);
  margin-left: 1.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.aditional-logo-individual-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.aditional-logo-icon {
  position: relative;
  width: 5.625rem;
  height: 5.625rem;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 0.5px solid #707070;
  border-radius: 5px;
  margin-right: 1rem;
}
.aditional-logo-icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 60%;
  min-height: 60%;
  min-width: 60%;
  max-width: 60%;
  transform: translate(-50%, -50%);
}

.aditional-logo-title-text {
  height: 5.625rem;
}

.aditional-logo-individual-title {
  color: #000;
  font-family: "Montserrat Semibold";
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.aditional-logo-individual-text {
  color: #616161;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.aditional-logo-individual-input-container {
  display: flex;
}
.aditional-logo-individual-input-container input {
  position: absolute;
  opacity: 0;
}

.aditional-logo-individual-upload {
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 0.5px solid #616161;
  border-radius: 5px;
  width: 5.875rem;
  height: 1.563rem;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #616161;
  font-family: "Montserrat Regular";
}

.legal-text-container #outlined-basic-label {
  display: none !important;
}

.legal-text-title {
  margin-bottom: 1rem;
  font-family: "Montserrat Semibold";
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 0rem 0rem 0rem 0rem !important;
}
.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root textarea {
  padding: 1rem;
}

.header-general-info-container {
  position: relative;
  margin-top: 3%;
  width: 100%;
  height: 2rem;
}

.general-title {
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translate(0%, -50%);
  font-size: 1.125rem;
  font-family: "Montserrat Semibold";
  color: #043250;
}

.update-btn {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translate(0%, -50%);
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 7px;
  color: #fff;
  height: 100%;
  width: 10%;
  font-family: "Montserrat Regular";
  font-size: 0.9vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modules-container {
  position: relative;
  width: 62%;
  left: 55%;
  transform: translate(-50%, 0);
  overflow-y: auto;
  min-height: 2rem;
  height: auto;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
  margin-bottom: 2rem;
}

.modules-name {
  position: relative;
  top: 5%;
  left: 50%;
  font-family: "Montserrat Semibold";
  color: #043250;
  font-size: 1.1rem;
  transform: translate(-50%, 0%);
  text-align: center;
  margin-top: 1rem;
}

.modules-card-container {
  position: relative;
  top: 10%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, 0%);
  display: grid;
  margin-top: 2rem;
  margin-bottom: 2rem;
  grid-template-columns: repeat(2, 2fr);
  font-size: 0.875rem;
}

.modules-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Montserrat Regular";
  border-radius: 5px;
  border: 0.1rem solid;
  margin: 0.5rem;
  padding-left: 1rem;
}

.color-palette-title {
  position: absolute;
  top: 5%;
  left: 50%;
  font-size: 1.125rem;
  font-family: "Montserrat Semibold";
  color: #043250;
  transform: translate(-50%, 0);
}

.color-palette-inputs-container {
  z-index: 2;
  position: absolute;
  width: 75%;
  top: 10%;
  left: 50%;
  height: 40%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-palette-individual-container {
  position: relative;
  width: 28%;
  margin-left: 1%;
  margin-right: 1%;
  height: 100%;
}

.color-palette-individual-title {
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translate(-50%, 0);
  width: 100%;
  text-align: center;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
}

.color-palette-preview {
  position: absolute;
  top: 20%;
  width: 60%;
  height: 40%;
  border: 2px solid #BEBEBE;
  left: 50%;
  transform: translate(-50%, 0);
}

.color-palette-input {
  position: absolute;
  top: 70%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, 0);
}

.color-palette-previews-container-main {
  position: absolute;
  top: 50%;
  width: 90%;
  height: 45%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.color-palette-previews-title {
  position: absolute;
  left: 50%;
  font-family: "Montserrat Semibold";
  font-size: 0.8vw;
  transform: translate(-50%, 0);
}

.color-palette-previews-container {
  position: absolute;
  top: 10%;
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-palette-individual-preview {
  position: relative;
  width: 12.5%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 100%;
}

.color-palette-individual-img-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}
.color-palette-individual-img-container svg {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 30%;
}

.color-palette-individual-inner-text {
  position: absolute;
  bottom: 10%;
  left: 50%;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  transform: translate(-50%, 0);
}

.color-palette-individual-text {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 0.8vw;
  font-family: "Montserrat Semibold";
}

.color-palette-outline-container {
  position: relative;
  width: 12.5%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 100%;
}

.palette-individual-text {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 0.8vw;
  font-family: "Montserrat Semibold";
}

.color-palette-text-states-container {
  position: relative;
  width: 15%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 100%;
}

.color-palette-bilboards-container {
  position: relative;
  width: 18%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 100%;
}

.color-palette-outline-btn {
  position: relative;
  top: 40%;
  height: 15%;
  width: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 5%;
  margin-bottom: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
}

.color-palette-amenities-billboard {
  position: relative;
  width: 50%;
}
.color-palette-amenities-billboard svg {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.color-palette-text-individual {
  position: relative;
  display: flex;
  align-items: center;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border-radius: 5px 0px 0px 5px;
  top: 40%;
  height: 15%;
  width: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 5%;
  margin-bottom: 5%;
}
.color-palette-text-individual img {
  height: 80%;
  margin-right: 5%;
  margin-left: 5%;
}
.color-palette-text-individual div {
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
}

.color-palette-lotes-billboard {
  position: relative;
  width: 50%;
}
.color-palette-lotes-billboard svg {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.svg-container {
  position: absolute;
  top: 50%;
  width: 90%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(0, -50%);
}

.colorPicker-palette {
  position: absolute;
  top: 20%;
  left: 80%;
  z-index: 2;
}

.attributes-container {
  position: absolute;
  top: 55%;
  left: 58%;
  height: 80%;
  width: 78%;
  transform: translate(-50%, -50%);
}

.atributtes-table {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 75%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 8px;
}

.attributes-settings {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 23%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 8px;
}

.ReactTags__tags {
  margin-top: 10%;
}

.ReactTags__tagInput {
  width: 100%;
  height: 2rem;
}

.ReactTags__tagInputField {
  width: 100%;
  height: 100%;
}

.ReactTags__selected {
  width: 100%;
  margin-top: 10%;
  margin-bottom: 10%;
  height: auto;
  max-height: 10vh;
  border: solid 1px #cacaca;
  border-radius: 4px;
  background: #fafafa;
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  overflow-y: auto;
}

.tag-wrapper {
  margin: 5px;
  border-radius: 4px;
  padding: 0.3rem;
  width: 80%;
  background-color: #cacaca;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  font-family: "Montserrat Regular";
}

.ReactTags__remove {
  border: none;
  border-radius: 4px;
  margin-left: 3px;
  width: 20%;
}

.attribute-selected-type-table {
  position: relative;
  width: 100%;
  left: 0%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  border-radius: 5px;
  margin-bottom: 2%;
}

.attribute-select-name {
  margin-left: 2%;
  width: 6rem;
  font-size: 0.875rem;
}
.attribute-select-name div {
  position: relative;
  margin-left: 0.5rem;
  width: 1rem;
  height: 1rem;
}
.attribute-select-name div svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.attribute-selecte-save-btn {
  cursor: pointer;
  margin-right: 2%;
  background-color: #ED0874;
  width: 10%;
  height: 3vh;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.875rem;
}

.attribute-select-type {
  display: flex;
  width: 40%;
  justify-content: center;
}

.select-type-name {
  position: relative;
  margin-right: 2%;
  font-size: 0.875rem;
}

.color-attribute-container {
  position: relative;
  width: 15vw;
  display: flex;
  align-items: center;
}

.color-palette-preview-attributes {
  cursor: pointer;
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border: 1px solid #000;
}

.layout-color-container {
  position: relative;
  display: flex;
  align-items: center;
}

.container {
  position: relative;
  width: 2vw;
}

.warning-attributes {
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  color: #ED0874;
}

.rst__rowContents {
  min-width: auto !important;
  max-width: 10rem;
  font-family: "Montserrat Regular";
}

.attribute-active {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  font-family: "Montserrat Regular";
  font-size: 1rem;
}
.attribute-active input {
  margin-right: 1rem;
}

.attributes-table-container {
  position: absolute;
  width: 38vw;
  height: 85%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  top: 12%;
  left: 20%;
  border: 0.5px solid #BEBEBE;
  border-radius: 8px;
}

.attributes-table-title-btn-container {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: calc(100% - 4rem);
  left: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.attributes-table-title {
  font-size: 1.125rem;
  font-family: "Montserrat Semibold";
  color: #043250;
}

.attributes-table-btn {
  width: 9.625rem;
  height: 2.125rem;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 7px;
  color: #fff;
  display: flex;
  font-size: 0.875rem;
  cursor: pointer;
  font-family: "Montserrat Regular";
  justify-content: center;
  align-items: center;
}

.kermit-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 60%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.kermit-container img {
  max-height: 12rem;
  min-height: 12rem;
  max-width: 12rem;
  min-width: 12rem;
  object-fit: contain;
  margin-bottom: 3rem;
}

.kermit-warning {
  color: #616161;
  text-align: center;
  font-size: 1.25rem;
  font-family: "Montserrat Semibold";
}

.created-attributes-container {
  position: relative;
  margin-bottom: 2rem;
  width: calc(100% - 4rem);
  left: 2rem;
  height: 85%;
  overflow-y: auto;
}

.attrbiutes-individual-container {
  width: 100%;
  position: relative;
  height: 3rem;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1294117647);
  border: 0.5px solid #BEBEBE;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.attributes-individual-drag-controller {
  cursor: grab;
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 1.25rem;
  height: 1.25rem;
}
.attributes-individual-drag-controller img {
  max-height: 100%;
  min-width: 100%;
  min-width: 100%;
  max-width: 100%;
  object-fit: contain;
}

.attributes-individual-name {
  font-size: 1rem;
  font-family: "Montserrat Semibold";
  color: #000;
}

.attributes-delete-edit-container {
  position: absolute;
  right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.attrbiutes-icon-container {
  cursor: pointer;
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.attrbiutes-icon-container img {
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: contain;
}

.attributes-table-tree-container {
  position: absolute;
  top: 3.5rem;
  background-color: white;
  width: 17.125rem;
  height: 15.875rem;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3019607843);
  border: 0.5px solid #616161;
  border-radius: 10px;
  z-index: 2;
  right: 2rem;
}

.attributes-table-tree {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.5px solid #616161;
  border-radius: 3px;
  width: 15.125rem;
  height: 9.188rem;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  overflow: auto;
}

.attributes-tree-title {
  margin-top: 2rem;
  position: relative;
  left: 1rem;
  font-size: 1rem;
  color: #043250;
  font-family: "Montserrat Semibold";
  margin-bottom: 0.75rem;
}

.attributes-tree-text {
  position: relative;
  left: 1rem;
  font-size: 0.75rem;
  color: #000000;
  font-family: "Montserrat Regular";
  margin-bottom: 0.75rem;
}

.attributes-individual-color-container {
  cursor: pointer;
  display: flex;
  position: absolute;
  left: 50%;
}

.attributes-delete {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  width: 1rem;
  height: 1rem;
}

.attributes-edit-active {
  position: absolute;
  left: 65%;
  width: 1.125rem;
  height: 1.125rem;
}
.attributes-edit-active input {
  width: 100%;
  height: 100%;
}

.attributes-list-names-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 5rem);
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: 0.5rem;
  font-family: "Montserrat Semibold";
  font-size: 0.875rem;
}

#root > div > div.attributes-table-container > div.attributes-list-names-container > div:nth-child(2) {
  position: absolute;
  left: 58%;
}

.attributes-create-container {
  position: absolute;
  width: 38vw;
  height: 85%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  top: 12%;
  right: 2%;
  border: 0.5px solid #BEBEBE;
  border-radius: 8px;
}

.attributes-create-title {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  width: calc(100% - 4rem);
  left: 2rem;
  font-size: 1.125rem;
  font-family: "Montserrat Semibold";
  color: #043250;
  height: 2.125rem;
  display: flex;
  align-items: center;
}

.attributes-creation-container {
  position: relative;
  width: calc(100% - 4rem);
  left: 2rem;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.5rem;
}

.attribute-creation-title {
  position: relative;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-family: "Montserrat Semibold";
}

.attribute-creation-selector {
  position: relative;
  margin-bottom: 0.5rem;
  height: 1.875rem;
  width: 11vw;
}

.attribute-creation-name {
  margin-right: 1.25rem;
}

.attribute-creation-name-alt {
  display: flex;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  height: 1.875rem;
  align-items: center;
}
.attribute-creation-name-alt input {
  width: 1.25rem;
  height: 1.25rem;
}

.attribute-creation-name-alt-text {
  width: 70%;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  color: #616161;
  font-family: "Montserrat Regular";
}

.attibutes-creation-value {
  position: relative;
  width: calc(100% - 4rem);
  left: 2rem;
  display: flex;
  align-items: center;
}
.attibutes-creation-value input {
  height: 1.75rem;
  width: 10.85vw;
  margin-right: 1.25rem;
}

.create-attrbiute-value-btn {
  cursor: pointer;
  position: relative;
  height: 1.75rem;
  width: 6.438rem;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #fff;
}

.create-attribute-value-name {
  font-size: 0.688rem;
  font-family: "Montserrat Regular";
}

.create-attribute-value-plus {
  font-size: 1rem;
  font-family: "Montserrat Semibold";
}

.created-settings-attributes-container {
  position: relative;
  width: calc(100% - 4rem);
  left: 2rem;
  margin-top: 1rem;
  height: 65%;
  overflow-y: auto;
  margin-bottom: 1rem;
}

.save-cancel-attributes-btns {
  position: relative;
  width: calc(100% - 4rem);
  left: 2rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cancel-attributes-btn {
  width: 5.125rem;
  height: 2.5rem;
  background: #E0E0E0 0% 0% no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  color: #616161;
  font-family: "Montserrat Regular";
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.save-attributes-btn {
  width: 5.125rem;
  height: 2.5rem;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  color: #fff;
  font-family: "Montserrat Regular";
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.created-attribute-individual {
  width: 100%;
  height: 3rem;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1294117647);
  border: 0.5px solid #BEBEBE;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  z-index: 0;
}

.attribute-individual-value-name {
  color: #000000;
  font-family: "Montserrat Semibold";
  font-size: 0.875rem;
  width: 15%;
}

.attribute-individual-color {
  display: flex;
}

.attribute-individual-color-name {
  color: #616161;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
  margin-right: 0.75rem;
}

.attribute-current-color-selected {
  width: 1rem;
  height: 1rem;
  border: 0.5px solid #616161;
  margin-right: 0.75rem;
  cursor: pointer;
}

.attributes-values-name-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.attributes-values-name-container div {
  font-family: "Montserrat Semibold";
  font-size: 0.875rem;
}

.attributes-value-value {
  margin-left: 1rem;
  margin-right: 1rem;
}

.attributes-value-colors {
  position: absolute;
  left: 61%;
  transform: translate(-50%, 0);
  text-align: center;
  width: 50%;
}

.attribute-value-container {
  position: relative;
  display: flex;
  align-items: center;
}
.attribute-value-container img {
  margin-left: 0.5rem;
  cursor: pointer;
}

.edition-input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 13rem;
  height: 1.875rem;
}
.edition-input-container input {
  width: 6rem;
  margin-right: 0.5rem;
}
.edition-input-container div {
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  font-family: "Montserrat Regular";
  background: #ED0874 0% 0% no-repeat padding-box;
  width: 10rem;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
}

.color-picker-container {
  position: absolute;
  top: 100%;
  z-index: 2;
  left: 50%;
  transform: translate(-50%, 0);
}

.gallery-container {
  position: absolute;
  top: 55%;
  left: 59%;
  width: 75%;
  height: 80%;
  transform: translate(-50%, -50%);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.gallery-container-links {
  position: absolute;
  top: 55%;
  left: 48%;
  width: 55%;
  height: 80%;
  transform: translate(-50%, -50%);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.gallery-upload-btn {
  cursor: pointer;
  position: absolute;
  top: 4%;
  right: 5%;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #fff;
  width: 9rem;
  height: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
}

.dz-message span {
  font-family: "Montserrat Regular";
}

.dropzone {
  border-radius: 5px;
}

.bread-crumbs-container {
  position: absolute;
  top: 3%;
  width: 90%;
  height: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
}

.individual-bread-crumb {
  font-size: 1.2rem;
  font-family: "Montserrat Regular";
  color: #043250;
  margin-right: 0.6vw;
}

.individual-bread-crumb-current {
  font-size: 1.2rem;
  font-family: "Montserrat Semibold";
  color: #043250;
  margin-right: 0.6vw;
}

.gallery-cards-container {
  position: absolute;
  width: 90%;
  height: auto;
  top: 15%;
  left: 50%;
  transform: translate(-50%, 0%);
  display: grid;
  grid-template-columns: repeat(7, 2fr);
}

.individual-gallery-card {
  position: relative;
  cursor: pointer;
  margin: 1vh;
  height: 13vh;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
}
.individual-gallery-card img {
  position: absolute;
  width: 40%;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.individual-gallery-name {
  position: absolute;
  word-wrap: break-word;
  top: 65%;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
}

.gallery-image-container {
  position: relative;
  top: 2%;
  width: 90%;
  height: 60%;
  left: 50%;
  transform: translate(-50%, 0);
}
.gallery-image-container img {
  max-height: 100%;
  min-height: 100%;
  max-width: 100%;
  min-width: 100%;
  object-fit: contain;
}

.gallery-preview-main {
  position: absolute;
  width: 90%;
  height: auto;
  max-height: 70%;
  top: 12%;
  left: 50%;
  transform: translate(-50%, 0%);
  display: grid;
  grid-template-columns: repeat(5, 3fr);
  overflow-y: auto;
}

.gallery-preview-main-collapsed {
  position: absolute;
  width: 90%;
  height: auto;
  max-height: 70%;
  top: 12%;
  left: 50%;
  transform: translate(-50%, 0%);
  display: grid;
  grid-template-columns: repeat(4, 3fr);
  overflow-y: auto;
}

.gallery-image-card {
  position: relative;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
  width: 80%;
  height: 12rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.gallery-image-container-links {
  position: relative;
  top: 0%;
  width: 100%;
  height: 60%;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 5px;
}
.gallery-image-container-links img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 90%;
  min-height: 90%;
  max-width: 90%;
  min-width: 90%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.information-container {
  position: relative;
  width: 100%;
  height: 40%;
  border-radius: 0px 0px 5px 5px;
  background-color: rgba(0, 0, 0, 0.1);
}

.gallery-image-name {
  position: relative;
  word-wrap: break-word;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
  top: 0.5rem;
  margin-bottom: 1rem;
  width: 80%;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
}

.fullscreen-image-contaienr {
  cursor: pointer;
  position: absolute;
  top: 5%;
  left: 2.5%;
  width: 15%;
  height: 20%;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.63) 0% 0% no-repeat padding-box;
}
.fullscreen-image-contaienr img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 70%;
  min-height: 70%;
  max-width: 70%;
  min-width: 70%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.close-image-contaienr {
  cursor: pointer;
  position: absolute;
  top: 5%;
  right: 2.5%;
  width: 15%;
  height: 20%;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.63) 0% 0% no-repeat padding-box;
}
.close-image-contaienr img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 70%;
  min-height: 70%;
  max-width: 70%;
  min-width: 70%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.image-options {
  position: relative;
  display: flex;
  width: 80%;
  left: 50%;
  transform: translate(-50%, 0);
  align-items: center;
  justify-content: center;
}

.fullscreen-option {
  width: 4rem;
  height: 1.75rem;
  font-family: "Montserrat Regular";
  color: #fff;
  background-color: #ED0874;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
  cursor: pointer;
}

.edit-gallery {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
  cursor: pointer;
}

.delete-gallery {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.edition-modal-gallery {
  position: absolute;
  top: 15%;
  height: 35%;
  right: 1%;
  width: 22.5%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 8px;
}

.edit-image-title {
  position: relative;
  top: 12%;
  left: 50%;
  transform: translate(-50%, 0);
  font-family: "Montserrat Semibold";
  color: #043250;
  font-size: 1.125rem;
  text-align: center;
  margin-bottom: 4rem;
}

.edit-image-container {
  position: relative;
  width: 80%;
  height: 40%;
  display: flex;
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: 2rem;
}

.edit-image-container-preview {
  position: relative;
  max-width: 50%;
  width: 50%;
  height: 100%;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 0.5px solid #616161;
  border-radius: 5px;
  margin-right: 1rem;
}
.edit-image-container-preview img {
  position: absolute;
  max-width: 90%;
  min-width: 90%;
  max-height: 90%;
  min-height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
}

.info-title-gallery {
  font-family: "Montserrat Regular";
  font-size: 1rem;
  color: #043250;
  margin-bottom: 0.75rem;
}

.info-subtitle-gallery {
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
  color: #000;
  margin-bottom: 0.75rem;
}

.edit-gallery-save {
  position: relative;
  width: 6rem;
  height: 2.25rem;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  font-family: "Montserrat Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translate(-50%, 0);
}

.fullscreen-component {
  position: absolute;
  background: rgba(0, 0, 0, 0.63) 0% 0% no-repeat padding-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.fullscreen-component img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  min-height: 90%;
  min-width: 80%;
  max-width: 80%;
  object-fit: contain;
}

.close-btn-gallery {
  cursor: pointer;
  position: absolute;
  top: 2%;
  right: 1%;
  background: rgb(237, 8, 116) 0% 0% no-repeat padding-box;
  border-radius: 7px;
  width: 4vh;
  height: 4vh;
}

.upload-main-container {
  position: absolute;
  background: rgba(0, 0, 0, 0.63) 0% 0% no-repeat padding-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.upload-card-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 60%;
  background: #fff 0% 0% no-repeat padding-box;
  transform: translate(-50%, -50%);
  border-radius: 7px;
}

.upload-card-header {
  position: absolute;
  height: 15%;
  width: 100%;
}

.upload-header-title {
  position: absolute;
  top: 50%;
  left: 5%;
  font-family: "Montserrat Regular";
  color: #043250;
  font-size: 1.2rem;
  transform: translate(0, -50%);
}

.upload-close-btn {
  position: absolute;
  top: 50%;
  right: 5%;
  height: 2.5rem;
  width: 2.5rem;
  background: rgb(237, 8, 116) 0% 0% no-repeat padding-box;
  border-radius: 7px;
  cursor: pointer;
  transform: translate(0, -50%);
}
.upload-close-btn img {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.filepicker {
  position: absolute;
  top: 52.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 70%;
  overflow-y: auto;
}

.upload-gallery-btn {
  position: absolute;
  bottom: 3%;
  right: 5%;
  width: 15%;
  height: 7%;
  background: rgb(237, 8, 116) 0% 0% no-repeat padding-box;
  border-radius: 7px;
  cursor: pointer;
  font-family: "Montserrat Regular";
  color: #fff;
  font-size: 0.9vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-create-container {
  position: absolute;
  top: 15%;
  height: auto;
  right: 1%;
  width: 22.5%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 8px;
}

.links-create-name {
  position: relative;
  left: 6%;
  font-size: 1.2rem;
  font-family: "Montserrat Semibold";
  color: #043250;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.links-create-text {
  position: relative;
  left: 6%;
  width: 70%;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
  color: #616161;
  margin-bottom: 1rem;
}

.links-create-name-container {
  position: relative;
  left: 6%;
  width: 80%;
  margin-bottom: 1rem;
}
.links-create-name-container input {
  width: 100%;
  height: 3vh;
}

.links-create-input-title {
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
  color: #000;
  margin-bottom: 3%;
}

.links-create-url-container {
  position: relative;
  top: 45%;
  left: 6%;
  width: 80%;
  margin-bottom: 1rem;
}
.links-create-url-container input {
  width: 100%;
  height: 3vh;
}

.links-create-desc-container {
  position: relative;
  top: 58%;
  left: 6%;
  width: 80%;
  height: 20%;
  margin-bottom: 1rem;
}
.links-create-desc-container textarea {
  width: 100%;
  height: 60%;
  resize: none;
}

.links-create-save {
  cursor: pointer;
  position: relative;
  bottom: 6%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 8rem;
  height: 2.5rem;
  background-color: #ED0874;
  color: #fff;
  border-radius: 8px;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.gallery-links-preview {
  position: absolute;
  top: 10%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, 0);
  height: 70%;
}

.gallery-link-container {
  position: relative;
  margin-bottom: 2%;
  margin-top: 2%;
  border: 1px solid #616161;
  height: 10%;
  border-radius: 8px;
}

.gallery-link-url {
  position: absolute;
  left: 60%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.75rem;
  font-family: "Montserrat Regular";
  color: #000;
}

.gallery-recorrido-name-des-container {
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translate(0, -50%);
}

.gallery-recorrido-name {
  font-size: 0.8vw;
  font-family: "Montserrat Semibold";
  color: #000;
}

.gallery-recorrido-desc {
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
  color: #000;
}

.close-gallery {
  position: absolute;
  width: 1rem;
  height: 1rem;
  right: 1rem;
  top: 50%;
  transform: translate(0%, -50%);
  cursor: pointer;
}
.close-gallery img {
  width: 100%;
  height: 100%;
}

.edit-links {
  position: absolute;
  width: 1rem;
  height: 1rem;
  right: 3rem;
  top: 42%;
  transform: translate(0%, -50%);
  cursor: pointer;
}

.preview-container {
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  font-family: "Montserrat Regular";
  font-size: 1rem;
}

.input-desc-links-create {
  font-family: "Montserrat Regular";
}

.brochures-upload-file {
  position: relative;
  margin-bottom: 1.5rem;
  height: auto;
  width: 88%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
}

.brochures-preview-image-upload {
  position: relative;
  width: 5rem;
  height: 5rem;
  margin-right: 1rem;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 0.5px solid #707070;
  border-radius: 5px;
}
.brochures-preview-image-upload img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.brochures-preview-link-title {
  position: relative;
  font-family: "Montserrat Semibold";
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.brochures-preview-subtitle {
  position: relative;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
}

.amenities-container {
  position: absolute;
  top: 56%;
  left: 49%;
  width: 58%;
  height: 82%;
  transform: translate(-50%, -50%);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 8px;
}

.create-amenities-default-container {
  position: absolute;
  top: 15%;
  right: 1%;
  width: 20%;
  height: 45%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 8px;
}

.create-amenities-new-container {
  position: absolute;
  top: 62%;
  right: 1%;
  width: 20%;
  height: 35%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 8px;
}

.amenidades-main-title {
  position: absolute;
  top: 3%;
  left: 3%;
  font-size: 1.125rem;
  color: #043250;
  font-family: "Montserrat Semibold";
}

.create-amenitie-new {
  position: absolute;
  top: 8%;
  left: 6%;
  font-size: 0.9vw;
  color: #043250;
  font-family: "Montserrat Semibold";
}

.amenitites-logo-container {
  position: absolute;
  top: 10%;
  width: 90%;
  height: 15%;
  left: 50%;
  transform: translate(-50%, 0);
}

.amenities-logo-image {
  position: absolute;
  width: 5.5vw;
  height: 5.5vw;
  background: #BEBEBE 0% 0% no-repeat padding-box;
  border-radius: 5px;
}
.amenities-logo-image img {
  position: absolute;
  max-width: 80%;
  min-width: 80%;
  max-height: 80%;
  min-height: 80%;
  object-fit: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.amenities-logo-information {
  position: absolute;
  left: 15%;
}

.logo-name-title-amenities {
  position: relative;
  font-family: "Montserrat Semibold";
  font-size: 0.9vw;
  margin-bottom: 1%;
}

.logo-format-subtitle-amenities {
  position: relative;
  font-family: "Montserrat Semibold";
  font-size: 0.75rem;
  margin-bottom: 1%;
  color: #939598;
}

.logo-format-dubtitle-amenities {
  position: relative;
  font-family: "Montserrat Semibold";
  font-size: 0.75rem;
  margin-bottom: 3%;
  color: #939598;
}

.inputfile-amenities {
  position: absolute;
  top: 130%;
  left: 20%;
  transform: translate(-50%, -50%);
  height: 60%;
  width: 35%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.input-amenities-icon-menu {
  cursor: pointer;
  position: absolute;
  top: 100%;
  left: 0%;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 0.5px solid #707070;
  border-radius: 5px;
  color: #707070;
  width: 40%;
  height: 60%;
  font-family: "Montserrat Regular";
  font-size: 0.85rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amenidades-list-container {
  position: absolute;
  top: 30%;
  width: 90%;
  height: auto;
  max-height: 53vh;
  left: 50%;
  transform: translate(-50%, 0);
  overflow-y: auto;
}

.amenitie-individual-container {
  position: relative;
  height: 6vh;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 5px;
  margin-bottom: 1vh;
  display: flex;
  align-items: center;
}

.amenitie-individual-name {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(0, -50%);
  font-family: "Montserrat Semibold";
  font-size: 0.9vw;
  color: #043250;
}

.amenity-icon-list-image {
  position: relative;
  max-height: 1.5rem;
  min-height: 1.5rem;
  max-width: 1.5rem;
  min-width: 1.5rem;
  object-fit: contain;
}

.amenitie-separator {
  position: absolute;
  right: 12.5%;
  top: 50%;
  background-color: #BEBEBE;
  width: 0.1vw;
  height: 40%;
  transform: translate(0, -50%);
}

.gallery-selector {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 6%;
  transform: translate(0, -50%);
  height: 40%;
  width: 5%;
}
.gallery-selector img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.edit-selector {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 1%;
  transform: translate(0, -50%);
  height: 40%;
  width: 5%;
}
.edit-selector img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.amenitites-select-spin {
  position: absolute;
  top: 50%;
  right: 14%;
  transform: translate(0, -50%);
  height: 2rem;
  display: flex;
  align-items: center;
}
.amenitites-select-spin select {
  margin-left: 1rem;
}

.create-new-container {
  position: absolute;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-new-amenitie-container {
  position: absolute;
  width: 100%;
  height: 60%;
}

.logo-new-amenitie {
  position: absolute;
  top: 55%;
  transform: translate(0, -50%);
  background: #BEBEBE 0% 0% no-repeat padding-box;
  border-radius: 5px;
  height: 4.5vw;
  width: 4.5vw;
}
.logo-new-amenitie img {
  position: absolute;
  max-height: 80%;
  min-height: 80%;
  max-width: 80%;
  min-width: 80%;
  object-fit: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-new-amenitie-info {
  position: absolute;
  width: 70%;
  left: 35%;
  top: 50%;
  transform: translate(0, -50%);
}

.logo-name-title-amenities-new {
  position: relative;
  font-family: "Montserrat Semibold";
  font-size: 0.8vw;
  margin-bottom: 3%;
}

.logo-format-subtitle-amenities-new {
  position: relative;
  font-family: "Montserrat Semibold";
  font-size: 0.7rem;
  margin-bottom: 1%;
  color: #939598;
}

.logo-format-dubtitle-amenities-new {
  position: relative;
  font-family: "Montserrat Regular";
  font-size: 0.7rem;
  margin-bottom: 20%;
  color: #939598;
}

.select-icon {
  cursor: pointer;
  position: absolute;
  top: 70%;
  left: 0%;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 0.5px solid #707070;
  border-radius: 5px;
  color: #707070;
  width: 45%;
  height: 40%;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

#root > div > div.create-amenities-new-container > div.edit-amenitie-container > div > div.logo-new-amenitie-info > div.select-icon {
  height: 30%;
  width: 55%;
}

.create-new-name {
  position: absolute;
  top: 100%;
  width: 100%;
  height: 3vh;
}
.create-new-name input {
  width: 100%;
  height: 100%;
}

.create-new-save-btn {
  position: absolute;
  top: 78%;
  left: 50%;
  transform: translate(-50%, 0);
  color: #fff;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 28%;
  height: 12%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amenitites-new-input {
  cursor: pointer;
  position: absolute;
  width: 45%;
  right: 10%;
  top: 70%;
  height: 30%;
  z-index: 2;
  opacity: 0;
}

.input-amenities-icon {
  cursor: pointer;
  position: absolute;
  width: 45%;
  right: 0%;
  top: 70%;
  height: 40%;
  font-family: "Montserrat Regular";
  font-size: 0.85rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ED0874 0% 0% no-repeat padding-box;
  border: 0.5px solid #ED0874;
  border-radius: 5px;
  color: #fff;
}

.default-amenities-title {
  position: absolute;
  top: 8%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
  font-family: "Montserrat Semibold";
  font-size: 0.9vw;
}

.default-amenties-image-info {
  position: absolute;
  top: 18%;
  width: 80%;
  left: 50%;
  height: 19%;
  transform: translate(-50%, 0%);
}

.default-amenities-icon-container {
  position: absolute;
  width: 4.5vw;
  height: 4.5vw;
  background: #BEBEBE 0% 0% no-repeat padding-box;
  border-radius: 5px;
}
.default-amenities-icon-container img {
  position: absolute;
  max-height: 80%;
  min-height: 80%;
  max-width: 80%;
  min-width: 80%;
  object-fit: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.default-amenties-info-container {
  position: absolute;
  width: 65%;
  right: 0;
}

.default-amenities-info-title {
  position: relative;
  color: #000000;
  font-size: 0.8vw;
  font-family: "Montserrat Semibold";
  margin-bottom: 3%;
}

.default-amenities-info-text {
  position: relative;
  color: #939598;
  font-family: "Montserrat Regular";
  font-size: 0.7rem;
  margin-bottom: 8%;
}

.default-amenities-select {
  cursor: pointer;
  position: relative;
  border: 1px solid #707070;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  height: 3vh;
  width: 50%;
  border-radius: 5px;
  color: #939598;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-amenities-input {
  position: absolute;
  z-index: 2;
  height: 3.55vh;
  opacity: 0;
}

.default-amenities-select-text {
  position: absolute;
  width: 80%;
  top: 48%;
  left: 50%;
  transform: translate(-50%, 0);
  color: #000000;
  font-size: 0.8vw;
  font-family: "Montserrat Semibold";
}

.default-amenities-selection {
  position: absolute;
  top: 55%;
  height: 7%;
  width: 80%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.default-amenities-save {
  cursor: pointer;
  position: absolute;
  bottom: 8%;
  height: 9%;
  width: 28%;
  left: 50%;
  transform: translate(-50%, 0);
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
}

.edit-amenitie-container {
  position: absolute;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.create-edit-save-btn {
  cursor: pointer;
  position: absolute;
  bottom: 10%;
  left: 20%;
  width: 25%;
  height: 10%;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #fff;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-edit-delete-btn {
  cursor: pointer;
  position: absolute;
  bottom: 10%;
  width: 25%;
  right: 20%;
  height: 10%;
  background: #BEBEBE 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #fff;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons-main-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(1, 17, 27, 0.7) 0% 0% no-repeat padding-box;
}

.icons-container {
  position: absolute;
  width: 60%;
  height: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(255, 255, 255) 0% 0% no-repeat padding-box;
  border: 1px solid rgb(190, 190, 190);
  border-radius: 13px;
}

.icons-name {
  position: absolute;
  top: 4%;
  left: 4%;
  font-family: "Montserrat Semibold";
  font-size: 0.9vw;
  color: #043250;
}

.icons-text {
  position: absolute;
  top: 7%;
  left: 4%;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  color: #939598;
}

.create-icons {
  cursor: pointer;
  position: absolute;
  right: 4%;
  top: 4%;
  height: 5%;
  width: 15%;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel-btn-icons {
  position: absolute;
  cursor: pointer;
  left: 33%;
  width: 15%;
  height: 5%;
  bottom: 3%;
  background: #BEBEBE 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.asign-btn-icons {
  position: absolute;
  cursor: pointer;
  right: 33%;
  width: 15%;
  height: 5%;
  bottom: 3%;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons-upload-container {
  position: absolute;
  width: 60%;
  height: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(255, 255, 255) 0% 0% no-repeat padding-box;
  border: 1px solid rgb(190, 190, 190);
  border-radius: 13px;
}

.icons-grid-container {
  position: absolute;
  width: 95%;
  height: auto;
  max-height: 75%;
  top: 12%;
  left: 50%;
  transform: translate(-50%, 0%);
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  overflow-y: auto;
}

.icon-individual {
  position: relative;
  width: 90%;
  left: 50%;
  transform: translate(-50%, 0);
  border: 1px solid #BEBEBE;
  height: 14vh;
  margin-bottom: 1vh;
  border-radius: 7px;
  overflow-y: auto;
}

.icon-folder-name {
  position: absolute;
  top: 10%;
  left: 50%;
  width: 80%;
  text-align: center;
  font-size: 0.9vw;
  font-family: "Montserrat Semibold";
  color: #043250;
  transform: translate(-50%, 0);
}

.icon-folder-container {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, 0);
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  overflow-y: auto;
}

.icon-folder-individual {
  cursor: pointer;
  position: relative;
  width: 5vh;
  height: 5vh;
  margin: 2vh;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.5px solid #BEBEBE;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-folder-individual img {
  position: absolute;
  max-width: 90%;
  min-width: 90%;
  max-height: 90%;
  min-height: 90%;
  object-fit: contain;
}

.save-icon-btn {
  cursor: pointer;
  position: absolute;
  right: 33%;
  bottom: 3%;
  width: 15%;
  height: 5%;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #fff;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-icon-main {
  position: absolute;
  top: 13%;
  width: 90%;
  left: 50%;
  height: 20%;
  transform: translate(-50%, 0);
}

.upload-icon-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
}

.upload-icon-image {
  position: absolute;
  top: 50%;
  left: 0;
  height: 5vw;
  width: 5vw;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 2px solid #BEBEBE;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0, -50%);
}
.upload-icon-image img {
  max-height: 80%;
  max-width: 80%;
  min-height: 80%;
  min-width: 80%;
  object-fit: contain;
}

.upload-icon-info {
  position: absolute;
  top: 30%;
  left: 25%;
  width: 60%;
  transform: translate(0, -50%);
}

.upload-icon-info-main {
  color: #000000;
  font-family: "Montserrat Regular";
  font-size: 0.9vw;
  margin-bottom: 2%;
}

.upload-icon-info-format {
  color: #939598;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  margin-bottom: 5%;
}

.input-icons-btn {
  position: absolute;
  cursor: pointer;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  color: #939598;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  width: 5.625rem;
  height: 1.5rem !important;
  font-size: 0.75rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputfile-icons {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  height: 2rem;
  opacity: 0;
}

.upload-icon-name {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
}

.upload-name-main {
  position: absolute;
  top: 8%;
  left: 10%;
  transform: translate(0%, 0);
  color: #000000;
  font-family: "Montserrat Regular";
  font-size: 0.9vw;
  margin-bottom: 2%;
}

.input-icon-name {
  position: absolute;
  top: 50%;
  left: 10%;
  width: 60%;
  height: 20%;
}

.selector-container {
  position: absolute;
  top: 62.5%;
  left: 50%;
  width: 90%;
  max-height: 50%;
  height: auto;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  overflow-y: auto;
  border: 1px solid #BEBEBE;
}

.icon-individual-container {
  position: relative;
  height: 6vh;
  border: 1px solid #BEBEBE;
  border-radius: 4px;
  margin: 2vh;
}
.icon-individual-container label {
  width: 90%;
  position: absolute;
  font-family: "Montserrat Semibold";
  font-size: 0.8vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.icon-individual-container label input {
  position: absolute;
  top: 50%;
  right: 1%;
  width: 3vh;
  height: 3vh;
  transform: translate(0, -50%);
}

input[type=checkbox]:checked {
  background-color: #ED0874;
  accent-color: #ED0874;
}

.gallery-modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(1, 17, 27, 0.7) 0% 0% no-repeat padding-box;
}

.gallery-modal-main {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 70%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.gallery-modal-title {
  position: absolute;
  top: 5%;
  left: 5%;
  font-size: 1.2rem;
  font-family: "Montserrat Semibold";
  color: #043250;
}

.gallery-modal-subtitle {
  position: absolute;
  top: 10%;
  left: 5%;
  font-size: 0.9vw;
  font-family: "Montserrat Regular";
  color: #939598;
}

.gallery-modal-cancel {
  cursor: pointer;
  position: absolute;
  bottom: 3%;
  left: 35%;
  background: #BEBEBE 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 0.9vw;
  font-family: "Montserrat Regular";
  color: #fff;
  width: 12%;
  height: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-modal-asign {
  cursor: pointer;
  position: absolute;
  bottom: 3%;
  right: 35%;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 0.9vw;
  font-family: "Montserrat Regular";
  color: #fff;
  width: 12%;
  height: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-modal-cards-container {
  position: absolute;
  width: 45%;
  left: 4%;
  height: 60%;
  top: 50%;
  transform: translate(0, -50%);
  border: 1px solid #BEBEBE;
  border-radius: 5px;
}

.gallery-modal-selected-material {
  position: absolute;
  width: 45%;
  right: 4%;
  height: 60%;
  top: 50%;
  transform: translate(0, -50%);
  border: 1px solid #BEBEBE;
  border-radius: 5px;
}

.gallery-cards-container-modal {
  position: absolute;
  width: 90%;
  height: 80%;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  overflow-y: auto;
  overflow-x: hidden;
}

.individual-gallery-card-modal {
  position: relative;
  cursor: pointer;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  margin-bottom: 0.5rem;
  height: 7rem;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
}
.individual-gallery-card-modal img {
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.individual-gallery-name-modal {
  position: absolute;
  word-wrap: break-word;
  top: 70%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, 0);
  text-align: center;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
}

.gallery-image-container-modal {
  position: absolute;
  top: 2%;
  width: 90%;
  height: 60%;
  left: 50%;
  transform: translate(-50%, 0);
}
.gallery-image-container-modal img {
  max-height: 100%;
  min-height: 100%;
  max-width: 100%;
  min-width: 100%;
  object-fit: contain;
}

.gallery-preview-main-modal {
  position: absolute;
  width: 100%;
  height: 80%;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  overflow-y: auto;
}

.gallery-image-card-modal {
  width: 7vw;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 1vh;
  height: 12vh;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
}

.gallery-image-container-modal {
  position: absolute;
  top: 2%;
  width: 90%;
  height: 60%;
  left: 50%;
  transform: translate(-50%, 0);
}
.gallery-image-container-modal img {
  max-height: 100%;
  min-height: 100%;
  max-width: 100%;
  min-width: 100%;
  object-fit: contain;
}

.gallery-image-name-modal {
  position: absolute;
  word-wrap: break-word;
  top: 65%;
  font-family: "Montserrat Regular";
  font-size: 0.65rem;
  width: 80%;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
}

.fullscreen-image-contaienr-modal {
  cursor: pointer;
  position: absolute;
  top: 5%;
  left: 2.5%;
  width: 15%;
  height: 20%;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.63) 0% 0% no-repeat padding-box;
}
.fullscreen-image-contaienr-modal img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 70%;
  min-height: 70%;
  max-width: 70%;
  min-width: 70%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.show-selection-main {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  overflow-y: auto;
}

.selected-card-main {
  width: 7vw;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 1vh;
  height: 10vh;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
}
.selected-card-main img {
  max-height: 100%;
  min-height: 100%;
  max-width: 100%;
  min-width: 100%;
  object-fit: contain;
}

.properties-container {
  position: absolute;
  top: 56%;
  left: 59%;
  width: 75%;
  height: 80%;
  background: #FFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
  transform: translate(-50%, -50%);
}

.properties-header-container {
  position: absolute;
  top: 2%;
  width: 100%;
  height: 8%;
}

.properties-header-title {
  position: absolute;
  left: 2%;
  font-family: "Montserrat Semibold";
  color: #043250;
  font-size: 1.2rem;
  top: 50%;
  transform: translate(0, -50%);
}

.properties-new-btn {
  cursor: pointer;
  position: absolute;
  right: 12rem;
  top: 50%;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 7px;
  height: 60%;
  width: 9rem;
  transform: translate(0, -50%);
  font-family: "Montserrat Regular";
  color: #fff;
  font-size: 0.85rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.properties-actions-btn {
  position: absolute;
  right: 2rem;
  top: 50%;
  width: 9rem;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 7px;
  height: 60%;
  transform: translate(0, -50%);
  font-family: "Montserrat Regular";
  color: #fff;
  font-size: 0.85rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.properties-actions-options {
  position: absolute;
  right: 2rem;
  top: 80%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 0.5px solid #BEBEBE;
  z-index: 2;
  width: 15%;
  height: 120%;
}

.properties-actions-selection {
  position: relative;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #043250;
  font-family: "Montserrat Regular";
  font-size: 0.9rem;
  cursor: pointer;
}

.breadcrumbs-container {
  position: absolute;
  width: 80%;
  top: 100%;
  font-family: "Montserrat Semibold";
  color: #014976;
  font-size: 0.8vw;
}

.breadcrumbs-builders {
  text-decoration: none;
  color: #616161;
  font-family: "Montserrat Regular";
  margin-right: 1.2%;
}

.project-name-edit-container {
  position: absolute;
  top: 10%;
  width: 90%;
  height: 40%;
  left: 50%;
  transform: translate(-50%, 0);
}

.project-name {
  position: absolute;
  width: 55%;
  min-width: 55%;
  max-width: 55%;
  height: 90%;
  top: 50%;
  left: 0;
  display: flex;
  word-break: break-all;
  align-items: center;
  transform: translate(0, -50%);
  font-size: 0.8vw;
  font-family: "Montserrat Semibold";
}

.project-url-see-container {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50%;
  width: 90%;
  transform: translate(-50%, 0);
}

.project-divider {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(0%, -50%);
  width: 2px;
  height: 90%;
  background-color: #BEBEBE;
}

.project-options-container {
  position: absolute;
  width: 30%;
  right: 10%;
  display: flex;
  top: 50%;
  transform: translate(0, -50%);
}

.project-url-btn {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: 55%;
  height: 40%;
  transform: translate(0, -50%);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #ED0874;
  border-radius: 5px;
}
.project-url-btn img {
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translate(0, -50%);
  max-height: 70%;
  width: 12%;
}

.project-url-text {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translate(0, -50%);
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
}

.project-see {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(0, -50%);
  width: 30%;
  height: 40%;
  background-color: #ED0874;
  border: 1px solid #ED0874;
  color: #fff;
  font-family: "Montserrat Semibold";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8vw;
  border-radius: 7px;
}

#root > div > div.builders-container > div.builders-card-grid > div:nth-child(1) > div.builders-info-container > div.project-name-edit-container > div.project-options-container > button:nth-child(1) {
  padding: 20% !important;
}

#root > div > div.builders-container > div.builders-card-grid > div:nth-child(1) > div.builders-info-container > div.project-name-edit-container > div.project-options-container > button:nth-child(2) {
  padding: 20% !important;
}

.general-roads-container {
  position: absolute;
  top: 11%;
  left: 19%;
  width: 55%;
  height: 88%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
}

.general-roads-title {
  position: relative;
  top: 3%;
  left: 3%;
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 1.125rem;
}

.general-roads-icon-container {
  position: relative;
  top: 2%;
  width: 95%;
  left: 2.5%;
  height: 10%;
}

.general-roads-icon-name {
  position: relative;
  font-family: "Montserrat Regular";
  color: #043250;
  font-size: 0.9vw;
}

.icon-roads {
  position: absolute;
  top: 20%;
  width: 4.5vw;
  height: 4.5vw;
  background: #BEBEBE 0% 0% no-repeat padding-box;
  border-radius: 5px;
}
.icon-roads img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 80%;
  min-width: 80%;
  max-height: 80%;
  min-height: 80%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.icon-text-info-roads {
  position: absolute;
  left: 25%;
  top: 20%;
}

.info-roads-title {
  position: relative;
  margin-bottom: 3%;
  color: #000000;
  font-family: "Montserrat Semibold";
  font-size: 0.8vw;
}

.info-roads-text {
  position: relative;
  margin-bottom: 3%;
  color: #939598;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
}

.general-roads-icon-selector {
  position: absolute;
  left: 0%;
  width: 50%;
  top: 0%;
  height: 100%;
}

.general-roads-icon-selector-oval {
  position: absolute;
  left: 55%;
  width: 60%;
  top: 0%;
  height: 100%;
}

.icon-roads-oval {
  position: absolute;
  top: 20%;
  width: 4.5vw;
  height: 4.5vw;
  background: #BEBEBE 0% 0% no-repeat padding-box;
  border-radius: 5px;
}
.icon-roads-oval img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 80%;
  min-width: 80%;
  max-height: 80%;
  min-height: 80%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.icon-text-info-roads-oval {
  position: absolute;
  left: 25%;
  top: 20%;
}

.info-roads-title-oval {
  position: relative;
  margin-bottom: 3%;
  color: #000000;
  font-family: "Montserrat Semibold";
  font-size: 0.8vw;
}

.info-roads-text-oval {
  position: relative;
  margin-bottom: 3%;
  color: #939598;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
}

.information-logo-icon-oval {
  position: absolute;
  top: 92.5%;
  font-family: "Montserrat Semibold";
  color: #ED0874;
  font-size: 0.8vw;
}

#root > div > div.roads-create-container > div.roads-create-icon-container > div.roads-create-info-text > div.input-icons-btn {
  height: 50%;
  font-size: 0.8vw;
}

.general-roads-icon-general-container {
  position: relative;
  display: flex;
  margin-top: 2rem;
}

.general-roads-icon-selector {
  position: relative;
}

.input-icons-btn {
  height: 2rem;
}

.general-roads-set-container {
  position: relative;
  width: 95%;
  height: 75%;
  left: 2.5%;
  top: 7%;
}

.general-roads-location-config {
  display: flex;
  width: 100%;
  height: 10%;
  margin-top: 2%;
}

.general-roads-location-map-type {
  position: relative;
  width: 70%;
  height: 15%;
  display: flex;
  text-align: right;
}
.general-roads-location-map-type div {
  margin-right: 20px;
}
.general-roads-location-map-type div input {
  margin-right: 10px;
}

.general-roads-location-search-container {
  position: relative;
  width: 30%;
  height: 8%;
}
.general-roads-location-search-container div {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  color: #000000;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
}
.general-roads-location-search-container input {
  position: relative;
  left: 20%;
  top: 50%;
  width: 20%;
  transform: translate(0, -50%);
}

.lat-lon-container {
  margin-top: 1%;
  position: relative;
  width: 100%;
  height: 8%;
  display: flex;
}

.lat-lon-text {
  position: relative;
  width: 60%;
  margin-right: 10%;
  color: #000000;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
}

.lat-container {
  margin-right: 15%;
  color: #000000;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
}

.lon-container {
  margin-right: 15%;
  color: #000000;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
}

.map-container {
  position: relative;
  width: 100%;
  height: 70%;
  background: #BEBEBE 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.zoom-container {
  margin-top: 0.5%;
  position: relative;
  width: 100%;
  height: 10%;
}

.default-zoom-container {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.max-min-zoom-container {
  position: absolute;
  left: 30%;
  width: 70%;
  top: 50%;
  transform: translate(0, -50%);
}

.default-zoom-title {
  position: relative;
  color: #000000;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  margin-bottom: 3%;
}

.default-zoom-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.default-zoom-input-container input {
  width: 50%;
  margin-right: 10%;
}

.default-zoom-btn {
  cursor: pointer;
  width: 40%;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 3px;
  color: #FFFFFF;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.max-min-zoom-input-container {
  display: flex;
}

.max-min-zoom-title {
  position: relative;
  color: #000000;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  margin-bottom: 1%;
}

.min-zoom-container {
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5%;
}

.max-zoom-container {
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.min-zoom-btn {
  cursor: pointer;
  width: 25%;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 3px;
  color: #FFFFFF;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.max-zoom-btn {
  cursor: pointer;
  width: 25%;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 3px;
  color: #FFFFFF;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.min-zoom-text {
  position: relative;
  color: #000000;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
}

.max-zoom-text {
  position: relative;
  color: #000000;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
}

#map {
  height: 100%;
}

.fijar-btn {
  position: absolute;
  left: 50%;
  top: 88%;
  transform: translate(-50%, 0);
  border: 0.5px solid #ED0874;
  width: 16.938rem;
  height: 2.125rem;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 3px;
  color: #fff;
}

.center-map {
  position: absolute;
  right: 0;
  top: 88%;
  width: 9.875rem;
  height: 2.125rem;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.5px solid #ED0874;
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
  color: #ED0874;
}

.lng-show-container {
  position: absolute;
  top: 100%;
  left: 30%;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
  width: 20%;
  text-align: center;
}

.lat-show-container {
  position: absolute;
  top: 100%;
  right: 30%;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
  width: 20%;
  text-align: center;
}

.roads-create-container {
  position: absolute;
  top: 41.5%;
  right: 0.5%;
  width: 24.5%;
  height: 57.5%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
  transform: translate(0%, 0%);
}

.roads-create-title {
  position: relative;
  top: 3%;
  left: 3%;
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 1rem;
}

.roads-create-icon-container {
  position: relative;
  left: 3%;
  top: 5%;
  width: 90%;
  height: 10%;
  display: flex;
}

.roads-create-icon-selector {
  position: relative;
  width: 4.5vw;
  height: 4.5vw;
  background: #BEBEBE 0% 0% no-repeat padding-box;
  border-radius: 5px;
}
.roads-create-icon-selector img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 80%;
  min-width: 80%;
  max-height: 80%;
  min-height: 80%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.roads-create-info-text {
  position: absolute;
  left: 25%;
  width: 80%;
}

.roads-info-title {
  position: relative;
  margin-bottom: 1%;
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 0.875rem;
}

.roads-info-subtitle {
  position: relative;
  margin-bottom: 1%;
  color: #000000;
  font-family: "Montserrat Regular";
  font-size: 0.8rem;
}

.rodas-info-text {
  position: relative;
  margin-bottom: 1%;
  color: #939598;
  font-family: "Montserrat Regular";
  font-size: 0.8rem;
}

.road-create-container {
  position: relative;
  top: 14%;
  left: 3%;
  width: 90%;
  border: 1px solid #BEBEBE;
  border-radius: 5px;
}

.roads-create-title-main {
  position: relative;
  margin-top: 2%;
  left: 3%;
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 0.875rem;
}

.create-roads-set-container {
  position: relative;
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 90%;
  left: 3%;
}

.create-roads-set-color {
  margin-left: 1%;
}
.create-roads-set-color input {
  width: 20%;
}

.create-roads-set-name {
  width: 40%;
}
.create-roads-set-name input {
  height: 3vh;
  width: 100%;
}

.create-roads-name-title {
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
  margin-bottom: 1vh;
}

.create-roads-color-title {
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
  margin-bottom: 1vh;
}

.selected-color-container {
  height: 3vh;
  border: 1px solid #BEBEBE;
}

.color-selector-input {
  width: 25%;
}
.color-selector-input input {
  height: 3vh;
  width: 100%;
}

.save-road-btn {
  cursor: pointer;
  width: 13%;
  height: 3vh;
  background: #ED0874 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 5px;
  color: #fff;
  font-size: 0.8rem;
  font-family: "Montserrat Regular";
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-roads-btns {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.map-draw-btn {
  cursor: pointer;
  width: 45%;
  height: 3vh;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #ED0874;
  border-radius: 4px;
  color: #ED0874;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.road-save-btn {
  width: 30%;
  height: 3vh;
  background: #ED0874 0% 0% no-repeat padding-box;
  border: 1px solid #ED0874;
  border-radius: 4px;
  color: white;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

#root > div > div.roads-create-container > div.road-create-container > div.create-roads-set-container > div.create-roads-set-color > div:nth-child(3) {
  z-index: 2;
  position: absolute !important;
}

.lote-container-main {
  position: absolute;
  top: 11%;
  right: 1%;
  width: 23.5%;
  height: 20%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
}

.lote-title {
  position: absolute;
  top: 10%;
  left: 3%;
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 0.9vw;
}

.lote-color-container {
  position: absolute;
  top: 50%;
  left: 5%;
  width: 50%;
  height: 40%;
  transform: translate(0, -50%);
}

.lote-color-title {
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 0.875rem;
  margin-bottom: 5%;
}

.lote-color-subcontainer {
  display: flex;
}

.lote-color-container-div {
  width: 15%;
  margin-right: 5%;
}

.lote-colorPicker {
  top: 110%;
  z-index: 2;
  position: absolute !important;
}

.lote-draw-btn {
  cursor: pointer;
  position: absolute;
  top: 60%;
  left: 30%;
  width: 20%;
  height: 20%;
  transform: translate(0, -50%);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #ED0874;
  border-radius: 4px;
  color: #ED0874;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lote-save {
  cursor: pointer;
  position: absolute;
  top: 60%;
  left: 52%;
  width: 20%;
  height: 20%;
  transform: translate(0, -50%);
  background: #ED0874 0% 0% no-repeat padding-box;
  border: 1px solid #ED0874;
  border-radius: 4px;
  color: #fff;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lote-delete {
  cursor: pointer;
  position: absolute;
  top: 60%;
  left: 74%;
  width: 20%;
  height: 20%;
  transform: translate(0, -50%);
  background: #ED0874 0% 0% no-repeat padding-box;
  border: 1px solid #ED0874;
  border-radius: 4px;
  color: #fff;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lote-icon-creation-container {
  position: absolute;
  top: 11%;
  right: 0.5%;
  background-color: #fff;
  width: 24.5%;
  border-radius: 10px;
  border: 0.5px solid #616161;
  height: 30vh;
}

.lote-icon-creation-title {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  left: 1rem;
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 1rem;
}

.icon-creation-container {
  position: relative;
  width: calc(100% - 2rem);
  left: 1rem;
  display: flex;
  align-items: center;
}

.icon-creation-image-container {
  position: relative;
  width: 5rem;
  height: 5rem;
  background: #E0E0E0 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin-right: 1rem;
}
.icon-creation-image-container img {
  position: absolute;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-creation-information-title {
  position: relative;
  margin-bottom: 0.5rem;
  font-family: "Montserrat Semibold";
  font-size: 0.875rem;
}

.icon-creation-information-text {
  position: relative;
  margin-bottom: 0.5rem;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
  color: #616161;
}

.icon-creation-information-input-container input {
  position: relative;
  width: 5.625rem;
  height: 1.5rem;
  opacity: 0;
}

.icon-creation-information-btn {
  position: absolute;
  border: 1px solid #E0E0E0;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border-radius: 3px;
  width: 5.625rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
}

.lote-creation-container {
  position: relative;
  width: calc(100% - 2rem);
  left: 1rem;
  margin-top: 1rem;
}

.lote-creation-title {
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 0.875rem;
}

.lote-creation-options {
  position: relative;
  display: flex;
  align-items: center;
}
.lote-creation-options input {
  height: 1.875rem;
  margin-right: 0.5rem;
  width: 5rem;
  border-radius: 5px;
  border: 0.5px solid #000;
}

.lote-color-container-thumb {
  cursor: pointer;
  position: relative;
  width: 1.875rem;
  height: 1.875rem;
  margin-right: 0.5rem;
}

.sketch-picker {
  top: 100%;
  position: absolute;
  z-index: 2;
}

.lote-draw-btn-creation {
  cursor: pointer;
  border-radius: 4px;
  border: 0.5px solid #ED0874;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  font-size: 0.75rem;
  color: #ED0874;
  height: 1.875rem;
  width: 7.5rem;
  font-family: "Montserrat Regular";
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 0.5rem;
}

.lote-save-btn-creation {
  cursor: pointer;
  background: #ED0874 0% 0% no-repeat padding-box;
  border: 0.5px solid #ED0874;
  border-radius: 4px;
  height: 1.875rem;
  width: 4.375rem;
  color: #fff;
  font-size: 0.75rem;
  font-family: "Montserrat Regular";
  display: flex;
  justify-content: center;
  align-items: center;
}

.lote-container-created {
  position: relative;
  width: calc(100% - 2rem);
  left: 1rem;
  margin-top: 1rem;
  display: flex;
  border: 0.5px solid #616161;
  border-radius: 5px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  align-items: center;
  height: 2.5rem;
}
.lote-container-created div {
  position: absolute;
  left: 0.5rem;
  font-family: "Montserrat Semibold";
  font-size: 0.75rem;
}
.lote-container-created img {
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 0.5rem;
}

.list-roads-container {
  position: relative;
  top: 15%;
  width: 90%;
  height: 40%;
  left: 3%;
  overflow-y: auto;
}

.list-roads-individual-container {
  position: relative;
  margin-bottom: 2%;
  height: 3.125rem;
  border: 1px solid #616161;
  border-radius: 10px;
}

.list-roads-name-container {
  position: absolute;
  top: 50%;
  left: 3%;
  width: 40%;
  height: 80%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
}
.list-roads-name-container img {
  max-height: 40%;
  min-height: 40%;
}

.list-roads-name {
  position: relative;
  margin-right: 10%;
  font-size: 0.75rem;
  font-family: "Montserrat Regular";
}

.delete-road {
  cursor: pointer;
  position: absolute;
  width: 5%;
  height: 80%;
  right: 3%;
  top: 50%;
  transform: translate(0, -50%);
}
.delete-road img {
  max-width: 50%;
  max-height: 50%;
  min-width: 50%;
  max-height: 50%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-roads-color {
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  width: 1.5rem;
  height: 1.5rem;
}

.list-roads-name-input {
  width: 100%;
}

.colorPicker-roads {
  position: absolute;
  bottom: 95%;
  left: 55%;
}

.table-container {
  position: absolute;
  width: 90%;
  height: 85%;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.properties-main-table-container {
  position: absolute;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  top: 12%;
  left: 19%;
  width: 80%;
  height: 86%;
}

.properties-table-header {
  position: relative;
  width: calc(100% - 4rem);
  left: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.properties-table-title {
  font-family: "Montserrat Semibold";
  color: #043250;
  font-size: 1.125rem;
}

.porperties-table-btns-container {
  display: flex;
}

.properties-create-new-btn {
  cursor: pointer;
  height: 2.5rem;
  width: 8.625rem;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 7px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
}

.properties-table-actions-btn {
  cursor: pointer;
  height: 2.5rem;
  width: 5rem;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
}
.properties-table-actions-btn .custom-select .Select-clear-zone {
  display: none;
}

.properties-table-actions-btn-small-default {
  cursor: pointer;
  height: 2rem;
  width: 3rem;
  background: #D4D4D4 0% 0% no-repeat padding-box;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  margin-left: 10px;
}

.MuiInputBase-sizeSmall {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.properties-table-actions-btn-small-success {
  cursor: pointer;
  height: 2rem;
  width: 3rem;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  margin-left: 10px;
}

.properties-table-general-container {
  position: relative;
  left: 2rem;
  width: calc(100% - 4rem);
  height: 85%;
  overflow-x: auto;
  overflow-y: auto;
}

.propeties-table-header-container {
  position: relative;
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  height: 2.5rem;
}

.checkbox-container {
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
}
.checkbox-container input {
  width: 100%;
  height: 100%;
}

.table-header-attributes-container {
  display: flex;
  border: 1px solid #939598;
  border-radius: 5px;
  height: 100%;
  width: auto;
  align-items: center;
  background-color: #f5f5f5;
}

.table-header-item {
  min-width: 10rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 0.875rem;
  font-family: "Montserrat Semibold";
  color: #000000;
}

.table-header-item-selectable {
  cursor: pointer;
  min-width: 10rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 0.875rem;
  font-family: "Montserrat Semibold";
  color: #000000;
}

.properties-individual-container {
  position: relative;
  display: flex;
  align-items: center;
  height: 3.125rem;
  margin-bottom: 1rem;
}

.properties-individual-items-container {
  display: flex;
  position: relative;
  border: 1px solid #939598;
  height: 100%;
  width: auto;
  border-radius: 5px;
  align-items: center;
  background-color: #fafafa;
}

.propeties-individual-title {
  min-width: 10rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  margin-left: 1rem;
  font-size: 0.875rem;
  font-family: "Montserrat Regular";
}
.propeties-individual-title select {
  height: 2.063rem;
  width: 90%;
}
.propeties-individual-title input {
  height: 2.063rem;
}

.property-new-individual-container {
  display: flex;
  height: 3.125rem;
  align-items: center;
  margin-bottom: 1rem;
}

.property-new-creation-container {
  display: flex;
  position: relative;
  border: 1px solid #ED0874;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 8px;
  height: 100%;
  width: auto;
  align-items: center;
}

.property-creation-individual-title {
  min-width: 10rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  margin-left: 1rem;
  font-size: 0.875rem;
  font-family: "Montserrat Regular";
}
.property-creation-individual-title select {
  height: 2.063rem;
  width: 90%;
}
.property-creation-individual-title input {
  height: 2.063rem;
}

.property-creation-save-btn {
  cursor: pointer;
  position: relative;
  width: 5.625rem;
  height: 2.125rem;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.875rem;
}

.properties-action-container {
  position: absolute;
  top: 2.5rem;
  z-index: 4;
  right: 0;
  border: 0.5px solid #E0E0E0;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3215686275);
  background: #FFFFFF 0% 0% no-repeat padding-box;
}

.properties-action-options {
  cursor: pointer;
  height: 3.125rem;
  width: 10.188rem;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(4, 50, 80);
  font-size: 0.875rem;
  font-family: "Montserrat Regular";
}

.properties-action-options:hover {
  cursor: pointer;
  height: 3.125rem;
  width: 10.188rem;
  background-color: rgba(0, 179, 197, 0.2);
  transition: all 0.3s ease-in-out;
}

.properties-make-copies-container {
  position: absolute;
  top: 2.5rem;
  z-index: 2;
  right: 0;
  border: 1px solid #BEBEBE;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3019607843);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  height: 7.063rem;
  width: 12.875rem;
}

.properties-close-container {
  cursor: pointer;
  width: 0.75rem;
  height: 0.75rem;
  position: relative;
  margin-top: 0.75rem;
  right: 1.5rem;
  left: calc(100% - 1.5rem);
}
.properties-close-container img {
  max-height: 100%;
  min-height: 100%;
  max-width: 100%;
  min-width: 100%;
  object-fit: contain;
}

.properties-copies-title {
  position: relative;
  left: 1.5rem;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  font-family: "Montserrat Semibold";
  color: #014976;
  margin-bottom: 1rem;
}

.properties-copies-input-container {
  position: relative;
  display: flex;
  width: calc(100% - 3rem);
  left: 1.5rem;
  align-items: center;
}

.properties-copies-input {
  width: 5rem;
  height: 1.938rem;
  margin-right: 1rem;
}
.properties-copies-input input {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  border: 0.5px solid #616161;
}

.propeties-copies-input-btn {
  cursor: pointer;
  position: relative;
  width: 3.875rem;
  height: 1.938rem;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 7px;
  color: #fff;
  font-size: 0.75rem;
  font-family: "Montserrat Regular";
  display: flex;
  align-items: center;
  justify-content: center;
}

.properties-edit-attribute-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(1, 17, 27, 0.4) 0% 0% no-repeat padding-box;
}

.properties-edit-attribute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 18rem;
  width: 28.5rem;
  background: rgb(255, 255, 255) 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
}

.properties-edit-attribute-close {
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
.properties-edit-attribute-close img {
  max-height: 100%;
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  object-fit: contain;
}

.properties-edit-attribute-title {
  position: relative;
  margin-top: 3rem;
  left: 2.5rem;
  font-size: 1.125rem;
  color: #043250;
  font-family: "Montserrat Semibold";
  margin-bottom: 1rem;
}

.properties-edit-attribute-text {
  position: relative;
  left: 2.5rem;
  width: calc(100% - 5rem);
  color: #616161;
  font-size: 0.875rem;
  font-family: "Montserrat Regular";
  margin-bottom: 1rem;
}

.properties-edit-attribute-setup {
  position: relative;
  left: 2.5rem;
  width: calc(100% - 5rem);
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.properties-edit-setup-input {
  width: 10rem;
  margin-right: 1rem;
}
.properties-edit-setup-input select {
  width: 100%;
  height: 1.875rem;
}

.properties-edit-setup-title {
  color: #014976;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  font-family: "Montserrat Semibold";
}

.properties-edit-btns {
  position: relative;
  left: 2.5rem;
  width: calc(100% - 5rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.properties-edit-attributes-cancel {
  cursor: pointer;
  position: relative;
  background: #E0E0E0 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 4.875rem;
  height: 2.125rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-family: "Montserrat Regular";
}

.properties-edit-attributes-apply {
  cursor: pointer;
  position: relative;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 8.125rem;
  height: 2.125rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-family: "Montserrat Regular";
  color: #fff;
}

.table-header-item-title {
  z-index: 3;
}

.attributes-filter-container {
  position: absolute;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  z-index: 2;
  top: 10%;
  width: 10rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3019607843);
  border: 0.5px solid #BEBEBE;
  border-radius: 5px;
}

.title-space-container {
  height: 2rem;
}

.properties-individual-option {
  display: flex;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.properties-individual-option input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.properties-individual-option div {
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
}

.pois-container-main {
  position: absolute;
  left: 20%;
  top: 13%;
  height: 84%;
  width: 55%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
}

.poi-icon-select-main {
  position: absolute;
  right: 2%;
  top: 13%;
  width: 21%;
  height: 35%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
}

.poi-icon-select-title {
  position: relative;
  top: 8%;
  left: 3%;
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 1.125rem;
}

.poi-icon-select-container {
  position: relative;
  top: 14%;
  left: 3%;
  width: 90%;
  height: 40%;
}

.poi-icon-selected-container {
  position: absolute;
  height: 4.5vw;
  width: 4.5vw;
  background: #BEBEBE 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
}
.poi-icon-selected-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  min-width: 80%;
  max-height: 80%;
  min-height: 80%;
  object-fit: contain;
}

.poi-icon-text-container {
  position: absolute;
  left: 30%;
}

.pois-info-title {
  position: relative;
  margin-bottom: 1%;
  color: #000;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
}

.pois-info-subtitle {
  position: relative;
  margin-bottom: 1%;
  color: #000;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
}

.pois-info-text {
  position: relative;
  margin-bottom: 1%;
  color: #939598;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
}

.input-icons-btn-poi {
  position: absolute;
  cursor: pointer;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  color: #939598;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  width: 7rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-poi-title {
  position: absolute;
  top: 4.5%;
  left: 3%;
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 0.9vw;
}

.poi-update-btn {
  cursor: pointer;
  position: absolute;
  top: 3%;
  right: 3%;
  width: 10%;
  height: 5%;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  color: #fff;
}

.header-poi-first-text {
  position: absolute;
  top: 12%;
  width: 50%;
  left: 3%;
  color: #000;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
}

.header-poi-second-text {
  position: absolute;
  top: 18%;
  width: 50%;
  left: 3%;
  color: #000;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
}

.create-poi-container {
  position: absolute;
  right: 2%;
  top: 50%;
  width: 21%;
  height: 15%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
}

.create-poi-title {
  position: absolute;
  top: 10%;
  left: 3%;
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 0.9vw;
}

.crate-poi-input-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 20%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.crate-poi-input-container input {
  height: 80%;
  margin-right: 5%;
}

.poi-create-btn {
  cursor: pointer;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  color: #fff;
  height: 100%;
  width: 30%;
}

.poi-category-container {
  position: absolute;
  width: 100%;
  top: 28%;
  left: 50%;
  height: 68%;
  overflow-x: auto;
  transform: translate(-50%, 0);
}

.poi-category-individual {
  position: relative;
  left: 50%;
  height: 8%;
  width: 30%;
  margin-bottom: 2%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 4px;
  transform: translate(-50%, 0);
}

.poi-category-individual-name {
  position: absolute;
  top: 50%;
  left: 12%;
  color: #000;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  transform: translate(0, -50%);
}

.poi-category-input {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translate(0, -50%);
  width: 10%;
  height: 50%;
}

.header-disabled-zoom {
  position: absolute;
  top: 810%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
}
.header-disabled-zoom input {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1rem;
}
.header-disabled-zoom div {
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
}

.draggable-controller {
  position: absolute;
  top: 50%;
  left: 2.5%;
  transform: translate(0, -50%);
  width: 1rem;
  height: 1rem;
  cursor: move;
}
.draggable-controller img {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 1rem;
  width: 1rem;
  transform: translate(-50%, -50%);
  object-fit: contain;
}

.general-properties-container {
  position: absolute;
  top: 55%;
  left: 55%;
  width: 45%;
  height: 75%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
  transform: translate(-50%, -50%);
}

.general-properties-select-icon-container {
  position: absolute;
  top: 3%;
  left: 5%;
  width: 70%;
  height: 30%;
}

.general-properties-select-view-container {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 90%;
  height: 65%;
}

.general-propertoes-title {
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 1.125rem;
}

.general-properties-icon-selected-container {
  position: absolute;
  height: 5.5vw;
  width: 5.5vw;
  background: #BEBEBE 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
}
.general-properties-icon-selected-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  min-width: 80%;
  max-height: 80%;
  min-height: 80%;
  object-fit: contain;
}

.general-properties-icon-select-container {
  margin-top: 5%;
  position: relative;
  width: 70%;
  height: 40%;
}

.general-properties-icon-text-container {
  position: absolute;
  left: 31%;
}
.general-properties-icon-text-container input {
  width: 50%;
}

.general-propertiess-info-title {
  position: relative;
  margin-bottom: 1%;
  color: #000;
  font-family: "Montserrat Semibold";
  font-size: 0.9vw;
}

.general-properties-info-subtitle {
  position: relative;
  margin-bottom: 1%;
  color: #000;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
}

.general-properties-info-text {
  position: relative;
  margin-bottom: 1%;
  color: #939598;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
}

.input-icons-btn-general-properties {
  position: absolute;
  cursor: pointer;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  color: #939598;
  font-family: "Montserrat Regular";
  font-size: 0.9vw;
  margin-top: 2%;
  width: 7rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.general-properties-attributes-view {
  position: absolute;
  width: 45%;
  height: 100%;
  left: 0;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 5px;
}
.general-properties-attributes-view a {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 35%;
  height: 8%;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 3px;
  text-decoration: none;
  color: #fff;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.general-properties-imuebles-view {
  position: absolute;
  width: 45%;
  height: 100%;
  right: 0;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 5px;
}
.general-properties-imuebles-view a {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 65%;
  height: 8%;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 3px;
  text-decoration: none;
  color: #fff;
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.general-properties-attributes-title {
  position: absolute;
  left: 50%;
  width: 80%;
  text-align: center;
  top: 5%;
  transform: translate(-50%, 0);
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 1.125rem;
}

.general-properties-imuebles-title {
  position: absolute;
  left: 50%;
  left-width: 80%;
  text-align: center;
  top: 5%;
  transform: translate(-50%, 0);
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 1.125rem;
}

.general-properties-imuebles-text {
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translate(-50%, 0%);
  width: 80%;
  color: #000;
  font-family: "Montserrat Regular";
  font-size: 0.9vw;
}

.general-properties-attributes-text {
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translate(-50%, 0%);
  width: 80%;
  color: #000;
  font-family: "Montserrat Regular";
  font-size: 0.9vw;
}

.general-properties-filter-menu-container {
  position: absolute;
  left: 70%;
  top: 25%;
  width: 50%;
}

.general-properties-filter-name {
  position: relative;
  margin-bottom: 1.5rem;
  font-family: "Montserrat Semibold";
  font-size: 1rem;
}

.general-properties-filter-radios {
  display: flex;
  flex-direction: column;
}
.general-properties-filter-radios label {
  display: flex;
  align-items: center;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}
.general-properties-filter-radios label input {
  margin-right: 0.5rem;
}

b {
  font-family: "Montserrat Semibold";
}

.pois-category-container-main {
  position: absolute;
  top: 13%;
  left: 20%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
  height: 85%;
  width: 55%;
}

.pois-category-header-title {
  position: absolute;
  top: 5%;
  left: 3%;
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 1.125rem;
}

.pois-category-header-subtitle {
  position: absolute;
  top: 10%;
  left: 3%;
  color: #000;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
}

.pois-category-header-create {
  position: absolute;
  top: 13%;
  left: 3%;
  width: 60%;
  height: 3.5%;
  display: flex;
}
.pois-category-header-create input {
  position: relative;
  height: 100%;
  max-height: 100%;
  width: 70%;
  margin-right: 5%;
}

.pois-category-header-create-btn {
  cursor: pointer;
  position: relative;
  width: 10%;
  height: 100%;
  top: 0;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #ED0874;
  color: #ED0874;
  font-family: "Montserrat Semibold";
  font-size: 0.75rem;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pois-category-header-text {
  position: absolute;
  top: 19%;
  left: 3%;
  color: #000;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
}

.pois-category-right-container-main {
  position: absolute;
  right: 1%;
  width: 23%;
  height: 85%;
  top: 13%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
}

.pois-category-icons-container {
  position: relative;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 90%;
  height: auto;
  min-height: 20%;
  margin-bottom: 1rem;
  display: flex;
}

.pois-category-icon-container {
  position: relative;
  width: 4.5vw;
  height: 4.5vw;
  margin-right: 5%;
  background: #BEBEBE 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
}
.pois-category-icon-container img {
  max-width: 80%;
  min-width: 80%;
  max-height: 80%;
  min-height: 80%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pois-category-icon-text-container {
  position: relative;
  width: 75%;
}

.pois-category-icon-title {
  color: #000;
  font-family: "Montserrat Semibold";
  font-size: 0.875rem;
  margin-bottom: 2%;
}

.pois-category-icon-text {
  color: #939598;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
  margin-bottom: 2%;
}

.pois-category-map-container {
  position: absolute;
  width: 95%;
  height: 65%;
  top: 23%;
  left: 50%;
  transform: translate(-50%, 0);
}

.pois-category-fijar-btn {
  position: absolute;
  left: 50%;
  top: 103%;
  transform: translate(-50%, 0);
  font-family: "Montserrat Regular";
  height: 6%;
  width: 30%;
  font-size: 0.8vw;
  border: 1px solid #ED0874;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pois-category-current-lng {
  position: absolute;
  top: 112%;
  left: 30%;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
}

.pois-category-current-lat {
  position: absolute;
  top: 112%;
  right: 30%;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
}

.pois-category-list-container {
  position: relative;
  top: 0%;
  width: 90%;
  height: 75%;
  left: 50%;
  transform: translate(-50%, 0);
  font-family: "Montserrat Semibold";
  font-size: 0.8vw;
  color: #043250;
}

.pois-category-list {
  position: relative;
  top: 0%;
  width: 100%;
  height: 95%;
  margin-top: 1rem;
  overflow-y: auto;
}

.poi-individual-container {
  position: relative;
  height: 7%;
  width: 100%;
  border: 1px solid #616161;
  margin-bottom: 3%;
  border-radius: 10px;
}

.poi-individual-name {
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translate(0, -50%);
}

.upload-spins-container {
  position: absolute;
  top: 13%;
  left: 20%;
  width: 58%;
  height: 25%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
}

.upload-spins-title {
  position: absolute;
  top: 8%;
  left: 2.5%;
  font-family: "Montserrat Semibold";
  font-size: 1.125rem;
  color: #043250;
}

.upload-name-type-container {
  position: absolute;
  left: 3%;
  top: 25%;
  width: 50%;
  height: 38%;
}

.upload-spin-map-container {
  position: absolute;
  right: 30%;
  top: 48%;
  width: 35%;
  height: 50%;
  transform: translate(0, -50%);
}

.upload-container-name {
  position: absolute;
  height: 70%;
  width: 40%;
}
.upload-container-name input {
  height: 30%;
  width: 100%;
}

.upload-continer-type {
  position: absolute;
  top: 60%;
  height: 70%;
  width: 40%;
  left: 0%;
}
.upload-continer-type select {
  height: 38%;
  width: 100%;
}

.upload-spin-name {
  position: relative;
  color: #000;
  font-size: 0.875rem;
  font-family: "Montserrat Semibold";
  margin-bottom: 1.5%;
}

.upload-spin-type {
  position: relative;
  color: #000;
  font-size: 0.875rem;
  font-family: "Montserrat Semibold";
  margin-bottom: 1.5%;
}

.upload-spin-container {
  position: absolute;
  width: 100%;
  height: 90%;
  border: 1px solid #939598;
  border-radius: 3px;
}
.upload-spin-container input {
  cursor: pointer;
  border: 1px solid red;
  position: absolute;
  left: 25%;
  top: 65%;
  z-index: 3;
  opacity: 0;
}

.upload-map-container {
  position: absolute;
  width: 40%;
  height: 90%;
  left: 45%;
  border: 1px solid #939598;
  border-radius: 3px;
}
.upload-map-container input {
  position: absolute;
  left: 30%;
  top: 65%;
  z-index: 2;
  opacity: 0;
}

.upload-spin-image-container {
  position: absolute;
  height: 4.5vw;
  width: 4.5vw;
  background-color: #F5F5F5;
  top: 50%;
  left: 2.5%;
  transform: translate(0, -50%);
  border: 1px solid #939598;
  border-radius: 3px;
}
.upload-spin-image-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 80%;
  min-height: 80%;
  max-width: 80%;
  min-width: 80%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.upload-spin-modal-title {
  position: absolute;
  left: 27.5%;
  top: 10%;
  color: #000;
  font-size: 0.8vw;
  font-family: "Montserrat Semibold";
}

.upload-spin-modal-inst {
  position: absolute;
  left: 27.5%;
  top: 27.5%;
  color: #616161;
  font-size: 0.7vw;
  font-family: "Montserrat Regular";
}

.upload-spin-btn {
  cursor: pointer;
  position: absolute;
  left: 27.5%;
  top: 65%;
  height: 25%;
  width: 30%;
  background-color: #F5F5F5;
  border: 1px solid #616161;
  color: #616161;
  border-radius: 5px;
  font-family: "Montserrat Regular";
  font-size: 0.7vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-spin-modal-format {
  position: absolute;
  left: 27.5%;
  top: 45%;
  color: #616161;
  font-size: 0.7vw;
  font-family: "Montserrat Regular";
}

.upload-map-image-container {
  position: absolute;
  height: 4.5vw;
  width: 4.5vw;
  background-color: #F5F5F5;
  top: 50%;
  left: 2.5%;
  transform: translate(0, -50%);
  border: 1px solid #939598;
  border-radius: 3px;
}
.upload-map-image-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 80%;
  min-height: 80%;
  max-width: 80%;
  min-width: 80%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.upload-map-name {
  position: absolute;
  left: 27.5%;
  top: 10%;
  color: #000;
  font-size: 0.8vw;
  font-family: "Montserrat Semibold";
}

.upload-map-format {
  position: absolute;
  left: 27.5%;
  top: 27.5%;
  color: #616161;
  font-size: 0.7vw;
  font-family: "Montserrat Regular";
}

.upload-map-btn {
  position: absolute;
  left: 27.5%;
  top: 65%;
  height: 25%;
  width: 30%;
  background-color: #F5F5F5;
  border: 1px solid #616161;
  color: #616161;
  border-radius: 5px;
  font-family: "Montserrat Regular";
  font-size: 0.7vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-save-btn {
  position: absolute;
  cursor: pointer;
  width: 5vw;
  height: 4vh;
  background-color: #ED0874;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  border-radius: 3px;
  bottom: 10%;
  left: 3%;
}

.list-spins-container-main {
  position: absolute;
  top: 40%;
  left: 20%;
  width: 58%;
  height: 58%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
}

.list-spins-title {
  position: absolute;
  top: 3%;
  left: 2.5%;
  font-family: "Montserrat Semibold";
  font-size: 1.125rem;
  color: #043250;
}

.list-files-spins {
  position: absolute;
  top: 13%;
  right: 1%;
  width: 20%;
  height: 85%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
}

.list-files-spins-names {
  position: absolute;
  top: 1.5%;
  font-family: "Montserrat Semibold";
  font-size: 1.125rem;
  color: #043250;
  left: 3%;
}

.list-files-text-container {
  position: absolute;
  top: 6%;
  font-family: "Montserrat Regular";
  width: 90%;
  font-size: 0.8rem;
  color: #000;
  left: 3%;
}
.list-files-text-container p {
  margin-bottom: 5%;
}

h6 {
  font-family: "Montserrat Semibold";
  color: #043250;
  font-size: 0.8rem;
  cursor: pointer;
}

.list-files-general-container {
  position: absolute;
  top: 20%;
  width: 90%;
  height: 70%;
  left: 50%;
  transform: translate(-50%, 0);
  overflow-y: auto;
  overflow-x: hidden;
}

.list-files-container {
  position: relative;
  border: 0.5px solid #616161;
  border-radius: 5px;
  width: 99%;
  height: auto;
  min-height: 4vh;
  margin-bottom: 5%;
}

.files-individual-container {
  position: relative;
}

.files-uncollapsed-radio-container {
  opacity: 0;
  height: 0vh;
  pointer-events: none;
}

.files-collapse-title-btn {
  height: 5vh;
  cursor: pointer;
}

.files-collapse-title {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 5%;
  font-family: "Montserrat Semibold";
  font-size: 1rem;
  color: #043250;
}

.files-collapse-image-btn {
  position: absolute;
  width: 5%;
  height: 100%;
  right: 5%;
}
.files-collapse-image-btn img {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.files-radio-individual-container {
  position: relative;
  width: 90%;
  height: 6vh;
  margin-top: 2%;
  margin-bottom: 2%;
  left: 50%;
  transform: translate(-50%, 0);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1607843137);
  border: 0.5px solid #616161;
  border-radius: 5px;
}

.group-name-spins {
  position: relative;
  width: 90%;
  margin-bottom: 2%;
  left: 50%;
  transform: translate(-50%, 0);
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  color: #043250;
}

.files-input-container {
  position: absolute;
  left: 1%;
  height: 100%;
  width: 10%;
}
.files-input-container input {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}

.files-name-date-container {
  position: absolute;
  left: 12%;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 1rem;
}

.files-name {
  font-size: 0.875rem;
  font-family: "Montserrat Semibold";
  color: #000000;
  letter-spacing: 0px;
}

.files-date {
  font-size: 0.875rem;
  font-family: "Montserrat Regular";
  color: #616161;
  letter-spacing: 0px;
}

.list-spins-container {
  position: absolute;
  top: 15%;
  width: 95%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
}

.spins-container-individual {
  position: relative;
  width: 98%;
  height: 5vh;
  border: 0.5px solid #616161;
  border-radius: 5px;
  margin-bottom: 10px;
}

.spin-container-name {
  position: absolute;
  top: 50%;
  left: 1%;
  height: 100%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  width: 40%;
  max-width: 40%;
}

.spin-name {
  font-family: "Montserrat Semibold";
  font-size: 1rem;
  margin-right: 5%;
}

.spin-edition-container {
  position: relative;
  cursor: pointer;
}
.spin-edition-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spin-type {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  font-family: "Montserrat Regular";
  font-size: 1rem;
}

.spin-close-btn {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 1%;
  width: 1rem;
  height: 1rem;
  transform: translate(0, -50%);
}
.spin-close-btn img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.list-spins-info-container {
  position: absolute;
  top: 9%;
  width: 95%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 2vh;
}

.list-spins-info-name {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0, -50%);
  font-size: 1rem;
  font-family: "Montserrat Semibold";
}

.list-spins-info-type {
  position: absolute;
  top: 50%;
  left: 34.5%;
  transform: translate(0, -50%);
  font-size: 1rem;
  font-family: "Montserrat Semibold";
}

.spins-container-name-edition {
  position: relative;
  left: 1%;
  height: 100%;
  display: flex;
  align-items: center;
}
.spins-container-name-edition input {
  margin-right: 2%;
}

.spins-container-name-save-btn {
  cursor: pointer;
  width: 5vw;
  height: 60%;
  background-color: #ED0874;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  border-radius: 3px;
}

.script-spin-selector {
  width: 90%;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
}
.script-spin-selector div {
  margin-right: 0.5rem;
}

.validate-spins-container-main {
  position: absolute;
  top: 40%;
  left: 20%;
  width: 58%;
  height: 58%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
}
.validate-spins-container-main img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 20%;
  min-width: 20%;
  max-height: 20%;
  min-height: 20%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.validate-spins-title {
  position: absolute;
  top: 30%;
  left: 50%;
  font-family: "Montserrat Regular";
  font-size: 0.9vw;
  color: #000;
  transform: translate(-50%, -50%);
}

.validate-spins-text {
  position: absolute;
  top: 70%;
  left: 50%;
  font-family: "Montserrat Regular";
  font-size: 0.9vw;
  color: #000;
  transform: translate(-50%, -50%);
}

.spins-previews-container-main {
  position: absolute;
  top: 45%;
  left: 20%;
  width: 58%;
  height: 53%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
  overflow-y: auto;
}

.spins-previews-title {
  position: absolute;
  top: 3%;
  left: 3%;
  font-family: "Montserrat Semibold";
  font-size: 1.125rem;
  color: #043250;
}

.spins-preview-validation-info {
  position: absolute;
  left: 50%;
  top: 12.5%;
  transform: translate(-50%, 0);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  width: 60%;
}

.spins-preview-validation-title {
  position: relative;
  margin-top: 2%;
  margin-bottom: 2%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, 0);
  font-family: "Montserrat Regular";
  font-size: 0.9vw;
  color: #000;
}

.spins-preview-line {
  position: relative;
  display: flex;
  margin-bottom: 2%;
  margin-top: 2%;
  height: 30%;
}

.spins-preview-info-individual-container {
  position: relative;
  width: 45%;
  display: flex;
  align-items: center;
  margin-left: 2.5%;
  margin-right: 2.5%;
  height: 100%;
}
.spins-preview-info-individual-container img {
  max-height: 100%;
  min-height: 100%;
  object-fit: contain;
  margin-right: 5%;
}
.spins-preview-info-individual-container div {
  font-family: "Montserrat Regular";
  font-size: 0.8vw;
  color: #000;
}

.canvas-content {
  width: 80%;
  height: 90%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, 0%);
  margin-bottom: 5%;
}

.canvas-3d {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.camera-frames {
  width: 100%;
  height: 100%;
}
.camera-frames img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}

.lazy-load-image-background {
  position: absolute;
  pointer-events: all;
}

.upload-spin-main-component {
  position: absolute;
  width: 38vw;
  height: 85%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  top: 12%;
  left: 20%;
  border: 0.5px solid #BEBEBE;
  border-radius: 8px;
}

.upload-spin-title {
  color: #043250;
  font-family: "Montserrat Semibold";
  position: relative;
  left: 3%;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.upload-spins-category-container {
  height: 90%;
  width: 94%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  overflow-y: auto;
  overflow-x: hidden;
}

.upload-spin-individual-category {
  position: relative;
  width: 100%;
  min-height: 3.75rem;
  height: auto;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 0.5px solid #8F8F8F;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
}

.upload-spin-name-icon-container {
  cursor: pointer;
  position: relative;
  height: 3.75rem;
  display: flex;
  align-items: center;
}
.upload-spin-name-icon-container img {
  max-height: 1rem;
  min-height: 1rem;
  min-width: 1rem;
  max-width: 1rem;
  object-fit: contain;
}

.closed-spin-icon {
  transform: rotate(-90deg);
}

.upload-spin-individual-name {
  font-size: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  font-family: "Montserrat Semibold";
}

.upload-spin-control-container {
  position: relative;
  margin-bottom: 1rem;
  margin-left: 1rem;
  width: calc(100% - 2rem);
  display: flex;
}

.upload-spin-control-left {
  position: relative;
  display: flex;
  align-items: center;
}

.upload-spin-icon-control {
  position: relative;
  width: 5.625rem;
  height: 5.625rem;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 0.5px solid #707070;
  border-radius: 5px;
  margin-right: 1rem;
}
.upload-spin-icon-control img {
  position: absolute;
  max-width: 3rem;
  min-width: 3rem;
  max-height: 3rem;
  min-height: 3rem;
  object-fit: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.upload-spin-control-info {
  position: relative;
  width: 15rem;
  margin-right: 1rem;
}

.upload-spin-control-info-title {
  font-size: 0.875rem;
  font-family: "Montserrat Semibold";
  color: #000000;
  margin-bottom: 0.3rem;
}

.upload-spin-control-info-text {
  font-size: 0.75rem;
  font-family: "Montserrat Regular";
  color: #616161;
  margin-bottom: 0.3rem;
}

.upload-spin-control-input-container {
  position: relative;
}
.upload-spin-control-input-container input {
  position: absolute;
  top: 0;
  cursor: pointer;
  height: 1.5rem;
  opacity: 0;
}

input[type=file] {
  cursor: pointer;
}

.upload-spin-control-info-btn {
  position: relative;
  height: 1.5rem;
  width: 7.75rem;
  border: 0.5px solid #616161;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-family: "Montserrat Regular";
}

.upload-spin-control-right {
  margin-left: 1rem;
}

.upload-spin-info-user-title {
  font-size: 0.875rem;
  font-family: "Montserrat Semibold";
  margin-bottom: 0.3rem;
}

.upload-spin-info-user-name {
  font-size: 0.75rem;
  font-family: "Montserrat Regular";
  color: #616161;
  margin-bottom: 0.3rem;
}

.upload-spin-info-user-date {
  font-size: 0.75rem;
  font-family: "Montserrat Regular";
  color: #616161;
  margin-bottom: 0.3rem;
}

.upload-spin-info-user-upload {
  font-size: 0.75rem;
  font-family: "Montserrat Regular";
  color: #616161;
  margin-bottom: 0.3rem;
}

.upload-spin-control-container-alt {
  position: relative;
  margin-bottom: 1rem;
  margin-left: 2rem;
  width: calc(100% - 4rem);
}

.select-amenity-spin-container {
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.select-amenity-spin-title {
  margin-right: 0.75rem;
  color: #000000;
  font-size: 0.875rem;
  font-family: "Montserrat Semibold";
}

.select-amenties-selector {
  margin-right: 0.75rem;
  height: 1.875rem;
  width: 12.5rem;
}

.select-amenity-spin-btn {
  cursor: pointer;
  width: 3.375rem;
  height: 1.875rem;
  color: #fff;
  font-size: 0.875rem;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat Regular";
}

.upload-spin-subcategory-container {
  width: 100%;
}

.upload-spin-subcategory-individual {
  width: 100%;
  border: 0.5px solid #8F8F8F;
  height: auto;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.upload-spin-subcategory-individual-name-icon {
  height: 2.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.upload-spin-subcategory-individual-name-icon img {
  max-height: 1rem;
  min-height: 1rem;
  min-width: 1rem;
  max-width: 1rem;
  object-fit: contain;
}

.upload-spin-subcategory-indiviudal-name {
  margin-left: 1rem;
  margin-right: 1rem;
  font-family: "Montserrat Semibold";
  font-size: 0.875rem;
}

.cheemsito-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cheemsito-container div {
  width: 50%;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-size: 1rem;
  font-family: "Montserrat Semibold";
  color: #8F8F8F;
}

.cheems-image {
  position: relative;
  max-width: 15rem;
  min-width: 15rem;
  max-height: 15rem;
  min-height: 15rem;
  object-fit: contain;
  opacity: 0.6;
}

.coordinates-main-container {
  position: absolute;
  width: 38vw;
  height: 85%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  top: 12%;
  right: 2%;
  border: 0.5px solid #BEBEBE;
  border-radius: 8px;
}

.coordinates-main-title {
  color: #043250;
  font-family: "Montserrat Semibold";
  position: relative;
  left: 3%;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.coordinates-main-text {
  color: #616161;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  width: calc(100% - 2rem);
  left: 3%;
  position: relative;
  margin-bottom: 1rem;
}

.coordinates-category-container {
  height: 80%;
  width: 94%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  overflow-y: auto;
  overflow-x: hidden;
}

.coordinates-individual-category {
  position: relative;
  width: 100%;
  min-height: 3.75rem;
  height: auto;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 0.5px solid #8F8F8F;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
}

.coordiantes-name-icon-container {
  cursor: pointer;
  position: relative;
  height: 3.75rem;
  display: flex;
  align-items: center;
}
.coordiantes-name-icon-container img {
  max-height: 1rem;
  min-height: 1rem;
  min-width: 1rem;
  max-width: 1rem;
  object-fit: contain;
}

.coordinates-closed-icon {
  transform: rotate(-90deg);
}

.coordinates-individual-name {
  font-size: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  font-family: "Montserrat Semibold";
}

.coordinates-files-container {
  position: relative;
  left: 1rem;
  width: calc(100% - 2rem);
}

.coordinate-file-individual-container {
  border: 0.5px solid #616161;
  border-radius: 5px;
  height: 3.625rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.coordinate-file-input-container {
  margin-left: 1rem;
  margin-right: 1rem;
}
.coordinate-file-input-container input {
  width: 1.5rem;
  height: 1.5rem;
  accent-color: #ED0874;
}

.coordinate-file-individual-name {
  font-family: "Montserrat Semibold";
  font-size: 0.875rem;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}

.coordinate-file-individual-date {
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
  color: #616161;
}

.script-name-spin-container {
  position: relative;
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.coordinates-group-name {
  position: relative;
  font-size: 0.875rem;
  color: #000000;
  font-family: "Montserrat Semibold";
}

.asociate-spin-coordinates {
  position: absolute;
  display: flex;
  align-items: center;
  right: 5%;
}
.asociate-spin-coordinates div {
  position: relative;
  font-size: 0.875rem;
  color: #000000;
  font-family: "Montserrat Semibold";
  margin-right: 1rem;
}

.asociate-spin-selector {
  height: 1.875rem;
  width: 18.75rem;
}

.show-more-coordinates {
  font-size: 1rem;
  font-family: "Montserrat Semibold";
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  text-align: center;
  color: #ED0874;
}

.asoleacion-setup-container-main {
  position: absolute;
  top: 13%;
  left: 20%;
  background-color: #FFFFFF;
  border: 1px solid #616161;
  width: 58%;
  height: 85%;
  border-radius: 10px;
}

.asoleacion-upload-title {
  position: absolute;
  top: 4%;
  left: 3%;
  color: #043250;
  font-size: 1.125rem;
  font-family: "Montserrat Semibold";
}

.asoleacion-upload-name-text {
  position: absolute;
  top: 10%;
  left: 3%;
  color: #000;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
}

.asoleacion-upload-desc-text {
  position: absolute;
  top: 23%;
  left: 3%;
  color: #000;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
}

.asolacion-list-vistas {
  position: absolute;
  left: 3%;
  top: 38%;
  width: 90%;
  height: 40%;
}

.asolacion-list-individual {
  position: relative;
  height: 15%;
  width: 100%;
  margin-bottom: 2%;
  padding-left: 2%;
  border-radius: 10px;
  border: 1px solid #616161;
  display: flex;
  align-items: center;
  font-size: 0.9vw;
  font-family: "Montserrat Semibold";
}

.asoleacion-upload-container-main {
  position: absolute;
  top: 13%;
  right: 1%;
  width: 20%;
  height: 50%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #616161;
  border-radius: 10px;
}

.asoleacion-setup-title {
  position: absolute;
  top: 3%;
  left: 3%;
  color: #043250;
  font-size: 1.125rem;
  font-family: "Montserrat Semibold";
}

.asoleacion-icono-setup {
  position: absolute;
  top: 10%;
  left: 3%;
  width: 40%;
  height: 15%;
}

.asoleacion-icono-preview {
  position: absolute;
  height: 4.5vw;
  width: 4.5vw;
  border: 1px solid #707070;
  background-color: #F5F5F5;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 10px;
}
.asoleacion-icono-preview img {
  position: absolute;
  max-width: 70%;
  min-width: 70%;
  max-height: 70%;
  min-height: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.asoleacion-icono-selection-text-container {
  position: absolute;
  top: 55%;
  left: 25%;
  transform: translate(0, -50%);
  height: 80%;
}
.asoleacion-icono-selection-text-container input {
  position: absolute;
  z-index: 2;
  height: 35%;
  opacity: 0;
}

.asoleacion-icono-selection-title {
  position: relative;
  margin-bottom: 3%;
  color: #000;
  font-size: 0.9vw;
  font-family: "Montserrat Semibold";
}

.asolaecion-icono-selection-text {
  position: relative;
  margin-bottom: 3%;
  color: #000;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
}

.input-asoleacion-icon-btn {
  position: absolute;
  height: 35%;
  width: 40%;
  background-color: #F5F5F5;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
  cursor: pointer;
}

.asoleacion-icono-document {
  position: absolute;
  top: 10%;
  left: 45%;
  width: 45%;
  height: 15%;
}

.asoleacion-list-title {
  position: absolute;
  top: 28%;
  left: 3%;
  color: #043250;
  font-size: 1.125rem;
  font-family: "Montserrat Semibold";
}

.asoleacion-list-text {
  position: absolute;
  top: 32%;
  left: 3%;
  color: #616161;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
}

.asolacion-icono-document-preview {
  position: absolute;
  height: 4.5vw;
  width: 4.5vw;
  border: 1px solid #707070;
  background-color: #F5F5F5;
  top: 52%;
  transform: translate(0, -50%);
  border-radius: 10px;
}
.asolacion-icono-document-preview img {
  position: absolute;
  max-width: 70%;
  min-width: 70%;
  max-height: 70%;
  min-height: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.asolecion-documentos-selection-text-container {
  position: absolute;
  top: 55%;
  left: 25%;
  transform: translate(0, -50%);
  height: 80%;
}
.asolecion-documentos-selection-text-container input {
  position: absolute;
  z-index: 2;
  height: 35%;
  opacity: 0;
}

.asoleacion-document-selection-title {
  position: relative;
  margin-bottom: 3%;
  color: #000;
  font-size: 0.9vw;
  font-family: "Montserrat Semibold";
}

.asoleacion-document-selection-text {
  position: relative;
  margin-bottom: 3%;
  color: #000;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
}

.input-asoleacion-document-btn {
  position: absolute;
  height: 35%;
  width: 40%;
  background-color: #F5F5F5;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
  cursor: pointer;
}

.input-name-asoleacion {
  position: absolute;
  top: 15%;
  left: 3%;
  width: 90%;
  height: 5%;
}

.input-desc-asoleacion {
  position: absolute;
  top: 28%;
  left: 3%;
  width: 90%;
  height: 20%;
  resize: none;
}

.asoleacion-upload-icon-preview-container {
  position: absolute;
  top: 53%;
  width: 90%;
  left: 3%;
  height: 7rem;
  border: 1px solid #616161;
  border-radius: 10px;
}

.asolaecion-upload-icon {
  position: absolute;
  top: 50%;
  left: 3%;
  height: 3.5vw;
  width: 3.5vw;
  transform: translate(0, -50%);
  background-color: #F5F5F5;
  border: 1px solid #707070;
  border-radius: 10px;
}
.asolaecion-upload-icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 70%;
  max-width: 70%;
  min-height: 70%;
  max-height: 70%;
  transform: translate(-50%, -50%);
  object-fit: contain;
}

.asolecion-upload-text-container {
  position: absolute;
  top: 50%;
  left: 28%;
  height: 70%;
  transform: translate(0, -50%);
}

.asoelacion-upload-title {
  position: relative;
  margin-bottom: 2%;
  color: #000;
  font-size: 0.8vw;
  font-family: "Montserrat Semibold";
}

.asoleacion-upload-text {
  position: relative;
  margin-bottom: 2%;
  color: #000;
  font-size: 0.75rem;
  font-family: "Montserrat Regular";
}

.asoleacion-upload-btn {
  position: absolute;
  height: 2rem;
  width: 7rem;
  background-color: #F5F5F5;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 0.7vw;
  font-family: "Montserrat Regular";
  cursor: pointer;
}

.asoleacion-upload-input {
  position: absolute;
  z-index: 2;
  height: 2rem;
  opacity: 0;
}

.asoleacion-upload-save-btn {
  cursor: pointer;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 8%;
  width: 25%;
  background-color: #ED0874;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9vw;
  font-family: "Montserrat Regular";
  border-radius: 10px;
}

.asoleacion-list {
  position: absolute;
  left: 20%;
  width: 58%;
  height: 50%;
  top: 45%;
}

.asoleacion-video-container {
  position: relative;
  margin-bottom: 2vh;
  width: 90%;
  height: 10%;
  left: 3%;
  border: 1px solid #616161;
  border-radius: 10px;
}

.asoleacion-video-title {
  position: absolute;
  left: 2%;
  top: 15%;
  font-size: 0.8vw;
  font-family: "Montserrat Semibold";
}

.asoleacion-video-desc {
  position: absolute;
  left: 2%;
  top: 60%;
  font-size: 0.6vw;
  font-family: "Montserrat Regular";
}

.asoleacion-video-preview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
}

.asoleacion-edit {
  cursor: pointer;
  position: absolute;
  top: 50%;
  height: 40%;
  width: 2%;
  right: 7%;
  transform: translate(0, -50%);
}
.asoleacion-edit img {
  position: absolute;
  min-height: 100%;
}

.asoleacion-delete {
  cursor: pointer;
  position: absolute;
  top: 50%;
  height: 40%;
  width: 2%;
  right: 2%;
  transform: translate(0, -50%);
}
.asoleacion-delete img {
  position: absolute;
  min-height: 100%;
}

.header-dispo-title {
  position: absolute;
  top: 3%;
  left: 3%;
  color: #043250;
  font-size: 1.125rem;
  font-family: "Montserrat Semibold";
}

.activate-dispo-container {
  position: absolute;
  width: 20%;
  height: 8%;
  top: 8%;
  right: 30%;
  background-color: #FFFFFF;
  border: 1px solid #616161;
}

.activate-dispo-states-container {
  position: absolute;
  left: 15%;
  width: 30%;
  top: 9%;
  height: 5%;
  background-color: #FFFFFF;
  border: 1px solid #616161;
  display: flex;
  justify-content: space-evenly;
}

.activate-dispo-in-masterplan-states-container {
  position: absolute;
  right: 3%;
  width: 40%;
  top: 9%;
  height: 5%;
  background-color: #FFFFFF;
  border: 1px solid #616161;
  display: flex;
  justify-content: space-evenly;
}

.activate-dispo-states-individual-container {
  position: relative;
  width: 30%;
}

.activate-dispo-masterplan-states-individual-container {
  position: relative;
  width: 30%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.activate-dispo-masterplan-states-individual-container label {
  font-size: 0.6rem;
}

.activate-dispo-state-name {
  position: relative;
  text-align: center;
  color: #000;
  font-size: 0.6rem;
  font-family: "Montserrat Regular";
}

.activate-dispo-title {
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translate(0, -50%);
  color: #000;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
}

.activate-dispo-switch {
  position: absolute !important;
  right: 5%;
  top: 50%;
  transform: translate(0, -50%);
}

.switch-state {
  position: relative;
  margin-top: 2%;
  left: 50%;
  transform: translate(-50%, 0);
}

.header-filter-hierarchy-first {
  cursor: pointer;
  position: absolute;
  top: 9%;
  height: 6%;
  width: 10%;
  left: 3%;
  background-color: #ED0874;
  border-radius: 10px;
  color: #fff;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-filter-hierarchy-second {
  cursor: pointer;
  position: absolute;
  top: 9%;
  height: 6%;
  width: 10%;
  left: 15%;
  background-color: #ED0874;
  border-radius: 10px;
  color: #fff;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-filters {
  background-color: #ED0874;
  border: none;
  color: #fff;
  font-size: 0.8vw;
}

.disponibilidad-table-container {
  position: absolute;
  top: 13%;
  left: 19%;
  background-color: #F5F5F5;
  border-radius: 10px;
  border: 1px solid #616161;
  height: 85%;
  width: 79%;
  overflow-y: auto;
}

.dispo-table-container {
  position: absolute;
  top: 20%;
  width: 90%;
  left: 50%;
  transform: translate(-50%, 0);
}

.dispo-actaulizar-btn {
  cursor: pointer;
  background-color: #ED0874;
  height: 100%;
  width: 60%;
  border-radius: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3%;
}

.quotation-icon-main-container {
  position: absolute;
  top: 12%;
  left: 20%;
  width: 42%;
  height: 20%;
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
}

.quotation-icon-title {
  position: absolute;
  top: 5%;
  left: 3%;
  color: #043250;
  font-size: 1.125rem;
  font-family: "Montserrat Semibold";
}

.quotation-icon-container {
  position: absolute;
  top: 20%;
  left: 3%;
  width: 60%;
  height: 60%;
}

.quotation-icon-holder {
  position: absolute;
  top: 50%;
  width: 4.5vw;
  height: 4.5vw;
  border: 1px solid #707070;
  background-color: #F5F5F5;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 10px;
}
.quotation-icon-holder img {
  position: absolute;
  max-width: 70%;
  min-width: 70%;
  max-height: 70%;
  min-height: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.quotation-icon-text-container {
  position: absolute;
  top: 50%;
  left: 22%;
  transform: translate(0, -50%);
  height: 80%;
}
.quotation-icon-text-container input {
  position: absolute;
  z-index: 2;
  height: 35%;
  opacity: 0;
}

.quotation-icon-container-title {
  position: relative;
  margin-bottom: 3%;
  color: #000;
  font-size: 0.9vw;
  font-family: "Montserrat Semibold";
}

.quotatuib-icon-text {
  position: relative;
  margin-bottom: 3%;
  color: #000;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
}

.quotation-icon-btn {
  position: absolute;
  height: 35%;
  width: 40%;
  background-color: #F5F5F5;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
  cursor: pointer;
}

.mailing-quotation-main-container {
  position: absolute;
  top: 33%;
  left: 20%;
  width: 42%;
  height: 65%;
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
}

.mailing-container-main {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 95%;
  height: 60%;
  border: 1px solid #616161;
  border-radius: 10px;
}

.mailing-quotation-title {
  position: absolute;
  top: 3%;
  left: 3%;
  color: #043250;
  font-size: 1.125rem;
  font-family: "Montserrat Semibold";
}

.mailing-quotation-text {
  position: absolute;
  top: 10%;
  left: 3%;
  color: #616161;
  width: 80%;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
}

.mailing-quoatuion-name {
  position: absolute;
  top: 25%;
  left: 3%;
  width: 40%;
  height: 15%;
}
.mailing-quoatuion-name input {
  width: 100%;
  height: 40%;
}

.mailing-quotatuion-name-text {
  position: relative;
  margin-bottom: 5%;
  color: #000;
  width: 80%;
  font-size: 0.9vw;
  font-family: "Montserrat Regular";
}

.mailing-quoatuion-des {
  position: absolute;
  top: 25%;
  left: 47%;
  width: 40%;
  height: 15%;
}
.mailing-quoatuion-des input {
  width: 100%;
  height: 40%;
}

.mailing-quotatuion-name-desc {
  position: relative;
  margin-bottom: 5%;
  color: #000;
  width: 80%;
  font-size: 0.9vw;
  font-family: "Montserrat Regular";
}

.quotation-mailing-container {
  position: absolute;
  top: 50%;
  left: 3%;
  width: 50%;
  height: 30%;
  border: 1px solid #616161;
  border-radius: 5px;
}

.quotation-mailing-holder {
  position: absolute;
  top: 50%;
  width: 4vw;
  height: 4vw;
  left: 2%;
  transform: translate(0, -50%);
  background-color: #F5F5F5;
  border: 1px solid #707070;
  border-radius: 10px;
}
.quotation-mailing-holder img {
  position: absolute;
  max-width: 70%;
  min-width: 70%;
  max-height: 70%;
  min-height: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.quotation-mailing-text-container {
  position: absolute;
  top: 50%;
  left: 24%;
  transform: translate(0, -50%);
  height: 80%;
}
.quotation-mailing-text-container input {
  position: absolute;
  z-index: 2;
  height: 35%;
  opacity: 0;
}

.quotation-mailing-title {
  position: relative;
  margin-bottom: 3%;
  color: #000;
  font-size: 0.9vw;
  font-family: "Montserrat Semibold";
}

.quotatuib-mailing-text {
  position: relative;
  margin-bottom: 3%;
  color: #000;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
}

.quotation-mailing-btn {
  position: absolute;
  height: 35%;
  width: 40%;
  background-color: #F5F5F5;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
  cursor: pointer;
}

.mailing-quotation-save-btn {
  cursor: pointer;
  position: absolute;
  bottom: 5%;
  left: 50%;
  height: 10%;
  width: 13%;
  background-color: #ED0874;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 0);
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
  border-radius: 5px;
}

.princing-quotation-main-container {
  position: absolute;
  top: 12%;
  right: 1%;
  width: 35%;
  height: 86%;
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.pricing-quotation-main-title {
  position: absolute;
  top: 1%;
  left: 3%;
  color: #043250;
  font-size: 1.125rem;
  font-family: "Montserrat Semibold";
}

.pricing-table-container {
  position: absolute;
  top: 16%;
  width: 95%;
  left: 50%;
  transform: translate(-50%, 0);
  overflow-y: auto;
  overflow-x: hidden;
}

.pricing-btn-filter-container {
  position: absolute;
  top: 10%;
  left: 3%;
  height: 3%;
  width: 80%;
  display: flex;
  align-items: center;
}
.pricing-btn-filter-container div {
  font-size: 0.8vw;
  font-family: "Montserrat Regular";
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.contact-information-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.salas-list-container {
  position: absolute;
  width: 62%;
  height: 85%;
  left: 19%;
  background-color: #fff;
  top: 13%;
  border-radius: 10px;
  border: 1px solid #BEBEBE;
}

.create-salas-emails-container {
  display: flex;
  margin-bottom: 0.5rem;
}
.create-salas-emails-container input {
  width: 60%;
  height: 1.25rem;
  margin-right: 0.5rem;
}
.create-salas-emails-container div {
  cursor: pointer;
  width: 30%;
  color: #fff;
  background-color: #ED0874;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: "Montserrat Regular";
  font-size: 1.5vh;
}

.create-salas-container {
  position: absolute;
  right: 1%;
  width: 17%;
  height: 85%;
  background-color: #fff;
  top: 13%;
  border-radius: 10px;
  border: 1px solid #BEBEBE;
  overflow-y: auto;
  overflow-x: hidden;
}

.salas-list-name {
  position: absolute;
  top: 3%;
  left: 3%;
  font-size: 1.2vw;
  font-family: "Montserrat Semibold";
}

.create-salas-name {
  position: relative;
  margin-top: 8%;
  margin-bottom: 10%;
  left: 5%;
  font-size: 2vh;
  font-family: "Montserrat Semibold";
}

.add-contact-info {
  cursor: pointer;
  position: relative;
  bottom: 0%;
  left: 50%;
  width: 50%;
  height: 5%;
  text-align: center;
  transform: translate(-50%, 100%);
  color: #fff;
  background-color: #ED0874;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: "Montserrat Regular";
  font-size: 1.5vh;
}

.create-salas-general-info {
  position: relative;
  left: 10%;
  width: 80%;
  transform: translate(0%, 0);
  margin-bottom: 8%;
}

.create-salas-email-info {
  position: relative;
  left: 10%;
  width: 85%;
  min-height: 8%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 1rem;
}

.email-container {
  font-family: "Montserrat Regular";
  font-size: 1.5vh;
  margin-top: 0.25rem;
  margin-bottom: 0.75rem;
}

.create-salas- {
  position: relative;
  margin-bottom: 5%;
  font-size: 1.5vh;
  font-family: "Montserrat Semibold";
}

.crate-salas-input-name {
  font-family: "Montserrat Regular";
  font-size: 1.5vh;
}

.create-salas-input-container {
  margin-bottom: 4%;
}
.create-salas-input-container input {
  width: 100%;
  height: 2vh;
}

.create-salas-numbers-info {
  position: relative;
  left: 10%;
  width: 85%;
  min-height: 8%;
  overflow-y: auto;
  overflow-x: hidden;
}

.create-salas-emails-name {
  position: relative;
  margin-bottom: 5%;
  font-size: 1.5vh;
  height: 8%;
  display: flex;
  align-items: center;
  font-family: "Montserrat Semibold";
}

.create-salas-numbers-name {
  position: relative;
  margin-bottom: 5%;
  font-size: 1.5vh;
  height: 8%;
  display: flex;
  align-items: center;
  font-family: "Montserrat Semibold";
}

.add-phone-btn {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 1.5rem;
  width: 35%;
  color: #fff;
  background-color: #ED0874;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: "Montserrat Regular";
  font-size: 1.5vh;
}

.create-salas-numbers-container {
  width: 100%;
}
.create-salas-numbers-container select {
  margin-right: 2%;
  width: 25%;
  height: 3vh;
}

#root > div > div.create-salas-container > div.create-salas-numbers-info > div.create-salas-numbers-container > input[type=text]:nth-child(2) {
  width: 15%;
  margin-right: 4%;
  height: 3vh;
  padding-left: 2%;
}

#root > div > div.create-salas-container > div.create-salas-numbers-info > div.create-salas-numbers-container > input[type=text]:nth-child(3) {
  width: 40%;
  height: 3vh;
  padding-left: 2%;
}

.whatsapp-checkcontainer {
  margin-top: 4%;
  display: flex;
}
.whatsapp-checkcontainer div {
  margin-right: 5%;
  font-size: 1.5vh;
  font-family: "Montserrat Regular";
}

.save-number-btn {
  position: relative;
  margin-top: 4%;
  margin-bottom: 4%;
  width: 40%;
  left: 50%;
  transform: translate(-50%, 0);
  color: #fff;
  background-color: #ED0874;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: "Montserrat Regular";
  font-size: 1.5vh;
  height: 4vh;
}

.saved-phone-container {
  margin-top: 4%;
  margin-bottom: 4%;
  position: relative;
}

.saved-phone-type {
  font-size: 1.5vh;
  font-family: "Montserrat Semibold";
  margin-bottom: 4%;
}

.saved-phone-number {
  font-size: 1.5vh;
  font-family: "Montserrat Regular";
  margin-bottom: 2%;
}

.saved-phone-whatsapp {
  font-size: 1.5vh;
  font-family: "Montserrat Regular";
  margin-bottom: 2%;
}

.salas-list-data-container {
  position: absolute;
  top: 10%;
  left: 3%;
  width: 95%;
}

.sala-individual-container {
  min-height: 4vh;
  margin-bottom: 2%;
  border: 0.1vw #616161 solid;
  border-radius: 10px;
}

.minified-info-modal {
  height: 5vh;
  position: relative;
  cursor: pointer;
}
.minified-info-modal img {
  height: 20%;
  position: absolute;
  right: 1%;
  top: 50%;
  transform: translate(0, -50%);
  transition: all 0.3s ease-in-out;
}

.uncollapsed-contact {
  transform: translate(0, -50%) rotate(-180deg) !important;
}

.minified-info-name {
  position: absolute;
  font-size: 1.6vh;
  font-family: "Montserrat Semibold";
  top: 50%;
  left: 1%;
  transform: translate(0, -50%);
}

.uncollapsed-contact-info {
  position: relative;
  left: 1%;
}
.uncollapsed-contact-info div {
  margin-bottom: 1%;
  font-family: "Montserrat Regular";
  font-size: 1.5vh;
}

.minified-delete-btn {
  cursor: pointer;
  position: absolute;
  height: 80%;
  width: 8%;
  right: 5%;
  top: 50%;
  transform: translate(0%, -50%);
  color: #fff;
  background-color: #ED0874;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: "Montserrat Regular";
  font-size: 1.5vh;
}

.minified-edit-btn {
  cursor: pointer;
  position: absolute;
  height: 80%;
  width: 8%;
  right: 14%;
  top: 50%;
  transform: translate(0%, -50%);
  color: #fff;
  background-color: #ED0874;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: "Montserrat Regular";
  font-size: 1.5vh;
}

.delete-number {
  position: absolute;
  width: 2vh;
  height: 2vh;
  top: 5%;
  right: 5%;
}

.contact-list-main-container {
  position: absolute;
  width: 48vw;
  height: 85%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  top: 12%;
  left: 20%;
  border: 0.5px solid #BEBEBE;
  border-radius: 8px;
}

.create-new-contact-sala-main-container {
  position: absolute;
  width: 28vw;
  height: 85%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  top: 12%;
  right: 2%;
  border: 0.5px solid #BEBEBE;
  border-radius: 8px;
}

.contact-list-main-container-title {
  position: relative;
  margin-top: 2rem;
  margin-left: 2rem;
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 1.125rem;
}

.create-new-contact-title {
  position: relative;
  margin-top: 2rem;
  margin-left: 2rem;
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 1.125rem;
}

.sala-list-data-container {
  position: relative;
  margin-top: 1rem;
  width: calc(100% - 4rem);
  left: 2rem;
}

.sala-individual-main-container {
  position: relative;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  width: 100%;
  height: auto;
  min-height: 3rem;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1294117647);
  border: 0.5px solid #BEBEBE;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.sala-individual-brief-container {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  height: 3rem;
}

.sala-individual-brief-name {
  margin-left: 0.875rem;
  margin-right: 0.875rem;
  color: #000;
  font-family: "Montserrat Semibold";
  font-size: 0.875rem;
}

.sala-indiivudal-brief-arrow {
  position: relative;
  width: 1rem;
  height: 1rem;
}
.sala-indiivudal-brief-arrow img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.sala-individual-brief-actions-container {
  position: absolute;
  right: 2%;
  display: flex;
}

.sala-individual-brief-individual-action {
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
}
.sala-individual-brief-individual-action img {
  max-height: 100%;
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  object-fit: contain;
}

.create-new-contact-create-container {
  position: relative;
  margin-top: 1rem;
  left: 2rem;
  width: calc(100% - 4rem);
  height: 82%;
  margin-bottom: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.create-new-contact-item {
  width: 100%;
  margin-bottom: 0.875rem;
}
.create-new-contact-item input {
  width: 100%;
  height: 1.875rem;
  box-sizing: border-box;
}

.create-new-item-title {
  position: relative;
  font-family: "Montserrat Semibold";
  color: #000;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
}

.create-new-item-title-alt-container {
  display: flex;
  margin-bottom: 0.5rem;
}

.create-new-item-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.create-new-item-input-container div {
  margin-right: 0.5rem;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  margin-left: 0.5rem;
}

.create-new-contact-item-alt {
  margin-bottom: 1rem;
}

.create-new-phone-btn {
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  height: 1.875rem;
  width: 9.25rem;
  cursor: pointer;
  color: #fff;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat Regular";
}

.input-container-short {
  width: 26%;
  height: 1.875rem;
}

.input-container-large {
  width: 35%;
  height: 1.875rem;
}

.create-cancel-contact-btn-contaienr {
  display: flex;
  width: calc(100% - 4rem);
  position: relative;
  left: 2rem;
  align-items: center;
  justify-content: center;
}

.hour-item-alt-container {
  font-family: "Montserrat Semibold";
  font-size: 0.875rem;
  width: 35%;
  margin-right: 2.4rem;
}

.time-item-alt-container {
  font-family: "Montserrat Semibold";
  font-size: 0.875rem;
  width: 35%;
}

.cancel-contact-btn {
  cursor: pointer;
  position: relative;
  width: 5.125rem;
  height: 2.5rem;
  background: #E0E0E0 0% 0% no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.create-contact-btn {
  cursor: pointer;
  position: relative;
  width: 5.125rem;
  height: 2.5rem;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
  color: #fff;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.create-new-items-phone-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.whatsapp-container-contact {
  display: flex;
  margin-left: 0.5rem;
  align-items: center;
}
.whatsapp-container-contact input {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  font-size: 0.875rem;
}
.whatsapp-container-contact div {
  width: 80%;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
}

.sala-individual-info-container {
  position: relative;
  left: 1rem;
}
.sala-individual-info-container div {
  margin-bottom: 1rem;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  word-wrap: break-word;
  width: 80%;
}

strong {
  font-family: "Montserrat Semibold";
}

.numbers-to-show-contact {
  position: relative;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  font-family: "Montserrat Regular";
  border: 1px solid #BEBEBE;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.close-numbers-array {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  transform: translate(0%, -50%);
}

.gallery-modal-properties-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(1, 17, 27, 0.7) 0% 0% no-repeat padding-box;
  z-index: 3;
}

.gallery-modal-properties-main-container {
  position: absolute;
  top: 52.5%;
  left: 50%;
  width: 60%;
  height: 40.75rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
  transform: translate(-50%, -50%);
}

.gallery-modal-properties-title {
  position: relative;
  margin-top: 3rem;
  left: 4rem;
  width: calc(100% - 8rem);
  color: #043250;
  font-family: "Montserrat Semibold";
  font-size: 1.125rem;
}

.gallery-modal-properties-text {
  position: relative;
  margin-top: 1rem;
  left: 4rem;
  width: calc(100% - 8rem);
  color: #000000;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.gallery-modal-attributes-container {
  position: relative;
  left: 4rem;
  width: calc(100% - 8rem);
  display: flex;
  align-items: center;
}

.gallery-modal-attributes-individual-container {
  margin-right: 1rem;
}

.gallery-modal-attributes-individual-title {
  position: relative;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  font-family: "Montserrat Semibold";
  color: #014976;
}

.gallery-modal-attributes-individual-select {
  width: 10rem;
  height: 1.875rem;
}
.gallery-modal-attributes-individual-select select {
  width: 100%;
  height: 100%;
}

.gallery-modal-container-galleries {
  position: relative;
  left: 4rem;
  margin-top: 1rem;
  width: calc(100% - 8rem);
  display: flex;
  height: 60%;
  align-items: center;
  justify-content: space-between;
}

.gallery-container-breadcrumbs-cards {
  position: relative;
  width: 49%;
  height: 100%;
  background: #FFF 0% 0% no-repeat padding-box;
  border: 0.5px solid #616161;
  border-radius: 5px;
}

.save-cancel-gallery-modal-properties {
  position: relative;
  margin-top: 1rem;
  left: 4rem;
  width: calc(100% - 8rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel-btn-gallery-modal {
  position: relative;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: 4.875rem;
  background: #E0E0E0 0% 0% no-repeat padding-box;
  border-radius: 5px;
  height: 2.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  cursor: pointer;
  color: #616161;
}

.save-btn-gallery-modal {
  position: relative;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: 8.125rem;
  background: #ED0874 0% 0% no-repeat padding-box;
  border-radius: 5px;
  height: 2.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
  cursor: pointer;
  color: #fff;
}

.breadcrumbs-container-gallery-modal-properties {
  position: relative;
  margin-top: 0.5rem;
  display: flex;
  left: 1rem;
  font-family: "Montserrat Regular";
  font-size: 0.875rem;
}

.bread-crumbs-container-text {
  margin-right: 1rem;
  cursor: pointer;
}

.gallery-container-breadcrumbs-title {
  position: relative;
  margin-top: 0.5rem;
  text-align: center;
  font-family: "Montserrat Regular";
  color: #8F8F8F;
  font-size: 0.875rem;
}

.gallery-modal-gallery-container {
  position: relative;
  left: 1rem;
  margin-top: 1rem;
  width: calc(100% - 2rem);
  display: grid;
  grid-template-columns: repeat(3, 2fr);
}

.gallery-modal-gallery-container-previews {
  position: relative;
  left: 1rem;
  margin-top: 1rem;
  width: calc(100% - 2rem);
  display: flex;
  flex-wrap: wrap;
  max-height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
}

.gallery-modal-gallery-container-individual {
  cursor: pointer;
  position: relative;
  display: flex;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 0.5px solid #616161;
  border-radius: 5px;
  height: 2.5rem;
  width: 8.125rem;
  display: flex;
  align-items: center;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
}

.gallery-modal-gallery-icon {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: 1.313rem;
  height: 1.313rem;
}
.gallery-modal-gallery-icon img {
  width: 100%;
  height: 100%;
}

.preview-image-container {
  position: relative;
  width: 8rem;
  height: 7rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 0.5px solid #616161;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 1rem;
}

.preview-iamge-gallery-container {
  width: 100%;
  height: 70%;
  border-radius: 5px;
}
.preview-iamge-gallery-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.preview-image-title {
  position: relative;
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
  text-align: center;
}

.preview-link-container {
  position: relative;
  width: 8rem;
  height: 2.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 0.5px solid #616161;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Montserrat Regular";
  font-size: 0.75rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-container-images-selected {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
  position: relative;
  left: 1rem;
  max-height: 85%;
  overflow-y: auto;
  overflow-x: hidden;
}

.gallery-modal-delete-btn {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  width: 1rem;
  height: 1rem;
  background: #ED0874 0% 0% no-repeat padding-box;
  cursor: pointer;
  border-radius: 2px;
}
.gallery-modal-delete-btn img {
  position: absolute;
  width: 60%;
  height: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 600px) and (max-width: 960px), (min-resolution: 192dpi) and (min-width: 600px) and (max-width: 960px) {
  .builders-card-grid {
    grid-template-columns: repeat(3, 2fr);
  }
  .builders-name {
    font-size: 1.5vw;
  }
  .new-builders-btn {
    font-size: 1.5vw;
  }
  .builders-projects {
    font-size: 1.2vw;
  }
  .builders-see-project {
    font-size: 1.2vw;
  }
  .breadcrumbs-builders {
    font-size: 1.5vw;
  }
  .nav-bar-container {
    max-height: 6% !important;
    padding: 3% !important;
  }
  .builders-container-opened {
    width: 65%;
    left: 35%;
  }
  .create-builder-container {
    min-width: 0px;
    width: 30%;
  }
  .create-title {
    font-size: 1.8vw;
  }
  .caption-logo-builder {
    font-size: 1.5vw;
  }
  .create-image-container {
    width: 12vw;
    height: 12vw;
  }
  .create-btn {
    width: 60%;
    font-size: 1.5vw;
  }
  .breadcrumbs-container {
    font-size: 1.5vw;
  }
  .project-name {
    font-size: 1.4vw;
  }
  .project-url-text {
    font-size: 1.2vw;
  }
  .MuiButtonBase-root {
    padding: 1% !important;
  }
  .project-see {
    font-size: 1.2vw;
  }
  .sidebar-header-title {
    font-size: 1.8vw;
  }
  .setup-general-container {
    width: 72%;
    left: 60%;
  }
  .modules-container {
    width: 72%;
    left: 60%;
  }
  .color-palette-container-main {
    width: 72%;
    left: 60%;
  }
  .sidebar-header-subtitle {
    font-size: 1.2vw;
  }
  .project-data-main-title-no-sub-menu {
    font-size: 1.3vw;
  }
  .sidebar-main {
    width: 19vw;
  }
  .title-submenu {
    font-size: 1.3vw;
    width: 70%;
  }
  .name-container {
    width: 60%;
    right: -10%;
  }
  .general-properties-container {
    width: 65%;
    left: 60%;
  }
  .submenu-btn div {
    font-size: 1.2vw;
  }
  .attributes-container {
    left: 60%;
  }
  .atributtes-table {
    width: 70%;
  }
  .disponibilidad-table-container {
    left: 20%;
  }
  .logo-name-title {
    font-size: 1.3vw;
  }
  .logo-format-subtitle {
    font-size: 1.2vw;
  }
  .logo-format-dubtitle {
    font-size: 1.2vw;
  }
  .logo-information {
    width: 70%;
  }
  .name-container {
    right: -20%;
  }
  .url-name-general-information {
    font-size: 1.3vw;
  }
  .modules-card {
    width: 90%;
  }
  .module-name {
    font-size: 1.2vw;
  }
  .color-palette-inputs-container {
    width: 90%;
  }
  .color-palette-individual-title {
    font-size: 1.2vw;
  }
  .color-palette-preview {
    width: 80%;
  }
  .color-palette-input {
    width: 70%;
  }
  .color-palette-previews-title {
    font-size: 1.6vw;
    width: 80%;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
  }
  .color-palette-individual-preview {
    width: 15%;
  }
  .color-palette-outline-container {
    width: 15%;
  }
  .color-palette-outline-btn {
    font-size: 1.2vw;
  }
  .color-palette-individual-text {
    font-size: 1.2vw;
  }
  .color-palette-text-states-container {
    width: 15%;
  }
  .color-palette-bilboards-container {
    width: 15%;
  }
  .svg-container {
    display: block;
    height: 70%;
  }
  .color-palette-amenities-billboard {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, 0);
  }
  .color-palette-lotes-billboard {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .palette-individual-text {
    font-size: 1.2vw;
  }
  .general-propertiess-info-title {
    font-size: 1.3vw;
  }
  .general-properties-info-subtitle {
    font-size: 1.2vw;
  }
  .general-properties-info-text {
    font-size: 1.2vw;
  }
  .input-icons-btn-general-properties {
    font-size: 1.2vw;
  }
  .general-properties-icon-selected-container {
    height: 6.5vw;
    width: 6.5vw;
  }
  .general-properties-attributes-text {
    font-size: 1.3vw;
  }
  .general-properties-imuebles-text {
    font-size: 1.3vw;
  }
  .general-properties-imuebles-btn {
    font-size: 1.1vw;
  }
  .general-properties-imuebles-view a {
    width: 80%;
  }
  .general-properties-attributes-view a {
    width: 45%;
  }
  .general-properties-attributes-btn {
    font-size: 1.1vw;
  }
  .properties-actions-options {
    width: 30%;
  }
  .info-roads-title-oval {
    font-size: 1.2vw;
  }
  .input-icons-btn {
    height: 40%;
  }
  .info-roads-text-oval {
    font-size: 1vw;
  }
  .information-logo-icon-oval {
    font-size: 1.2vw;
    top: 80%;
  }
  .info-roads-title {
    font-size: 1.2vw;
  }
  .info-roads-text {
    font-size: 1vw;
  }
  .general-roads-location-search-container div {
    font-size: 1.2vw;
  }
  .general-roads-location-search-container input {
    left: 30%;
    width: 40%;
  }
  .fijar-btn {
    width: 50%;
    left: 40%;
    font-size: 1.2vw;
  }
  .center-map {
    width: 30%;
    font-size: 1.2vw;
  }
  .lng-show-container {
    font-size: 1.2vw;
    left: 20%;
  }
  .lat-show-container {
    font-size: 1.2vw;
    right: 20%;
  }
  .lote-title {
    font-size: 1.2vw;
  }
  .lote-draw-btn {
    top: 40%;
    width: 45%;
    left: 40%;
  }
  .lote-save {
    top: 65%;
    width: 45%;
    left: 40%;
  }
  .gallery-modal-main {
    width: 80%;
  }
  .gallery-cards-container-modal {
    grid-template-columns: repeat(3, 2fr);
  }
  .gallery-modal-cards-container {
    overflow-y: auto;
  }
  .individual-gallery-card-modal {
    height: 12vh;
    margin-bottom: 1vh;
    margin-top: 1vh;
  }
  .individual-gallery-name-modal {
    font-size: 1vw;
  }
  .gallery-modal-subtitle {
    font-size: 1.2vw;
  }
  .gallery-modal-cancel {
    font-size: 1.1vw;
  }
  .gallery-modal-asign {
    font-size: 1.1vw;
  }
  .gallery-image-card-modal {
    width: 9vw;
  }
  .gallery-image-name-modal {
    font-size: 0.9vw;
  }
  .lote-draw-btn {
    font-size: 1vw;
  }
  .lote-save {
    font-size: 1vw;
  }
  .roads-info-title {
    font-size: 1.2vw;
  }
  .roads-info-subtitle {
    font-size: 1vw;
  }
  .rodas-info-text {
    font-size: 1vw;
  }
  #root > div > div.roads-create-container > div.roads-create-icon-container > div.roads-create-info-text > div.input-icons-btn {
    font-size: 1.2vw;
    width: 60%;
  }
  .roads-create-title {
    font-size: 1.2vw;
  }
  .roads-create-title-main {
    font-size: 1.2vw;
  }
  .create-roads-name-title {
    font-size: 1.2vw;
  }
  .create-roads-color-title {
    font-size: 1.2vw;
  }
  .road-save-btn {
    font-size: 1vw;
  }
  .map-draw-btn {
    font-size: 1vw;
  }
  .list-roads-name-container {
    width: 40%;
  }
  .list-roads-color {
    width: 16%;
  }
  .delete-road {
    width: 8%;
  }
  .header-poi-title {
    font-size: 1.4vw;
  }
  .header-poi-first-text {
    font-size: 1.2vw;
  }
  .header-poi-second-text {
    font-size: 1.2vw;
  }
  .poi-category-individual {
    width: 45%;
  }
  .poi-category-individual-name {
    font-size: 1.2vw;
  }
  .poi-icon-select-title {
    font-size: 1.4vw;
  }
  .pois-info-title {
    font-size: 1.3vw;
  }
  .pois-info-subtitle {
    font-size: 1.1vw;
  }
  .pois-info-text {
    font-size: 1.1vw;
  }
  .input-icons-btn-poi {
    width: 80%;
    font-size: 1vw;
  }
  .create-poi-title {
    font-size: 1.4vw;
  }
  .crate-poi-input-container input {
    width: 50%;
  }
  .poi-create-btn {
    font-size: 1vw;
  }
  .pois-category-header-create-btn {
    width: 20%;
  }
  .pois-category-fijar-btn {
    width: 50%;
    font-size: 1.2vw;
  }
  .pois-category-current-lng {
    width: 20%;
    left: 20%;
    font-size: 1.2vw;
    text-align: center;
  }
  .pois-category-current-lat {
    width: 20%;
    right: 20%;
    font-size: 1.2vw;
    text-align: center;
  }
  .pois-ccategory-list-title {
    font-size: 1.2vw;
  }
  .poi-individual-name {
    font-size: 1.1vw;
  }
  .logo-name-title-amenities {
    font-size: 1.2vw;
  }
  .amenitie-individual-name {
    font-size: 1.2vw;
  }
  .default-amenities-title {
    font-size: 1.2vw;
  }
  .default-amenities-info-title {
    font-size: 1vw;
  }
  .default-amenities-info-text {
    font-size: 1vw;
  }
  .default-amenities-info-title {
    font-size: 1.2vw;
  }
  .default-amenities-select {
    width: 100%;
    font-size: 1vw;
  }
  .default-amenities-select-text {
    font-size: 1.2vw;
  }
  .default-amenities-save {
    width: 40%;
    font-size: 1.1vw;
  }
  .create-amenitie-new {
    font-size: 1.2vw;
  }
  .logo-format-dubtitle-amenities-new {
    font-size: 1vw;
  }
  .logo-name-title-amenities-new {
    font-size: 1.2vw;
  }
  .input-amenities-icon {
    top: 120%;
    height: 30%;
    left: 0%;
    width: 100%;
    font-size: 1.1vw;
  }
  .select-icon {
    height: 30%;
    width: 100%;
    top: 80%;
    font-size: 1.1vw;
  }
  .create-new-name {
    top: 110%;
  }
  .create-new-save-btn {
    width: 40%;
    font-size: 1.1vw;
  }
  .gallery-cards-container {
    grid-template-columns: repeat(5, 2fr);
  }
  .individual-gallery-name {
    font-size: 1.2vw;
  }
  .individual-gallery-card {
    height: 14vh;
  }
  .gallery-preview-main {
    grid-template-columns: repeat(4, 2fr);
  }
  .gallery-image-name {
    font-size: 1vw;
  }
  .gallery-upload-btn {
    font-size: 1vw;
    width: 15%;
  }
  .icons-container {
    width: 90%;
  }
  .icon-folder-container {
    width: 80%;
  }
  .icons-name {
    font-size: 1.4vw;
  }
  .icons-text {
    font-size: 1.2vw;
  }
  .icon-folder-name {
    font-size: 1.2vw;
  }
  .create-icons {
    font-size: 1.2vw;
  }
  .cancel-btn-icons {
    font-size: 1.2vw;
  }
  .asign-btn-icons {
    font-size: 1.2vw;
  }
  .icons-upload-container {
    width: 90%;
  }
  .upload-icon-info-main {
    font-size: 1.4vw;
  }
  .upload-icon-image {
    width: 10vw;
    height: 10vw;
  }
  .upload-icon-info {
    left: 30%;
  }
  .upload-icon-info-format {
    font-size: 1.2vw;
  }
  .save-icon-btn {
    font-size: 1.2vw;
  }
  .upload-name-main {
    font-size: 1.2vw;
  }
  .upload-icon-name input {
    top: 40%;
  }
  #root > div > div.icons-main-container.fade-in > div > div.upload-icon-main > div.upload-icon-container > div.upload-icon-info > div.input-icons-btn {
    height: 50%;
  }
  .icon-individual-container label {
    font-size: 1.2vw;
  }
  .upload-spin-name {
    font-size: 1.2vw;
  }
  .upload-spin-type {
    font-size: 1.2vw;
  }
  .upload-continer-type select {
    width: 80%;
  }
  .upload-spin-container {
    width: 50%;
  }
  .upload-map-container {
    left: 55%;
    width: 50%;
  }
  .upload-spin-modal-title {
    font-size: 1.3vw;
  }
  .upload-map-name {
    font-size: 1.3vw;
  }
  .upload-map-format {
    font-size: 1.1vw;
    top: 38%;
  }
  .upload-spin-modal-inst {
    font-size: 1.1vw;
  }
  .upload-spin-modal-format {
    font-size: 1.1vw;
  }
  .upload-spin-btn {
    top: 70%;
    width: 40%;
    font-size: 1vw;
  }
  .upload-map-btn {
    top: 70%;
    width: 40%;
    font-size: 1vw;
  }
  .pricing-btn-filter-container div {
    font-size: 1.2vw;
  }
  .select-filters {
    font-size: 1.2vw;
  }
  .activate-dispo-title {
    font-size: 1.1vw;
  }
  .activate-dispo-container {
    width: 30%;
  }
  .activate-dispo-state-name {
    font-size: 1vw;
  }
  .dispo-actaulizar-btn {
    width: 100%;
    padding: 5%;
    font-size: 1.4vw;
  }
  .quotation-icon-holder {
    width: 7.5vw;
    height: 7.5vw;
  }
  .quotation-icon-container {
    width: 80%;
  }
  .quotation-icon-text-container {
    left: 30%;
  }
  .quotation-icon-container-title {
    font-size: 1.2vw;
  }
  .quotatuib-icon-text {
    font-size: 1.2vw;
  }
  .quotation-icon-btn {
    font-size: 1.2vw;
    width: 50%;
  }
  .mailing-quotation-text {
    font-size: 1vw;
  }
  .mailing-quotation-save-btn {
    width: 25%;
    font-size: 1.2vw;
  }
  .mailing-quotatuion-name-text {
    font-size: 1.2vw;
  }
  .mailing-quotatuion-name-desc {
    font-size: 1.2vw;
  }
  .quotation-mailing-container {
    width: 80%;
  }
  .quotation-mailing-holder {
    width: 7.5vw;
    height: 7.5vw;
  }
  .quotation-mailing-text-container {
    left: 30%;
  }
  .quotation-mailing-title {
    font-size: 1.2vw;
  }
  .quotatuib-mailing-text {
    font-size: 1vw;
  }
  .quotation-mailing-btn {
    width: 50%;
    font-size: 1.2vw;
  }
  .asoleacion-list-text {
    font-size: 1vw;
  }
  .asoleacion-icono-preview {
    width: 6vw;
    height: 6vw;
  }
  .asoleacion-icono-selection-text-container {
    left: 30%;
  }
  .asoleacion-icono-selection-title {
    font-size: 1.2vw;
  }
  .asolaecion-icono-selection-text {
    font-size: 1vw;
  }
  .input-asoleacion-icon-btn {
    width: 70%;
    font-size: 1vw;
  }
  .asolacion-icono-document-preview {
    width: 6vw;
    height: 6vw;
  }
  .asolecion-documentos-selection-text-container {
    left: 30%;
  }
  .asoleacion-document-selection-title {
    font-size: 1.2vw;
  }
  .asoleacion-document-selection-text {
    font-size: 1vw;
  }
  .input-asoleacion-document-btn {
    width: 80%;
    font-size: 1vw;
  }
  .asoleacion-upload-name-text {
    font-size: 1.2vw;
  }
  .asoleacion-upload-desc-text {
    font-size: 1.2vw;
  }
  .asoleacion-upload-icon-preview-container {
    height: 30%;
  }
  .asoleacion-upload-save-btn {
    top: 90%;
    width: 45%;
    font-size: 1.2vw;
  }
  .asoleacion-upload-text {
    font-size: 1vw;
  }
  .asoelacion-upload-title {
    font-size: 1.2vw;
  }
  .asoleacion-upload-btn {
    font-size: 1vw;
    width: 80%;
    height: 30%;
  }
  .princing-quotation-main-container {
    overflow-x: auto !important;
  }
  .pricing-table-container {
    overflow-y: auto !important;
  }
  .MuiPaper-elevation {
    overflow: auto !important;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) {
  .builders-card-grid {
    grid-template-columns: repeat(3, 2fr);
  }
  .builders-name {
    font-size: 1.5vw;
  }
  .new-builders-btn {
    font-size: 1.5vw;
  }
  .builders-projects {
    font-size: 1.2vw;
  }
  .builders-see-project {
    font-size: 1.2vw;
  }
  .breadcrumbs-builders {
    font-size: 1.5vw;
  }
  .nav-bar-container {
    max-height: 6% !important;
    padding: 3% !important;
  }
  .builders-container-opened {
    width: 65%;
    left: 35%;
  }
  .create-builder-container {
    min-width: 0px;
    width: 30%;
  }
  .create-title {
    font-size: 1.8vw;
  }
  .caption-logo-builder {
    font-size: 1.5vw;
  }
  .create-image-container {
    width: 12vw;
    height: 12vw;
  }
  .create-btn {
    width: 60%;
    font-size: 1.5vw;
  }
  .breadcrumbs-container {
    font-size: 1.5vw;
  }
  .project-name {
    font-size: 1.4vw;
  }
  .project-url-text {
    font-size: 1.2vw;
  }
  .MuiButtonBase-root {
    padding: 1% !important;
  }
  .project-see {
    font-size: 1.2vw;
  }
  .sidebar-header-title {
    font-size: 1.8vw;
  }
  .setup-general-container {
    width: 72%;
    left: 60%;
  }
  .modules-container {
    width: 72%;
    left: 60%;
  }
  .color-palette-container-main {
    width: 72%;
    left: 60%;
  }
  .sidebar-header-subtitle {
    font-size: 1.2vw;
  }
  .project-data-main-title-no-sub-menu {
    font-size: 1.3vw;
  }
  .sidebar-main {
    width: 19vw;
  }
  .title-submenu {
    font-size: 1.3vw;
    width: 70%;
  }
  .name-container {
    width: 60%;
    right: -10%;
  }
  .general-properties-container {
    width: 65%;
    left: 60%;
  }
  .submenu-btn div {
    font-size: 1.2vw;
  }
  .attributes-container {
    left: 60%;
  }
  .atributtes-table {
    width: 70%;
  }
  .disponibilidad-table-container {
    left: 20%;
  }
  .logo-name-title {
    font-size: 1.3vw;
  }
  .logo-format-subtitle {
    font-size: 1.2vw;
  }
  .logo-format-dubtitle {
    font-size: 1.2vw;
  }
  .logo-information {
    width: 70%;
  }
  .name-container {
    right: -20%;
  }
  .url-name-general-information {
    font-size: 1.3vw;
  }
  .modules-card {
    width: 90%;
  }
  .module-name {
    font-size: 1.2vw;
  }
  .color-palette-inputs-container {
    width: 90%;
  }
  .color-palette-individual-title {
    font-size: 1.2vw;
  }
  .color-palette-preview {
    width: 80%;
  }
  .color-palette-input {
    width: 70%;
  }
  .color-palette-previews-title {
    font-size: 1.6vw;
    width: 80%;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
  }
  .color-palette-individual-preview {
    width: 15%;
  }
  .color-palette-outline-container {
    width: 15%;
  }
  .color-palette-outline-btn {
    font-size: 1.2vw;
  }
  .color-palette-individual-text {
    font-size: 1.2vw;
  }
  .color-palette-text-states-container {
    width: 15%;
  }
  .color-palette-bilboards-container {
    width: 15%;
  }
  .svg-container {
    display: block;
    height: 70%;
  }
  .color-palette-amenities-billboard {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, 0);
  }
  .color-palette-lotes-billboard {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .palette-individual-text {
    font-size: 1.2vw;
  }
  .general-propertiess-info-title {
    font-size: 1.3vw;
  }
  .general-properties-info-subtitle {
    font-size: 1.2vw;
  }
  .general-properties-info-text {
    font-size: 1.2vw;
  }
  .input-icons-btn-general-properties {
    font-size: 1.2vw;
  }
  .general-properties-icon-selected-container {
    height: 6.5vw;
    width: 6.5vw;
  }
  .general-properties-attributes-text {
    font-size: 1.3vw;
  }
  .general-properties-imuebles-text {
    font-size: 1.3vw;
  }
  .general-properties-imuebles-btn {
    font-size: 1.1vw;
  }
  .general-properties-imuebles-view a {
    width: 80%;
  }
  .general-properties-attributes-view a {
    width: 45%;
  }
  .general-properties-attributes-btn {
    font-size: 1.1vw;
  }
  .properties-actions-options {
    width: 30%;
  }
  .info-roads-title-oval {
    font-size: 1.2vw;
  }
  .input-icons-btn {
    height: 40%;
  }
  .info-roads-text-oval {
    font-size: 1vw;
  }
  .information-logo-icon-oval {
    font-size: 1.2vw;
    top: 80%;
  }
  .info-roads-title {
    font-size: 1.2vw;
  }
  .info-roads-text {
    font-size: 1vw;
  }
  .general-roads-location-search-container div {
    font-size: 1.2vw;
  }
  .general-roads-location-search-container input {
    left: 30%;
    width: 40%;
  }
  .fijar-btn {
    width: 50%;
    left: 40%;
    font-size: 1.2vw;
  }
  .center-map {
    width: 30%;
    font-size: 1.2vw;
  }
  .lng-show-container {
    font-size: 1.2vw;
    left: 20%;
  }
  .lat-show-container {
    font-size: 1.2vw;
    right: 20%;
  }
  .lote-title {
    font-size: 1.2vw;
  }
  .lote-draw-btn {
    top: 40%;
    width: 45%;
    left: 40%;
  }
  .lote-save {
    top: 65%;
    width: 45%;
    left: 40%;
  }
  .gallery-modal-main {
    width: 80%;
  }
  .gallery-cards-container-modal {
    grid-template-columns: repeat(3, 2fr);
  }
  .gallery-modal-cards-container {
    overflow-y: auto;
  }
  .individual-gallery-card-modal {
    height: 12vh;
    margin-bottom: 1vh;
    margin-top: 1vh;
  }
  .individual-gallery-name-modal {
    font-size: 1vw;
  }
  .gallery-modal-subtitle {
    font-size: 1.2vw;
  }
  .gallery-modal-cancel {
    font-size: 1.1vw;
  }
  .gallery-modal-asign {
    font-size: 1.1vw;
  }
  .gallery-image-card-modal {
    width: 9vw;
  }
  .gallery-image-name-modal {
    font-size: 0.9vw;
  }
  .lote-draw-btn {
    font-size: 1vw;
  }
  .lote-save {
    font-size: 1vw;
  }
  .roads-info-title {
    font-size: 1.2vw;
  }
  .roads-info-subtitle {
    font-size: 1vw;
  }
  .rodas-info-text {
    font-size: 1vw;
  }
  #root > div > div.roads-create-container > div.roads-create-icon-container > div.roads-create-info-text > div.input-icons-btn {
    font-size: 1.2vw;
    width: 60%;
  }
  .roads-create-title {
    font-size: 1.2vw;
  }
  .roads-create-title-main {
    font-size: 1.2vw;
  }
  .create-roads-name-title {
    font-size: 1.2vw;
  }
  .create-roads-color-title {
    font-size: 1.2vw;
  }
  .road-save-btn {
    font-size: 1vw;
  }
  .map-draw-btn {
    font-size: 1vw;
  }
  .list-roads-name-container {
    width: 40%;
  }
  .list-roads-color {
    width: 16%;
  }
  .delete-road {
    width: 8%;
  }
  .header-poi-title {
    font-size: 1.4vw;
  }
  .header-poi-first-text {
    font-size: 1.2vw;
  }
  .header-poi-second-text {
    font-size: 1.2vw;
  }
  .poi-category-individual {
    width: 45%;
  }
  .poi-category-individual-name {
    font-size: 1.2vw;
  }
  .poi-icon-select-title {
    font-size: 1.4vw;
  }
  .pois-info-title {
    font-size: 1.3vw;
  }
  .pois-info-subtitle {
    font-size: 1.1vw;
  }
  .pois-info-text {
    font-size: 1.1vw;
  }
  .input-icons-btn-poi {
    width: 80%;
    font-size: 1vw;
  }
  .create-poi-title {
    font-size: 1.4vw;
  }
  .crate-poi-input-container input {
    width: 50%;
  }
  .poi-create-btn {
    font-size: 1vw;
  }
  .pois-category-header-create-btn {
    width: 20%;
  }
  .pois-category-fijar-btn {
    width: 50%;
    font-size: 1.2vw;
  }
  .pois-category-current-lng {
    width: 20%;
    left: 20%;
    font-size: 1.2vw;
    text-align: center;
  }
  .pois-category-current-lat {
    width: 20%;
    right: 20%;
    font-size: 1.2vw;
    text-align: center;
  }
  .pois-ccategory-list-title {
    font-size: 1.2vw;
  }
  .poi-individual-name {
    font-size: 1.1vw;
  }
  .logo-name-title-amenities {
    font-size: 1.2vw;
  }
  .amenitie-individual-name {
    font-size: 1.2vw;
  }
  .default-amenities-title {
    font-size: 1.2vw;
  }
  .default-amenities-info-title {
    font-size: 1vw;
  }
  .default-amenities-info-text {
    font-size: 1vw;
  }
  .default-amenities-info-title {
    font-size: 1.2vw;
  }
  .default-amenities-select {
    width: 100%;
    font-size: 1vw;
  }
  .default-amenities-select-text {
    font-size: 1.2vw;
  }
  .default-amenities-save {
    width: 40%;
    font-size: 1.1vw;
  }
  .create-amenitie-new {
    font-size: 1.2vw;
  }
  .logo-format-dubtitle-amenities-new {
    font-size: 1vw;
  }
  .logo-name-title-amenities-new {
    font-size: 1.2vw;
  }
  .input-amenities-icon {
    top: 120%;
    height: 30%;
    left: 0%;
    width: 100%;
    font-size: 1.1vw;
  }
  .select-icon {
    height: 30%;
    width: 100%;
    top: 80%;
    font-size: 1.1vw;
  }
  .create-new-name {
    top: 110%;
  }
  .create-new-save-btn {
    width: 40%;
    font-size: 1.1vw;
  }
  .gallery-cards-container {
    grid-template-columns: repeat(5, 2fr);
  }
  .individual-gallery-name {
    font-size: 1.2vw;
  }
  .individual-gallery-card {
    height: 14vh;
  }
  .gallery-preview-main {
    grid-template-columns: repeat(4, 2fr);
  }
  .gallery-image-name {
    font-size: 1vw;
  }
  .gallery-upload-btn {
    font-size: 1vw;
    width: 15%;
  }
  .icons-container {
    width: 90%;
  }
  .icon-folder-container {
    width: 80%;
  }
  .icons-name {
    font-size: 1.4vw;
  }
  .icons-text {
    font-size: 1.2vw;
  }
  .icon-folder-name {
    font-size: 1.2vw;
  }
  .create-icons {
    font-size: 1.2vw;
  }
  .cancel-btn-icons {
    font-size: 1.2vw;
  }
  .asign-btn-icons {
    font-size: 1.2vw;
  }
  .icons-upload-container {
    width: 90%;
  }
  .upload-icon-info-main {
    font-size: 1.4vw;
  }
  .upload-icon-image {
    width: 10vw;
    height: 10vw;
  }
  .upload-icon-info {
    left: 30%;
  }
  .upload-icon-info-format {
    font-size: 1.2vw;
  }
  .save-icon-btn {
    font-size: 1.2vw;
  }
  .upload-name-main {
    font-size: 1.2vw;
  }
  .upload-icon-name input {
    top: 40%;
  }
  #root > div > div.icons-main-container.fade-in > div > div.upload-icon-main > div.upload-icon-container > div.upload-icon-info > div.input-icons-btn {
    height: 50%;
  }
  .icon-individual-container label {
    font-size: 1.2vw;
  }
  .upload-spin-name {
    font-size: 1.2vw;
  }
  .upload-spin-type {
    font-size: 1.2vw;
  }
  .upload-continer-type select {
    width: 80%;
  }
  .upload-spin-container {
    width: 50%;
  }
  .upload-map-container {
    left: 55%;
    width: 50%;
  }
  .upload-spin-modal-title {
    font-size: 1.3vw;
  }
  .upload-map-name {
    font-size: 1.3vw;
  }
  .upload-map-format {
    font-size: 1.1vw;
    top: 38%;
  }
  .upload-spin-modal-inst {
    font-size: 1.1vw;
  }
  .upload-spin-modal-format {
    font-size: 1.1vw;
  }
  .upload-spin-btn {
    top: 70%;
    width: 40%;
    font-size: 1vw;
  }
  .upload-map-btn {
    top: 70%;
    width: 40%;
    font-size: 1vw;
  }
  .pricing-btn-filter-container div {
    font-size: 1.2vw;
  }
  .select-filters {
    font-size: 1.2vw;
  }
  .activate-dispo-title {
    font-size: 1.1vw;
  }
  .activate-dispo-container {
    width: 30%;
  }
  .activate-dispo-state-name {
    font-size: 1vw;
  }
  .dispo-actaulizar-btn {
    width: 100%;
    padding: 5%;
    font-size: 1.4vw;
  }
  .quotation-icon-holder {
    width: 7.5vw;
    height: 7.5vw;
  }
  .quotation-icon-container {
    width: 80%;
  }
  .quotation-icon-text-container {
    left: 30%;
  }
  .quotation-icon-container-title {
    font-size: 1.2vw;
  }
  .quotatuib-icon-text {
    font-size: 1.2vw;
  }
  .quotation-icon-btn {
    font-size: 1.2vw;
    width: 50%;
  }
  .mailing-quotation-text {
    font-size: 1vw;
  }
  .mailing-quotation-save-btn {
    width: 25%;
    font-size: 1.2vw;
  }
  .mailing-quotatuion-name-text {
    font-size: 1.2vw;
  }
  .mailing-quotatuion-name-desc {
    font-size: 1.2vw;
  }
  .quotation-mailing-container {
    width: 80%;
  }
  .quotation-mailing-holder {
    width: 7.5vw;
    height: 7.5vw;
  }
  .quotation-mailing-text-container {
    left: 30%;
  }
  .quotation-mailing-title {
    font-size: 1.2vw;
  }
  .quotatuib-mailing-text {
    font-size: 1vw;
  }
  .quotation-mailing-btn {
    width: 50%;
    font-size: 1.2vw;
  }
  .asoleacion-list-text {
    font-size: 1vw;
  }
  .asoleacion-icono-preview {
    width: 6vw;
    height: 6vw;
  }
  .asoleacion-icono-selection-text-container {
    left: 30%;
  }
  .asoleacion-icono-selection-title {
    font-size: 1.2vw;
  }
  .asolaecion-icono-selection-text {
    font-size: 1vw;
  }
  .input-asoleacion-icon-btn {
    width: 70%;
    font-size: 1vw;
  }
  .asolacion-icono-document-preview {
    width: 6vw;
    height: 6vw;
  }
  .asolecion-documentos-selection-text-container {
    left: 30%;
  }
  .asoleacion-document-selection-title {
    font-size: 1.2vw;
  }
  .asoleacion-document-selection-text {
    font-size: 1vw;
  }
  .input-asoleacion-document-btn {
    width: 80%;
    font-size: 1vw;
  }
  .asoleacion-upload-name-text {
    font-size: 1.2vw;
  }
  .asoleacion-upload-desc-text {
    font-size: 1.2vw;
  }
  .asoleacion-upload-icon-preview-container {
    height: 30%;
  }
  .asoleacion-upload-save-btn {
    top: 90%;
    width: 45%;
    font-size: 1.2vw;
  }
  .asoleacion-upload-text {
    font-size: 1vw;
  }
  .asoelacion-upload-title {
    font-size: 1.2vw;
  }
  .asoleacion-upload-btn {
    font-size: 1vw;
    width: 80%;
    height: 30%;
  }
  .princing-quotation-main-container {
    overflow-x: auto !important;
  }
  .pricing-table-container {
    overflow-y: auto !important;
  }
  .MuiPaper-elevation {
    overflow: auto !important;
  }
}

