@import ./../../variables.style
    
.roads-create-container
    position: absolute
    top: 41.5%
    right: 0.5%
    width: 24.5%
    height: 57.5%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px
    transform: translate(0%,0%)

.roads-create-title
    position: relative
    top: 3%
    left: 3%
    color: #043250
    font-family: $font-family-semibold
    font-size: 1rem