@import ./../../variables.style

.pois-category-list-container
    position: relative
    top: 0%
    width: 90%
    height: 75%
    left: 50%
    transform: translate(-50%,0)
    font-family: $font-family-semibold
    font-size: 0.8vw
    color: #043250

.pois-category-list
    position: relative
    top: 0%
    width: 100%
    height: 95%
    margin-top: 1rem
    overflow-y: auto

.poi-individual-container
    position: relative
    height: 7%
    width: 100%
    border: 1px solid #616161
    margin-bottom: 3%
    border-radius: 10px

.poi-individual-name
    position: absolute
    top: 50%
    left: 3%
    transform: translate(0,-50%)


