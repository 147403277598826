@import ./../../variables.style

.validate-spins-container-main
    position: absolute
    top: 40%
    left: 20%
    width: 58%
    height: 58%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px
    img
        position: absolute
        top: 50%
        left: 50%
        max-width: 20%
        min-width: 20%
        max-height: 20%
        min-height: 20%
        object-fit: contain
        transform: translate(-50%,-50%)

.validate-spins-title
    position: absolute
    top: 30%
    left: 50%
    font-family: $font-family-regular
    font-size: 0.9vw
    color: #000
    transform: translate(-50%,-50%)

.validate-spins-text
    position: absolute
    top: 70%
    left: 50%
    font-family: $font-family-regular
    font-size: 0.9vw
    color: #000
    transform: translate(-50%,-50%)