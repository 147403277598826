@import ./../../variables.style

.coordinates-main-container
  position: absolute
  width: 38vw
  height: 85%
  background: #FFFFFF 0% 0% no-repeat padding-box
  top: 12%
  right: 2%
  border: 0.5px solid #BEBEBE
  border-radius: 8px

.coordinates-main-title
  color: #043250
  font-family: $font-family-semibold
  position: relative
  left: 3%
  margin-top: 2rem
  margin-bottom: 1rem
  font-size: 1.125rem

.coordinates-main-text
  color: #616161
  font-family: $font-family-regular
  font-size: 0.875rem
  width: calc( 100% - 2rem )
  left: 3%
  position: relative
  margin-bottom: 1rem

.coordinates-category-container
  height: 80%
  width: 94%
  position: absolute
  left: 50%
  transform: translate(-50%,0)
  overflow-y: auto
  overflow-x: hidden

.coordinates-individual-category
  position: relative
  width: 100%
  min-height: 3.75rem
  height: auto
  background: #FFFFFF 0% 0% no-repeat padding-box
  border-radius: 5px
  border: 0.5px solid #8F8F8F
  margin-bottom: 0.5rem
  box-sizing: border-box

.coordiantes-name-icon-container
  cursor: pointer
  position: relative
  height: 3.75rem
  display: flex
  align-items: center
  img
    max-height: 1rem
    min-height: 1rem
    min-width: 1rem
    max-width: 1rem
    object-fit: contain

.coordinates-closed-icon
  transform: rotate(-90deg)

.coordinates-individual-name
  font-size: 1rem
  margin-left: 1rem
  margin-right: 1rem
  font-family: $font-family-semibold

.coordinates-files-container
  position: relative
  left: 1rem
  width: calc( 100% - 2rem )

.coordinate-file-individual-container
  border: 0.5px solid #616161
  border-radius: 5px
  height: 3.625rem
  margin-bottom: 0.5rem
  display: flex
  align-items: center
  
.coordinate-file-input-container
  margin-left: 1rem
  margin-right: 1rem
  input
    width: 1.5rem
    height: 1.5rem
    accent-color: #ED0874

.coordinate-file-individual-name
  font-family: $font-family-semibold
  font-size: 0.875rem
  margin-bottom: 0.2rem
  margin-top: 0.2rem

.coordinate-file-individual-date
  font-family: $font-family-regular
  font-size: 0.875rem
  margin-bottom: 0.2rem
  margin-top: 0.2rem
  color: #616161

.script-name-spin-container
  position: relative
  margin-bottom: 1rem
  margin-top: 1rem
  display: flex
  align-items: center

.coordinates-group-name
  position: relative
  font-size: 0.875rem
  color: #000000
  font-family: $font-family-semibold

.asociate-spin-coordinates
  position: absolute
  display: flex
  align-items: center
  right: 5%
  div
    position: relative
    font-size: 0.875rem
    color: #000000
    font-family: $font-family-semibold
    margin-right: 1rem

.asociate-spin-selector
  height: 1.875rem
  width: 18.75rem

.show-more-coordinates
  font-size: 1rem
  font-family: $font-family-semibold
  margin-top: 1rem
  margin-bottom: 1.5rem
  cursor: pointer
  text-align: center
  color: #ED0874