@import ./../../variables.style

.project-name-edit-container
    position: absolute
    top: 10%
    width: 90%
    height: 40%
    left: 50%
    transform: translate(-50%,0)

.project-name
    position: absolute
    width: 55%
    min-width: 55%
    max-width: 55%
    height: 90%
    top: 50%
    left: 0
    display: flex
    word-break: break-all
    align-items: center
    transform: translate(0,-50%)
    font-size: 0.8vw
    font-family: $font-family-semibold

.project-url-see-container
    position: absolute
    top: 50%
    left: 50%
    height: 50%
    width: 90%
    transform: translate(-50%,0)

.project-divider
    position: absolute
    top: 50%
    left: 55%
    transform: translate(0%,-50%)
    width: 2px
    height: 90%
    background-color: #BEBEBE

.project-options-container
    position: absolute
    width: 30%
    right: 10%
    display: flex
    top: 50%
    transform: translate(0,-50%)

.project-url-btn
    cursor: pointer
    position: absolute
    top: 50%
    width: 55%
    height: 40%
    transform: translate(0,-50%)
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #ED0874
    border-radius: 5px
    img
        position: absolute
        top: 50%
        left: 5%
        transform: translate(0,-50%)
        max-height: 70%
        width: 12%

.project-url-text
    position: absolute
    top: 50%
    right: 10%
    transform: translate(0,-50%)
    font-size: 0.8vw
    font-family: $font-family-regular

.project-see
    cursor: pointer
    position: absolute
    top: 50%
    right: 0%
    transform: translate(0,-50%)
    width: 30%
    height: 40%
    background-color: #ED0874
    border: 1px solid #ED0874
    color: #fff
    font-family: $font-family-semibold
    display: flex
    justify-content: center
    align-items: center
    font-size: 0.8vw
    border-radius: 7px

#root > div > div.builders-container > div.builders-card-grid > div:nth-child(1) > div.builders-info-container > div.project-name-edit-container > div.project-options-container > button:nth-child(1)
    padding: 20% !important

#root > div > div.builders-container > div.builders-card-grid > div:nth-child(1) > div.builders-info-container > div.project-name-edit-container > div.project-options-container > button:nth-child(2)
    padding: 20% !important