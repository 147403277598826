@import ./../../variables.style

.bread-crumbs-container
    position: absolute
    top: 3%
    width: 90%
    height: 5%
    left: 50%
    transform: translate(-50%,0)
    display: flex
    align-items: center

.individual-bread-crumb
    font-size: 1.2rem
    font-family: $font-family-regular
    color: #043250
    margin-right: 0.6vw

.individual-bread-crumb-current
    font-size: 1.2rem
    font-family: $font-family-semibold
    color: #043250
    margin-right: 0.6vw