@import ./../../variables.style

.general-roads-icon-container
    position: relative
    top: 2%
    width: 95%
    left: 2.5%
    height: 10%

.general-roads-icon-name
    position: relative
    font-family: $font-family-regular
    color: #043250
    font-size: 0.9vw

.icon-roads
    position: absolute
    top: 20%
    width: 4.5vw
    height: 4.5vw
    background: #BEBEBE 0% 0% no-repeat padding-box
    border-radius: 5px
    img
        position: absolute
        top: 50%
        left: 50%
        max-width: 80%
        min-width: 80%
        max-height: 80%
        min-height: 80%
        object-fit: contain
        transform: translate(-50%,-50%)

.icon-text-info-roads
    position: absolute
    left: 25%
    top: 20%

.info-roads-title
    position: relative
    margin-bottom: 3%
    color: #000000
    font-family: $font-family-semibold
    font-size: 0.8vw

.info-roads-text
    position: relative
    margin-bottom: 3%
    color: #939598
    font-family: $font-family-regular
    font-size: 0.75rem

.general-roads-icon-selector
    position: absolute
    left: 0%
    width: 50%
    top: 0%
    height: 100%

.general-roads-icon-selector-oval
    position: absolute
    left: 55%
    width: 60%
    top: 0%
    height: 100%

.icon-roads-oval
    position: absolute
    top: 20%
    width: 4.5vw
    height: 4.5vw
    background: #BEBEBE 0% 0% no-repeat padding-box
    border-radius: 5px
    img
        position: absolute
        top: 50%
        left: 50%
        max-width: 80%
        min-width: 80%
        max-height: 80%
        min-height: 80%
        object-fit: contain
        transform: translate(-50%,-50%)

.icon-text-info-roads-oval
    position: absolute
    left: 25%
    top: 20%

.info-roads-title-oval
    position: relative
    margin-bottom: 3%
    color: #000000
    font-family: $font-family-semibold
    font-size: 0.8vw

.info-roads-text-oval
    position: relative
    margin-bottom: 3%
    color: #939598
    font-family: $font-family-regular
    font-size: 0.75rem

.information-logo-icon-oval
    position: absolute
    top: 92.5%
    font-family: $font-family-semibold
    color: #ED0874
    font-size: 0.8vw

#root > div > div.roads-create-container > div.roads-create-icon-container > div.roads-create-info-text > div.input-icons-btn
    height: 50%
    font-size: 0.8vw

.general-roads-icon-general-container
    position: relative
    display: flex
    margin-top: 2rem

.general-roads-icon-selector
    position: relative

.input-icons-btn
    height: 2rem