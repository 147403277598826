@import ./../../variables.style


.general-info-container
    position: absolute
    top: 55%
    left: 50%
    width: 100%
    height: 80%
    transform: translate(-50%,-50%)
    overflow-y: auto
    overflow-x: hidden

.logo-name-container
    position: relative
    margin-top: 2rem
    width: 90%
    height: 20%
    left: 50%
    transform: translate(-50%,0)
    display: flex

.logo-name-container-2
    position: relative
    margin-top: 2rem
    width: 90%
    height: 18%
    left: 50%
    transform: translate(-50%,0)
    margin-bottom: 2rem

.logo-container
    position: relative
    width: 50%
    height: 100%
    display: flex

.frame-intro-container
    position: relative
    right: 0
    width: 50%
    height: 100%
    display: flex

.logo-image
    position: relative
    width: 7vw
    height: 7vw
    border: 1px #939598 solid
    border-radius: 7px
    img
        position: absolute
        max-height: 80%
        min-height: 80%
        max-width: 80%
        min-width: 80%
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

.logo-information
    position: relative
    width: 60%
    left: 10%

.logo-name-title
    font-family: $font-family-semibold
    font-size: 1.125rem
    margin-bottom: 1rem

.logo-format-subtitle
    font-family: $font-family-regular
    font-size: 0.875rem
    margin-bottom: 1rem
    color: #939598

.logo-format-dubtitle
    font-family: $font-family-regular
    font-size: 0.875rem
    margin-bottom: 1rem
    color: #939598

.name-container
    position: relative
    left: 0
    display: flex
    input
        position: relative
        top: 25%
        height: 2.5rem
        width: 41.688rem

.legal-text-container
    position: relative
    top: 44%
    width: 90%
    left: 50%
    height: 30%
    transform: translate(-50%,0)
    margin-bottom: 2rem

.color-palette-container-main
    position: relative
    width: 62%
    left: 55%
    height: 80%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px
    transform: translate(-50%, 0)

.url-name-general-information
    position: relative
    font-family: $font-family-semibold
    font-size: 0.9vw
    margin-bottom: 1rem

.general-info-copy-url
    cursor: pointer
    position: relative
    top: 25%
    width: 8.125rem
    height: 2.5rem
    left: 5%
    background: #FFFFFF 0% 0% no-repeat padding-box
    border: 1px solid #ED0874
    border-radius: 5px
    display: flex
    align-items: center
    justify-content: space-evenly

.general-info-copy-text
    right: 5%
    font-size: 0.875rem
    font-family: $font-family-regular

.Mui-checked
    color: #ED0874 !important

.Mui-checked+.MuiSwitch-track
    background-color: #ED0874 !important
