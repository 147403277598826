.gallery-preview-main-modal
    position: absolute
    width: 100%
    height: 80%
    top: 55%
    left: 50%
    transform: translate(-50%,-50%)
    display: grid
    grid-template-columns: repeat(3, 2fr)
    overflow-y: auto

.gallery-image-card-modal
    width: 7vw
    position: relative
    left: 50%
    top: 50%
    transform: translate(-50%,-50%)
    margin: 1vh
    height: 12vh
    background: #fff 0% 0% no-repeat padding-box
    border: 1px solid #BEBEBE
    border-radius: 10px

.gallery-image-container-modal
    position: absolute
    top: 2%
    width: 90%
    height: 60%
    left: 50%
    transform: translate(-50%,0)
    img
        max-height: 100%
        min-height: 100%
        max-width: 100%
        min-width: 100%
        object-fit: contain

.gallery-image-name-modal
    position: absolute
    word-wrap: break-word
    top: 65%
    font-family: $font-family-regular
    font-size: 0.65rem
    width: 80%
    left: 50%
    transform: translate(-50%,0)
    text-align: center

.fullscreen-image-contaienr-modal
    cursor: pointer
    position: absolute
    top: 5%
    left: 2.5%
    width: 15%
    height: 20%
    border-radius: 3px
    background: rgba(0, 0, 0, 0.63) 0% 0% no-repeat padding-box
    img
        position: absolute
        top: 50%
        left: 50%
        max-height: 70%
        min-height: 70%
        max-width: 70%
        min-width: 70%
        object-fit: contain
        transform: translate(-50%,-50%)