@import ./../../variables.style

.upload-main-container
    position: absolute
    background: rgba(0, 0, 0, 0.63) 0% 0% no-repeat padding-box
    width: 100%
    height: 100%
    top: 0
    left: 0

.upload-card-container
    position: absolute
    top: 50%
    left: 50%
    width: 50%
    height: 60%
    background: #fff 0% 0% no-repeat padding-box
    transform: translate(-50%,-50%)
    border-radius: 7px

.upload-card-header
    position: absolute
    height: 15%
    width: 100%

.upload-header-title
    position: absolute
    top: 50%
    left: 5%
    font-family: $font-family-regular
    color: #043250
    font-size: 1.2rem
    transform: translate(0,-50%)

.upload-close-btn
    position: absolute
    top: 50%
    right: 5%
    height: 2.5rem
    width: 2.5rem
    background: rgba(237, 8, 116, 1) 0% 0% no-repeat padding-box
    border-radius: 7px
    cursor: pointer
    transform: translate(0,-50%)
    img
        position: absolute
        width: 1.5rem
        height: 1.5rem
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

.filepicker
    position: absolute
    top: 52.5%
    left: 50%
    transform: translate(-50%,-50%)
    width: 90%
    height: 70%
    overflow-y: auto

.upload-gallery-btn
    position: absolute
    bottom: 3%
    right: 5%
    width: 15%
    height: 7%
    background: rgba(237, 8, 116, 1) 0% 0% no-repeat padding-box
    border-radius: 7px
    cursor: pointer
    font-family: $font-family-regular
    color: #fff
    font-size: 0.9vw
    display: flex
    justify-content: center
    align-items: center
