@import ./../../variables.style

.projects-data-container
    position: relative
    top: 0
    height: auto
    width: 100%

.projects-data-title
    position: relative
    left: 5%
    font-size: 1.2rem
    font-family: $font-family-regular
    color: #8F8F8F

.sidebar-projects-container
    position: relative
    width: 90%
    left: 5%

.project-data-btn-container
    position: relative
    margin-top: 3%
    margin-bottom: 3%
    height: auto
    min-height: 4vh
    transition: all 0.3s ease-in-out
    a
        height: 4vh
        text-decoration: none

.project-data-btn-container:hover
    background-color: #EEEEEE
    transition: all 0.3s ease-in-out

.project-data-main-title
    cursor: pointer
    max-height: 4vh
    height: 4vh
    position: relative
    color: #8F8F8F
    font-family: $font-family-regular
    font-size: 0.8vw
    left: 5%
    transition: all 0.3s ease-in-out

.title-submenu
    position: absolute
    top: 50%
    transform: translate(0,-50%)
    transition: all 0.3s ease-in-out

.deselected-arrow
    position: absolute
    width: 7%
    top: 50%
    right: 10%
    transform: translate(0,-50%)
    transition: 0.1s all ease-in-out

.selected-arrow
    position: absolute
    width: 7%
    top: 50%
    right: 10%
    transform: translate(0,-50%) rotate(90deg)
    transition: 0.1s all ease-in-out
    path
        stroke: #ED0874 

.project-data-main-title-no-sub-menu
    position: relative
    color: #8F8F8F
    font-family: $font-family-regular
    font-size: 0.8vw
    height: 4vh
    display: flex
    left: 5%
    align-items: center

.sub-menu-container
    position: relative
    height: auto
    width: 90%
    transform: translate(-20%)

.submenu-btn
    cursor: pointer
    position: relative
    left: 0%
    text-decoration: none
    width: 100%
    margin-bottom: 5%
    margin-top: 5%
    div
        margin-bottom: 2%
        margin-top: 2%
        position: relative
        color: #8F8F8F
        left: 0%
        font-size: 0.8vw
        display: flex
        align-items: center
        height: 4vh
        text-align: left
        font-family: $font-family-regular
        border-radius: 3px 30px 30px 3px
        padding-left: 40%
        transition: all 0.3s ease-in-out

.submenu-btn:hover
    position: relative
    left: 0%
    width: 100%
    div
        color: #ED0874 !important
        border-radius: 3px 30px 30px 3px
        background-color: #FFCCE4
        transition: all 0.3s ease-in-out

.sidebar-selected
    font-family: $font-family-semibold
    color: #ED0874
    transition: all 0.3s ease-in-out

.submenu-selected
    color: #ED0874 !important
    border-radius: 3px 30px 30px 3px
    background-color: #FFCCE4
    transition: all 0.3s ease-in-out