@import ./../../variables.style

.create-new-container
    position: absolute
    width: 80%
    height: 80%
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)

.logo-new-amenitie-container
    position: absolute
    width: 100%
    height: 60%

.logo-new-amenitie
    position: absolute
    top: 55%
    transform: translate(0,-50%)
    background: #BEBEBE 0% 0% no-repeat padding-box
    border-radius: 5px
    height: 4.5vw
    width: 4.5vw
    img
        position: absolute
        max-height: 80%
        min-height: 80%
        max-width: 80%
        min-width: 80%
        object-fit: contain
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

.logo-new-amenitie-info
    position: absolute
    width: 70%
    left: 35%
    top: 50%
    transform: translate(0,-50%)

.logo-name-title-amenities-new
    position: relative
    font-family: $font-family-semibold 
    font-size: 0.8vw
    margin-bottom: 3%

.logo-format-subtitle-amenities-new
    position: relative
    font-family: $font-family-semibold 
    font-size: 0.7rem
    margin-bottom: 1%
    color: #939598

.logo-format-dubtitle-amenities-new
    position: relative
    font-family: $font-family-regular 
    font-size: 0.7rem
    margin-bottom: 20%
    color: #939598

.select-icon
    cursor: pointer
    position: absolute
    top: 70%
    left: 0%
    background: #F5F5F5 0% 0% no-repeat padding-box
    border: 0.5px solid #707070
    border-radius: 5px
    color: #707070
    width: 45%
    height: 40%
    font-family: $font-family-regular
    font-size: 0.8vw
    display: flex
    justify-content: center
    align-items: center

#root > div > div.create-amenities-new-container > div.edit-amenitie-container > div > div.logo-new-amenitie-info > div.select-icon
    height: 30%
    width: 55%

.create-new-name
    position: absolute
    top: 100%
    width: 100%
    height: 3vh
    input
        width: 100%
        height: 100%

.create-new-save-btn
    position: absolute
    top: 78%
    left: 50%
    transform: translate(-50%, 0)
    color: #fff
    font-family: $font-family-regular
    font-size: 0.8vw
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 5px
    width: 28%
    height: 12%
    cursor: pointer
    display: flex
    justify-content: center
    align-items: center

.amenitites-new-input
    cursor: pointer
    position: absolute
    width: 45%
    right: 10%
    top: 70%
    height: 30%
    z-index: 2
    opacity: 0

.input-amenities-icon
    cursor: pointer
    position: absolute
    width: 45%
    right: 0%
    top: 70%
    height: 40%
    font-family: $font-family-regular
    font-size: 0.85rem
    display: flex
    justify-content: center
    align-items: center
    background: #ED0874 0% 0% no-repeat padding-box
    border: 0.5px solid #ED0874
    border-radius: 5px
    color: #fff