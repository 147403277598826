@import ./../../variables.style

.default-amenities-title
    position: absolute
    top: 8%
    left: 50%
    transform: translate(-50%,-50%)
    width: 90%
    text-align: center
    font-family: $font-family-semibold
    font-size: 0.9vw

.default-amenties-image-info
    position: absolute
    top: 18%
    width: 80% 
    left: 50% 
    height: 19%
    transform: translate(-50%, 0%)

.default-amenities-icon-container
    position: absolute
    width: 4.5vw
    height: 4.5vw
    background: #BEBEBE 0% 0% no-repeat padding-box
    border-radius: 5px
    img
        position: absolute
        max-height: 80%
        min-height: 80%
        max-width: 80%
        min-width: 80%
        object-fit: contain
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

.default-amenties-info-container
    position: absolute
    width: 65%
    right: 0

.default-amenities-info-title
    position: relative
    color: #000000
    font-size: 0.8vw
    font-family: $font-family-semibold
    margin-bottom: 3%

.default-amenities-info-text
    position: relative
    color: #939598
    font-family: $font-family-regular
    font-size: 0.7rem
    margin-bottom: 8%

.default-amenities-select
    cursor: pointer
    position: relative
    border: 1px solid #707070
    background: #F5F5F5 0% 0% no-repeat padding-box
    height: 3vh
    width: 50%
    border-radius: 5px
    color: #939598
    font-family: $font-family-regular
    font-size: 0.8vw
    display: flex
    justify-content: center
    align-items: center

.default-amenities-input
    position: absolute
    z-index: 2
    height: 3.55vh
    opacity: 0

.default-amenities-select-text
    position: absolute
    width: 80%
    top: 48%
    left: 50%
    transform: translate(-50%,0)
    color: #000000
    font-size: 0.8vw
    font-family: $font-family-semibold

.default-amenities-selection
    position: absolute
    top: 55%
    height: 7%
    width: 80%
    left: 50%
    transform: translate(-50%,0%)

.default-amenities-save
    cursor: pointer
    position: absolute
    bottom: 8%
    height: 9%
    width: 28%
    left: 50%
    transform: translate(-50%,0)
    background: #ED0874 0% 0% no-repeat padding-box
    border-radius: 5px
    display: flex
    justify-content: center
    align-items: center
    color: #fff
    font-size: 0.8vw
    font-family: $font-family-regular