@import ./../../variables.style
  
.upload-spin-main-component
  position: absolute
  width: 38vw
  height: 85%
  background: #FFFFFF 0% 0% no-repeat padding-box
  top: 12%
  left: 20%
  border: 0.5px solid #BEBEBE
  border-radius: 8px

.upload-spin-title
  color: #043250
  font-family: $font-family-semibold
  position: relative
  left: 3%
  margin-top: 2rem
  margin-bottom: 1rem
  font-size: 1.125rem

.upload-spins-category-container
  height: 90%
  width: 94%
  position: absolute
  left: 50%
  transform: translate(-50%,0)
  overflow-y: auto
  overflow-x: hidden

.upload-spin-individual-category
  position: relative
  width: 100%
  min-height: 3.75rem
  height: auto
  background: #FFFFFF 0% 0% no-repeat padding-box
  border-radius: 5px
  border: 0.5px solid #8F8F8F
  margin-bottom: 0.5rem
  box-sizing: border-box

.upload-spin-name-icon-container
  cursor: pointer
  position: relative
  height: 3.75rem
  display: flex
  align-items: center
  img
    max-height: 1rem
    min-height: 1rem
    min-width: 1rem
    max-width: 1rem
    object-fit: contain

.closed-spin-icon
  transform: rotate(-90deg)

.upload-spin-individual-name
  font-size: 1rem
  margin-left: 1rem
  margin-right: 1rem
  font-family: $font-family-semibold

.upload-spin-control-container
  position: relative
  margin-bottom: 1rem
  margin-left: 1rem
  width: calc( 100% - 2rem )
  display: flex

.upload-spin-control-left
  position: relative
  display: flex
  align-items: center

.upload-spin-icon-control
  position: relative
  width: 5.625rem
  height: 5.625rem
  background: #F5F5F5 0% 0% no-repeat padding-box
  border: 0.5px solid #707070
  border-radius: 5px
  margin-right: 1rem
  img
    position: absolute
    max-width: 3rem
    min-width: 3rem
    max-height: 3rem
    min-height: 3rem
    object-fit: contain
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)

.upload-spin-control-info
  position: relative
  width: 15rem
  margin-right: 1rem

.upload-spin-control-info-title
  font-size: 0.875rem
  font-family: $font-family-semibold
  color: #000000
  margin-bottom: 0.3rem

.upload-spin-control-info-text
  font-size: 0.75rem
  font-family: $font-family-regular
  color: #616161
  margin-bottom: 0.3rem

.upload-spin-control-input-container
  position: relative
  input
    position: absolute
    top: 0
    cursor: pointer
    height: 1.5rem
    opacity: 0

input[type="file"]
  cursor: pointer

.upload-spin-control-info-btn
  position: relative
  height: 1.5rem
  width: 7.75rem
  border: 0.5px solid #616161
  background: #F5F5F5 0% 0% no-repeat padding-box
  border-radius: 5px
  display: flex
  justify-content: center
  align-items: center
  font-size: 0.75rem
  font-family: $font-family-regular

.upload-spin-control-right
  margin-left: 1rem

.upload-spin-info-user-title
  font-size: 0.875rem
  font-family: $font-family-semibold
  margin-bottom: 0.3rem

.upload-spin-info-user-name
  font-size: 0.75rem
  font-family: $font-family-regular
  color: #616161
  margin-bottom: 0.3rem

.upload-spin-info-user-date
  font-size: 0.75rem
  font-family: $font-family-regular
  color: #616161
  margin-bottom: 0.3rem

.upload-spin-info-user-upload
  font-size: 0.75rem
  font-family: $font-family-regular
  color: #616161
  margin-bottom: 0.3rem

.upload-spin-control-container-alt
  position: relative
  margin-bottom: 1rem
  margin-left: 2rem
  width: calc( 100% - 4rem )

.select-amenity-spin-container
  position: relative
  margin-bottom: 1rem
  display: flex
  align-items: center

.select-amenity-spin-title
  margin-right: 0.75rem
  color: #000000
  font-size: 0.875rem
  font-family: $font-family-semibold

.select-amenties-selector
  margin-right: 0.75rem
  height: 1.875rem
  width: 12.5rem

.select-amenity-spin-btn
  cursor: pointer
  width: 3.375rem
  height: 1.875rem
  color: #fff
  font-size: 0.875rem
  background: #ED0874 0% 0% no-repeat padding-box
  border-radius: 5px
  display: flex
  justify-content: center
  align-items: center
  font-family: $font-family-regular

.upload-spin-subcategory-container
  width: 100%

.upload-spin-subcategory-individual
  width: 100%
  border: 0.5px solid #8F8F8F
  height: auto
  margin-bottom: 0.5rem
  border-radius: 5px
  box-shadow: 0px 3px 6px #00000029

.upload-spin-subcategory-individual-name-icon
  height: 2.5rem
  width: 100%
  display: flex
  align-items: center
  cursor: pointer
  img
    max-height: 1rem
    min-height: 1rem
    min-width: 1rem
    max-width: 1rem
    object-fit: contain

.upload-spin-subcategory-indiviudal-name
  margin-left: 1rem
  margin-right: 1rem
  font-family: $font-family-semibold
  font-size: 0.875rem

.cheemsito-container
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  div
    width: 50%
    text-align: center
    margin-bottom: 2rem
    margin-top: 2rem
    font-size: 1rem
    font-family: $font-family-semibold
    color: #8F8F8F

.cheems-image
  position: relative
  max-width: 15rem
  min-width: 15rem
  max-height: 15rem
  min-height: 15rem
  object-fit: contain
  opacity: 0.6